export const bankNames = [
  "Bank of Baroda",
  "Bank of India",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "Indian Bank",
  "Indian Overseas Bank",
  "Punjab & Sind Bank",
  "Punjab National Bank",
  "State Bank of India",
  "UCO Bank",
  "Union Bank of India",
  "Axis Bank Ltd.",
  "Bandhan Bank Ltd.",
  "CSB Bank Ltd.",
  "City Union Bank Ltd.",
  "DCB Bank Ltd.",
  "Dhanlaxmi Bank Ltd.",
  "Federal Bank Ltd.",
  "HDFC Bank Ltd",
  "ICICI Bank Ltd.",
  "Induslnd Bank Ltd.",
  "IDFC First Bank Ltd.",
  "Jammu & Kashmir Bank Ltd.",
  "Karnataka Bank Ltd.",
  "Karur Vysya Bank Ltd.",
  "Kotak Mahindra Bank Ltd.",
  "Nainital Bank Ltd.",
  "RBL Bank Ltd.",
  "South Indian Bank Ltd.",
  "Tamilnad Mercantile Bank Ltd.",
  "YES Bank Ltd.",
  "IDBI Bank Ltd.",
  "Au Small Finance Bank Ltd.",
  "Capital Small Finance Bank Ltd.",
  "Equitas Small Finance Bank Ltd.",
  "Suryoday Small Finance Bank Ltd.",
  "Ujjivan Small Finance Bank Ltd.",
  "Utkarsh Small Finance Bank Ltd.",
  "ESAF Small Finance Bank Ltd.",
  "Fincare Small Finance Bank Ltd.",
  "Jana Small Finance Bank Ltd.",
  "North East Small Finance Bank Ltd.",
  "Shivalik Small Finance Bank Ltd.",
  "Unity Small Finance Bank Ltd.",
  "India Post Payments Bank Ltd.",
  "Fino Payments Bank Ltd.",
  "Paytm Payments Bank Ltd.",
  "Airtel Payments Bank Ltd.",
  "Andhra Pragathi Grameena Bank",
  "Andhra Pradesh Grameena Vikas Bank",
  "Arunachal Pradesh Rural Bank",
  "Aryavart Bank",
  "Assam Gramin Vikash Bank",
  "Bangiya Gramin Vikas Bank",
  "Baroda Gujarat Gramin Bank",
  "Baroda Rajasthan Kshetriya Gramin Bank",
  "Baroda UP Bank",
  "Chaitanya Godavari Grameena Bank",
  "Chhattisgarh Rajya Gramin Bank",
  "Dakshin Bihar Gramin Bank",
  "Ellaquai Dehati Bank",
  "Himachal Pradesh Gramin Bank",
  "J&K Grameen Bank",
  "Jharkhand Rajya Gramin Bank",
  "Karnataka Gramin Bank",
  "Karnataka Vikas Grameena Bank",
  "Kerala Gramin Bank",
  "Madhya Pradesh Gramin Bank",
  "Madhyanchal Gramin Bank",
  "Maharashtra Gramin Bank",
  "Manipur Rural Bank",
  "Meghalaya Rural Bank",
  "Mizoram Rural Bank",
  "Nagaland Rural Bank",
  "Odisha Gramya Bank",
  "Paschim Banga Gramin Bank",
  "Prathama UP Gramin Bank",
  "Puduvai Bharathiar Grama Bank",
  "Punjab Gramin Bank",
  "Rajasthan Marudhara Gramin Bank",
  "Saptagiri Grameena Bank",
  "Sarva Haryana Gramin Bank",
  "Saurashtra Gramin Bank",
  "Tamil Nadu Grama Bank",
  "Telangana Grameena Bank",
  "Tripura Gramin Bank",
  "Utkal Grameen bank",
  "Uttar Bihar Gramin Bank",
  "Uttarakhand Gramin Bank",
  "Uttarbanga Kshetriya Gramin Bank",
  "Vidharbha Konkan Gramin Bank",
  "AB Bank Ltd.",
  "American Express Banking Corporation",
  "Australia and New Zealand Banking Group Ltd.",
  "Barclays Bank Plc.",
  "Bank of America",
  "Bank of Bahrain & Kuwait BSC",
  "Bank of Ceylon",
  "Bank of China",
  "Bank of Nova Scotia",
  "BNP Paribas",
  "Citibank N.A.",
  "Cooperatieve Rabobank U.A.",
  "Credit Agricole Corporate & Investment Bank",
  "Credit Suisse A.G",
  "CTBC Bank Co., Ltd.",
  "DBS Bank India Ltd.*",
  "Deutsche Bank",
  "Doha Bank Q.P.S.C",
  "Emirates Bank NBD",
  "First Abu Dhabi Bank PJSC",
  "FirstRand Bank Ltd",
  "HSBC Ltd",
  "Industrial & Commercial Bank of China Ltd.",
  "Industrial Bank of Korea",
  "J.P. Morgan Chase Bank N.A.",
  "JSC VTB Bank",
  "KEB Hana Bank",
  "Kookmin Ban",
  "Krung Thai Bank Public Co. Ltd.",
  "Mashreq Bank PSC",
  "Mizuho  Bank Ltd.",
  "MUFG Bank, Ltd.",
  "NatWest Markets Plc",
  "NongHyup Bank",
  "PT Bank Maybank Indonesia TBK",
  "Qatar National Bank (Q.P.S.C.)",
  "Sberbank",
  "SBM Bank (India) Ltd.*",
  "Shinhan Bank",
  "Societe Generale",
  "Sonali Bank Ltd.",
  "Standard Chartered Bank",
  "Sumitomo Mitsui Banking Corporation",
  "United Overseas Bank Ltd.",
  "Woori Bank",
];
// export const BASE_URL = "https://dev.ehostingguru.com/option365/v1/user/";
// export const BASE_URL = 'https://test.ehostingguru.com/option365/v1/user/'
// export const BASE_URL = 'https://uat.ehostingguru.com/opinion365/v1/user/'
export const BASE_URL = "https://unicorn-tradexbet.in/unicorn/v1/user/";

export const BASE_APP_URL = "https://unicorn-tradexbet.in/";

// export const BASE_APP_URL = 'https://test-opinion365.netlify.app/'
