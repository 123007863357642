import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { FaRegClock } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import bankImg from "../Assets/Images/New Project (1).jpg";
import {
  addMoney,
  rechargeStatus,
  setRechargeResponse,
  setRechargeStatusResponse,
} from "../Redux/PostSlice";
import { recharge } from "../Redux/PostSlice";
import { createOrder } from "../Redux/PostSlice";
import { userBalance } from "../Redux/GetSlice";
import { Navigate } from "react-router-dom";
import { TextField } from "@mui/material";
import QRCode from "react-qr-code";
import GPay from "./../Assets/Images/gpay.png";
import Paytm from "./../Assets/Images/paytm.png";
import PhonePe from "./../Assets/Images/phonepe.png";
import BharatPe from "./../Assets/Images/bharatpe.png";
import AmazonPay from "./../Assets/Images/amazonpay.png";
import { HiArrowLeft } from "react-icons/hi2";
import { IoMdAdd } from "react-icons/io";

export default function Addmoney() {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  const { orderResponse } = useSelector((state) => state.Post);
  const { rechargeResponse } = useSelector((state) => state.Post);
  const { rechargeStatusResponse } = useSelector((state) => state.Post);
  const { totalBalanceResponse } = useSelector((state) => state.Get);
  const { createOrderResponse } = useSelector((state) => state.Post);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(countdown);
        dispatch(setRechargeResponse());
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addAmount = (e) => {
    setAmount(e.target.value);
  };

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  useEffect(() => {
    if (rechargeResponse?.status) {
      const twoSecondInterval = setInterval(() => {
        const formData = new FormData();
        formData.append("order_id", rechargeResponse?.data?.order_id);
        dispatch(rechargeStatus(formData));
        if (
          rechargeStatusResponse?.data?.payment_status == "success" &&
          rechargeResponse?.data?.order_id ==
            rechargeStatusResponse?.data?.order_id
        ) {
          toast.success("Payment Done Successfully");
          dispatch(setRechargeResponse());
          dispatch(setRechargeStatusResponse());
          navigate("/wallet");
        }
      }, 2000);

      return () => clearInterval(twoSecondInterval);
    }
  }, [
    JSON.stringify(rechargeStatusResponse),
    JSON.stringify(rechargeResponse),
  ]);

  const uid = localStorage.getItem("user_id");

  const withdReq = () => {
    const amnt = amount;
    const formData = new FormData();
    formData.append("user_id", uid);
    formData.append("amount", amnt);
    setLoading(true);

    if (amount !== "" && amount !== undefined) {
      setError("");
      dispatch(recharge(formData));
      setMinutes(5);
      setSeconds(0);
    } else {
      setError("Please enter an amount");
    }
  };

  // useEffect(() => {
  //   const formData = new FormData();
  //   formData.append("customer_mobile", totalBalanceResponse?.user?.phone);
  //   formData.append("user_token", orderResponse?.user_token);
  //   formData.append("order_id", orderResponse?.orderDetails?.order_id);
  //   formData.append("amount", orderResponse?.orderDetails?.amount);
  //   formData.append("redirect_url", orderResponse?.redirect_url);
  //   dispatch(createOrder(formData));
  // }, [orderResponse]);

  // console.log(createOrderResponse,"link+++++++++++++++++");
  //
  useEffect(() => {
    // console.log("redirecting++++++++++++++++++++++");
    // console.log(
    //   createOrderResponse?.result?.payment_url,
    //   "dfhdh================"
    // );
    if (createOrderResponse?.result?.payment_url !== undefined || null) {
      window.location.href = createOrderResponse?.result?.payment_url;
    }
  }, [createOrderResponse]);
  const amounts = [
    { label: "₹1,000", value: 1000 },
    { label: "₹2,000", value: 2000 },
    { label: "₹3,000", value: 3000 },
    { label: "₹5,000", value: 5000 },
    { label: "₹10,000", value: 10000 },
    { label: "₹20,500", value: 20000 },
    { label: "₹30,000", value: 30000 },
    { label: "₹50,000", value: 50000 },
  ];
  const balanceAmount =
    parseFloat(totalBalanceResponse?.user?.balance_deposit) +
    parseFloat(totalBalanceResponse?.user?.balance_winning);

  const InfoPopup = () => {
    return (
      <div
        style={{
          width: "100%",
          position: "relative",
          justifyContent: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        <div className="headerAddmoney">
          <p
            className="scanText"
            style={{ fontSize: "25px", fontWeight: "300" }}
          >
            Scan QR using any UPI app
          </p>
        </div>

        <div style={{ marginTop: "40px", marginBottom: "10px" }}>
          <div className="qrcodeBox">
            <QRCode size={160} value={rechargeResponse?.data?.upi_link} />
          </div>

          <div
            style={{
              marginTop: "42%",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#989898",
              gap: "10px",
            }}
          >
            <FaRegClock size={20} />

            <p style={{ fontSize: "16px", fontWeight: "600" }}>
              {minutes.toString().padStart(2, "0")}min:
              {seconds.toString().padStart(2, "0")}s
            </p>
          </div>
          <a
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              textAlign: "center",
              textDecoration: "none",
              width: "80%",
              margin: "auto",
            }}
            href={rechargeResponse?.data?.upi_link}
            // href="upi://pay?cu=INR&pa=paytmqr2810050501011c7hdlw291fz@paytm&pn=Paytm Merchant&am=100&mam=100&tr=B8oEjIoSjp1716276512&tn=UPI Payment"
          >
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#109d3d",
                borderRadius: "5px",
                textDecoration: "none",
                height: "45px",
                width: "90%",
                margin: "auto",
                marginTop: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "15px",
                  color: "white",
                  padding: "10px",
                  lineHeight: "30px",
                }}
              >
                PROCEED TO TOPUP
              </p>
            </div>

            <div className="belowButton">
              Use the Montek app to scan the QR code and receive financial
              payments into your account.
            </div>

            <div
              style={{
                margin: "auto",
                width: "100%",
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60%", height: "20%", margin: "auto" }}
                src={bankImg}
                alt=""
              />
            </div>
          </a>
        </div>
        {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <p style={{ fontSize: "20px" }}>QR code expired. Please try again.</p>
        </div> */}
      </div>
    );
  };

  return (
    <div>
      <div className="mobile__layout main">
        <div
          style={{ background: "white" }}
          className="container auth__container fixed"
        >
          <div
            style={{ justifyContent: "space-between", height: "56px" }}
            className="reg-back fixed_up"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ background: "#00000000" }} className="reg__back">
                <Link to="/">
                  <HiArrowLeft color="white" size={25} />
                </Link>
              </div>
              <p
                style={{ fontSize: "18px", color: "white", paddingTop: "4px" }}
              >
                Add Money
              </p>
            </div>
          </div>

          <div>
            {rechargeResponse?.status ? (
              InfoPopup()
            ) : (
              <div>
                <div style={{ background: "white" }} className="add_money">
                  <div className="currentBalHeader">
                    <div className="currentBalHeaderInside">
                      <div className="firstFlex">
                        <IoWallet color="#c20309" size={20} />
                        <p style={{ fontWeight: "600" }}>Current Balance</p>
                      </div>
                      <div>
                        <p style={{ fontWeight: "600" }}> ₹{balanceAmount}</p>
                      </div>
                    </div>
                  </div>
                  <div className="inn_money">
                    <div className="mb-3">
                      <div
                        style={{
                          width: "90%",
                          margin: "auto",
                          height: "55px",
                          marginTop: "20px",
                        }}
                      >
                        <input
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder="₹ Enter Amount"
                          style={{
                            width: "100%",
                            margin: "auto",
                            height: "100%",
                            border: "2px solid lightgrey",
                            borderRadius: "5px",
                            fontSize: "25px",
                            color: "black",
                            padding: "10px",
                          }}
                          type="number"
                          name=""
                          id=""
                        />
                      </div>

                      {/* <TextField
                      style={{
                        width: "100%",
                        color: "lightgrey",
                        margin: "auto",
                        height:"100px"
                      }}
                      fullWidth
                      placeholder="Enter the amount"
                      onChange={(e) => setAmount(e.target.value)}
                      type="text"
                      className="form-control"
                      value={amount}
                    /> */}
                      {error ? (
                        <div
                          style={{
                            height: "50px",
                            fontSize: "15px",
                            color: "red",
                            paddingLeft: "5px",
                          }}
                        >
                          {error}
                        </div>
                      ) : null}
                    </div>

                    <div style={{ margin: "auto", padding: "10px" }}>
                      <p>Recommended Price</p>
                      <div style={{ marginTop: "10px", fontWeight: "600" }}>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            // justifyContent: "space-between",
                            gap: "8px", // Added gap for better spacing
                            width: "100%",
                            margin: "auto",
                          }}
                        >
                          {amounts.map((item) => (
                            <div
                              key={item.value}
                              onClick={() => setAmount(item.value)}
                              style={{
                                padding: "7px 10px",
                                border: "2px solid #582e85",
                                borderRadius: "8px",
                                color: "#582e85",
                                cursor: "pointer",
                                minWidth: "20%",
                                textAlign: "center",
                                fontSize: 12,
                              }}
                            >
                              {item.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* <ul>
                    <li onClick={(e) => addAmount(e)} value={250}>
                      250
                    </li>
                    <li onClick={(e) => addAmount(e)} value={500}>
                      500
                    </li>
                    <li onClick={(e) => addAmount(e)} value={750}>
                      750
                    </li>
                    <li onClick={(e) => addAmount(e)} value={750}>
                      750
                    </li>
                  </ul> */}

                    <div
                      onClick={withdReq}
                      style={{
                        textAlign: "center",
                        backgroundColor: "#109d3d",
                        borderRadius: "5px",
                        textDecoration: "none",
                        height: "45px",
                        width: "90%",
                        margin: "auto",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "15px",
                          color: "white",
                          padding: "10px",
                          lineHeight: "30px",
                        }}
                      >
                        PROCEED TO TOPUP
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            background: "black",
            height: "40px",
            width: "100%",
            padding: "10px",
          }}
        >
          <p style={{ color: "grey", fontSize: "10px", textAlign: "center" }}>
            By Clicking "Top Up" button, you are agree to the conditions of 1Win
            website User Agreement
          </p>
        </div>

        <div className="Toastify"></div>
      </div>
    </div>
  );
}
