import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getPrivacypol } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer4 from "../Components/Footer4";
import { produc } from "../Redux/Helper";
import banner from "../Assets/Images/hero_image.avif";

export default function PrivacyPol() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrivacypol());
  }, []);

  const { pages } = useSelector((state) => state.Get);

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container">
          <div
            style={{ position: "sticky", top: "0", height: "56px" }}
            className="reg-back"
          >
            <div className="reg__back">
              <Link to="/more">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Privacy Policy</p>
          </div>

          <div className="mid_content">
            <div className="terms_img">
              {pages?.privacyPolicy?.banner_image == null || undefined || "" ? (
                <img src={banner} alt="" />
              ) : (
                <img src={produc(pages?.privacyPolicy?.banner_image)} alt="" />
              )}
            </div>

            <div className="terms_content">
              <h4>Privacy Policy</h4>

              <div className="summary_contents">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pages?.privacyPolicy?.html_content,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="Toastify"></div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer4 />
        </div>
      </div>
    </div>
  );
}
