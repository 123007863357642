import { Typography } from "@mui/material";

export const TradeTypography = ({ children }) => {
  return (
    <h5
      style={{
        fontWeight: "600",
        fontSize: "13px",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        maxWidth: "100%",
      }}
    >
      {children}
    </h5>
  );
};
