import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PortfCompos from "../Components/PortfCompos";
import TopNav from "./TopNav";
import jersey from "../Assets/Images/jersey.png";
import Footer from "../Components/Footer";
import { portfolio } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer2 from "../Components/Footer2";
import SideNav from "./SideNav";
import fav1 from "../Assets/Images/fav1.png";

import { cancelBet } from "../Redux/PostSlice";
import toast from "react-hot-toast";
import TopNavWOL from "./TopNavWOL";
import { getClosedEvents } from "../Redux/GetSlice";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import { userBalance } from "../Redux/GetSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import logo4 from "../Assets/Images/fav1.png";
import { io } from "socket.io-client";
import { getPortDetails } from "../Redux/PostSlice";
import PLiveMatches from "./PLiveMatches";
import { getPortfolioMatches } from "../Redux/GetSlice";
import banner from "../Assets/Images/hero_image.avif";
import "./../Auth/style.css";
import EventDetWithwatermarkstatusInvest from "../Components/EventDetWithwatermarkstatusInvest";
import { FaCircleChevronRight } from "react-icons/fa6";
import { HiArrowLeft } from "react-icons/hi2";
import { Typography } from "@mui/material";
import matchlogo2 from "../Assets/Images/trago2.png";
import { TradeTypography } from "../Components/TradeTypography";
import { GoDotFill } from "react-icons/go";

const socket = io.connect(RT_BASE_URL);

export default function PCancel() {
  const [click, setClick] = useState(false);

  const { portFolios } = useSelector((state) => state.Post);
  const { totalBalanceResponse } = useSelector((state) => state.Get);
  const { ClosedEvents } = useSelector((state) => state.Get);
  const { cancelResponse } = useSelector((state) => state.Post);
  const { portfolioDetails } = useSelector((state) => state.Post);
  const { portfolioMatches } = useSelector((state) => state.Get);

  const [portfolioAllMatches, setportfolioAllMatches] = useState([]);

  const dispatch = useDispatch();

  const id = localStorage.getItem("user_id");

  useEffect(() => {
    if (portfolioMatches?.length > 0) {
      setportfolioAllMatches(
        portfolioMatches?.map((item) => {
          return {
            ...item,
            extra_data: item?.extra_data ? JSON.parse(item?.extra_data) : {},
          };
        })
      );
    } else {
      setportfolioAllMatches([]);
    }
  }, [JSON.stringify(portfolioMatches)]);

  useEffect(() => {
    dispatch(userBalance(id));
  }, []);

  const [quesCardId, setQuesCardId] = useState(1);
  const [closeQues, setCloseQues] = useState([{}]);
  const [quests, setQuests] = useState([{}]);

  useEffect(() => {
    setCloseQues(ClosedEvents);
  }, [ClosedEvents]);

  useEffect(() => {
    setQuests(portFolios);
  }, []);

  useEffect(() => {
    socket.on("questionUpdated", (data) => {
      dispatch(getClosedEvents(id));
    });
  }, []);

  const demoApicallforClosedPortfs = () => {
    //This funtion to be changed when api available
  };

  // Running useeffect for particular ques id to call api
  // useEffect(()=>{
  //   dispatch(demoApicallforClosedPortfs(QuesCardid))
  // },[QuesCardid])

  useEffect(() => {
    let data = {
      id: id,
      status: "Completed",
    };
    dispatch(getPortfolioMatches(data));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClosedEvents(id));
  }, []);

  let arr = [0, 1, 2, 3, 4];

  const [side, setSide] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalReturns, setTotalReturns] = useState(0);
  const [mID, setMID] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const [toQuest, setToQuest] = useState(false);

  const toQuestions = (mId, type) => {
    console.log("...type...", type);
    setClick(true);
    setMID(mId);
    setSelectedType(type);
  };
  console.log(".....selectedType....", selectedType);

  const openSideNav = () => {
    setSide(true);
    // console.log("Open");
  };
  const closeSideNav = () => {
    setSide(false);
  };

  // console.log(totalInvestment);
  // console.log(totalReturns);

  const [canceled, setCanceled] = useState(false);
  const [exit, setExit] = useState(false);
  const [exitInfo, setexitInfo] = useState({});
  const [cancelInfo, setcancelInfo] = useState({});
  const [cancelUcheck, setCancelUcheck] = useState(false);
  const [cancelMcheck, setCancelMcheck] = useState(false);
  const [exitMcheck, setExitMcheck] = useState(false);

  const handleConfirm = () => {
    alert("Confirmed!");
    // Add your logic for confirmation here
  };

  useEffect(() => {
    if (cancelResponse) {
      setCanceled(false);
      setExit(false);
    }
  }, [cancelResponse]);

  const cancelPop = (items) => {
    setCanceled(true);
    setcancelInfo(items);
  };

  const exitPop = (items) => {
    setExit(true);
    setexitInfo(items);
  };

  const runCancel = (qid, amount) => {
    const formData = new FormData();
    formData.append("question_id", qid);
    formData.append("user_id", id);
    formData.append("amount", amount);
    formData.append("is_paired", 0);
    formData.append("is_unpaired", cancelUcheck ? 1 : 0);
    dispatch(cancelBet(formData));
  };

  const runExit = (qid, amount) => {
    const formData = new FormData();
    formData.append("question_id", qid);
    formData.append("user_id", id);
    formData.append("amount", amount);
    formData.append("is_paired", exitMcheck ? 1 : 0);
    formData.append("is_unpaired", 0);
    dispatch(cancelBet(formData));
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [toQuest, click]);

  const closeInfo = (qid) => {
    setClick(true);
    setToQuest(true);
    setQuesCardId(id);
    const formData = new FormData();
    formData.append("question_id", qid);
    formData.append("user_id", id);
    dispatch(getPortDetails(formData));
  };

  const PortFolioClosedCards = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#00793c",
                  paddingTop: "1px",
                  backgroundColor: "#c5ffdd",
                }}
              >
                Matched
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.matched?.question?.question_image == null ||
                items?.matched?.question?.question_image == undefined ||
                items?.matched?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.matched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.matched?.question?.question_text} </p>
            </div>
            <>
              {items?.matched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Result</div>
                    </div>
                  </div>

                  {items?.matched?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest}</div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div className="rslt_blo rd">
                          {items?.publish_result}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.matched?.total_invested}
                        {parseInt(items?.matched?.total_returns) > 0 && (
                          <span>Gains ₹{items?.matched?.total_returns}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  const PortFolioClosedCards2 = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                <TradeTypography> Unmatched</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.unmatched?.question?.question_image == null ||
                items?.unmatched?.question?.question_image == undefined ||
                items?.unmatched?.question?.question_image == "" ? (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={jersey}
                    alt="img"
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.unmatched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <div style={{ maxWidth: "90%" }}>
                <TradeTypography>
                  {items?.unmatched?.question?.question_text}{" "}
                </TradeTypography>
              </div>
            </div>
            <>
              {items?.unmatched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Buy Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Result</TradeTypography>
                      </div>
                    </div>
                  </div>

                  {items?.unmatched?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest} </div>
                        <div className="rslt_blo" style={{ marginRight: 12 }}>
                          ₹{items?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div
                          className="rslt_blo rd"
                          style={{ fontWeight: "bold", paddingLeft: 12 }}
                        >
                          <TradeTypography>
                            {items?.publish_result}{" "}
                          </TradeTypography>
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        <TradeTypography>
                          <TradeTypography>
                            Invested ₹{items?.unmatched?.total_invested}
                          </TradeTypography>
                        </TradeTypography>
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        <TradeTypography>
                          <TradeTypography>
                            {" "}
                            Unmatched qty : {items?.total_unmatched}
                          </TradeTypography>
                        </TradeTypography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexiting = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",

              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#6a257d",
                  paddingTop: "1px",
                  backgroundColor: "#f4e7f9",
                }}
              >
                <TradeTypography>Exiting</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.exiting?.question?.question_image == null ||
                items?.exiting?.question?.question_image == undefined ||
                items?.exiting?.question?.question_image == "" ? (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={jersey}
                    alt="img"
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.exiting?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <div style={{ width: "90%" }}>
                <TradeTypography>
                  {items?.exiting?.question?.question_text}{" "}
                </TradeTypography>
              </div>
            </div>
            <>
              {items?.exiting?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Selling Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Result</TradeTypography>
                      </div>
                    </div>
                  </div>

                  {items?.exiting?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo" style={{ marginRight: 10 }}>
                          ₹{items?.invest}{" "}
                        </div>
                        <div className="rslt_blo" style={{ marginRight: 12 }}>
                          ₹{items?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div
                          className="rslt_blo rd"
                          style={{ fontWeight: "bold", paddingLeft: 12 }}
                        >
                          <TradeTypography>
                            {" "}
                            {items?.publish_result}{" "}
                          </TradeTypography>
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        <TradeTypography>
                          {" "}
                          Invested ₹{items?.exiting?.total_invested}
                        </TradeTypography>
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        <TradeTypography>
                          {" "}
                          exiting qty : {items?.total_unmatched}
                        </TradeTypography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexited = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  paddingTop: "1px",
                  backgroundColor: "#ededed",
                }}
              >
                <TradeTypography>Exited</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.exited?.question?.question_image == null ||
                items?.exited?.question?.question_image == undefined ||
                items?.exited?.question?.question_image == "" ? (
                  <img
                    src={jersey}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.exited?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <div style={{ width: "90%" }}>
                <TradeTypography>
                  {items?.exited?.question?.question_text}{" "}
                </TradeTypography>
              </div>
            </div>
            <>
              {items?.matched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Selling Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Result</TradeTypography>
                      </div>
                    </div>
                  </div>

                  {items?.exited?.bets?.map((bets, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{bets?.choose} </div>
                        <div className="rslt_blo">₹{bets?.invest} </div>
                        <div className="rslt_blo" style={{ marginRight: 18 }}>
                          ₹{bets?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {bets?.quantity}
                        </div>
                        <div
                          className="rslt_blo rd"
                          style={{ fontWeight: "bold", paddingLeft: 10 }}
                        >
                          <TradeTypography>
                            {" "}
                            {bets?.publish_result}{" "}
                          </TradeTypography>
                        </div>
                      </div>
                    );
                  })}

                  <div className="card_below_content">
                    <div
                      style={{
                        width: "100%",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        background: `linear-gradient(34deg, rgb(247,246,244) 35%, rgb(247,246,244) 100%)`,
                      }}
                      className="below_content"
                    >
                      <span
                        style={{
                          color: "red",
                          background: "white",
                          padding: "1px",
                        }}
                        className="percent-details"
                      >
                        <TradeTypography>
                          {" "}
                          Investment ₹{items?.exited?.total_invested}
                        </TradeTypography>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsCancel = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",

              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  paddingTop: "1px",
                  backgroundColor: "#ededed",
                }}
              >
                <TradeTypography>Canceled</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.canceled?.question?.question_image == null ||
                items?.canceled?.question?.question_image == undefined ||
                items?.canceled?.question?.question_image == "" ? (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={jersey}
                    alt="img"
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.canceled?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <div style={{ maxWidth: "90%" }}>
                <TradeTypography>
                  {items?.canceled?.question?.question_text}{" "}
                </TradeTypography>
              </div>
            </div>
            <>
              {items?.canceled?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Buy Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Result</TradeTypography>
                      </div>
                    </div>
                  </div>

                  {items?.canceled?.bets?.map((bets, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{bets?.choose} </div>
                        <div className="rslt_blo">₹{bets?.invest} </div>
                        <div className="rslt_blo" style={{ marginRight: 14 }}>
                          ₹{bets?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {bets?.quantity}
                        </div>
                        <div
                          className="rslt_blo rd"
                          style={{ fontWeight: "bold", paddingLeft: 12 }}
                        >
                          {bets?.publish_result}{" "}
                        </div>
                      </div>
                    );
                  })}

                  <div className="card_below_content">
                    <div
                      style={{
                        width: "100%",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        background: `linear-gradient(34deg, rgb(247,246,244) 35%, rgb(247,246,244) 100%)`,
                      }}
                      className="below_content"
                    >
                      <span
                        style={{
                          color: "red",
                          background: "white",
                          padding: "1px",
                        }}
                        className="percent-details"
                      >
                        <TradeTypography>
                          Investment ₹{items?.matched?.total_invested}
                        </TradeTypography>
                      </span>
                    </div>
                  </div>

                  {/* <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.canceled?.total_invested}
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        canceled qty : {items?.total_unmatched}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div> */}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {side ? (
        <>
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>
      ) : (
        <></>
      )}

      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div
          style={{ backgroundColor: "white" }}
          className="container auth__container"
        >
          {/* <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
            {id !== "" && id !== undefined && id !== null ? (
              <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />
            ) : (
              <TopNavWOL />
            )}
          </div> */}

          <div
            style={{ justifyContent: "space-between", height: "56px" }}
            className="reg-back fixed_up"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ background: "#00000000" }} className="reg__back">
                <Link to="/">
                  <HiArrowLeft color="white" size={25} />
                </Link>
              </div>
              <p
                style={{ fontSize: "18px", color: "white", paddingTop: "4px" }}
              >
                My Portfolio
              </p>
            </div>
          </div>

          {(click && !toQuest) || (click && toQuest) ? (
            <>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  background: "#cfcfcf",
                  marginBottom: 10,
                  height: "50px",
                  position: "sticky",
                  top: 56,
                  zIndex: 4,
                }}
              >
                <div
                  onClick={() => {
                    if (toQuest) {
                      setToQuest(false);
                    } else {
                      setClick(false);
                    }
                  }}
                  style={{
                    marginLeft: 10,
                    backgroundColor: "grey",
                    width: "7%",
                    height: 30,
                    borderRadius: 100,
                    marginTop: 5,
                    marginBottom: 5,
                    textAlign: "center",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                >
                  <FontAwesomeIcon
                    style={{ marginTop: 7, color: "white" }}
                    icon={faArrowLeft}
                  />
                </div>
                <Typography>Event Details</Typography>
              </div>
            </>
          ) : (
            <div
              style={{
                height: "50px",
                zIndex: "0",
                position: "sticky",
                top: "5%",
                borderBottom: "1px solid lightgrey",
              }}
              className="upper_tabs"
            >
              <Link to="/portfolio">
                <div className="close_eve">Live Events</div>
              </Link>
              <Link>
                <div className="live_eve">Closed Events</div>
              </Link>
            </div>
          )}

          {(click && !toQuest) || (click && toQuest) ? null : (
            <div style={{}}>
              <PortfCompos
                returns={totalBalanceResponse?.user?.balance_winning}
                invest={totalBalanceResponse?.investment}
              />
            </div>
          )}

          <div
            style={{
              padding: 5,
              marginTop: -5,
            }}
          >
            <div>
              {toQuest ? (
                <>
                  {portfolioDetails?.matched?.bets?.length > 0 ? (
                    <EventDetWithwatermarkstatusInvest
                      items={portfolioDetails}
                    />
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.unmatched?.bets?.length > 0 ? (
                    <PortFolioClosedCards2 items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.exiting?.bets?.length > 0 ? (
                    <PortFolioLiveCardsexiting items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.exited?.bets?.length > 0 ? (
                    <PortFolioLiveCardsexited items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.matched?.bets?.length > 0 ? (
                    <PortFolioLiveCardsCancel items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {Array.isArray(ClosedEvents) &&
                    click &&
                    ClosedEvents?.map((items, ind) => {
                      if (
                        items?.match_id == mID &&
                        items?.type == selectedType
                      ) {
                        return (
                          <div
                            key={ind}
                            onClick={() => closeInfo(items?.question_id)}
                            style={{
                              width: "95%",
                              margin: "auto",
                              padding: "10px",
                              background: "white",
                              borderRadius: "15px",
                              border: "1px solid black",
                              marginTop: "20px",
                            }}
                          >
                            <div style={{ width: "95%", margin: "auto" }}>
                              {/* <div
                                style={{
                                  padding: "2px 4px",
                                  background: "#5c95e1",
                                  width: "13%",
                                  fontSize: "13px",
                                  marginTop: "6px",
                                  fontWeight: "600",
                                }}
                              >
                                Event
                              </div> */}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "95%",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "15px",
                                  }}
                                  className="quest_img"
                                >
                                  {items?.question_image == null ||
                                  items?.question_image == undefined ||
                                  items?.question_image == "" ? (
                                    <img
                                      style={{
                                        borderRadius: 10,
                                        backgroundColor: "grey",
                                      }}
                                      src={jersey}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        borderRadius: 10,
                                        backgroundColor: "lightgrey",
                                      }}
                                      src={produc(items?.question_image)}
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "80%",
                                    color: "grey",
                                    fontSize: "13.5px",
                                  }}
                                >
                                  {items?.question_text}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                margin: "auto",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                opacity: 0.9,
                              }}
                            >
                              <div
                                style={{
                                  background: "red",
                                  padding: "3px 7px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "4px",
                                  color: "white",
                                  borderRadius: "50px",
                                  fontSize: "12px",
                                }}
                              >
                                <FaCircleChevronRight size={18} color="white" />
                                <Typography
                                  style={{ fontSize: 12, fontWeight: "bold" }}
                                >
                                  View Event
                                </Typography>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (
                        items?.market_id == mID &&
                        items?.type == selectedType
                      ) {
                        return (
                          console.log("....close.else if......"),
                          (
                            <div
                              key={ind}
                              onClick={() => closeInfo(items?.question_id)}
                              style={{
                                width: "95%",
                                margin: "auto",
                                padding: "10px",
                                background: "white",
                                borderRadius: "15px",
                                border: "1px solid black",
                                marginTop: "20px",
                              }}
                            >
                              <div style={{ width: "95%", margin: "auto" }}>
                                <div
                                  style={{
                                    padding: "2px 4px",
                                    background: "#5c95e1",
                                    width: "13%",
                                    fontSize: "13px",
                                    marginTop: "6px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Event
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "95%",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{ marginTop: "15px" }}
                                    className="quest_img"
                                  >
                                    {items?.question_image == null ||
                                    items?.question_image == undefined ||
                                    items?.question_image == "" ? (
                                      <img
                                        style={{
                                          borderRadius: 10,
                                          backgroundColor: "grey",
                                        }}
                                        src={jersey}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          borderRadius: 10,
                                          backgroundColor: "lightgrey",
                                        }}
                                        src={produc(items?.question_image)}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      width: "80%",
                                      color: "grey",
                                      fontSize: "13.5px",
                                    }}
                                  >
                                    {items?.question_text}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "95%",
                                  margin: "auto",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    background: "red",
                                    padding: "3px 7px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                    color: "white",
                                    borderRadius: "50px",
                                    fontSize: "12px",
                                  }}
                                >
                                  <FaCircleChevronRight
                                    size={18}
                                    color="white"
                                  />
                                  JOIN NOW
                                </div>
                              </div>
                            </div>
                          )
                        );
                      }
                    })}
                </>
              )}
            </div>

            <div onClick={() => setClick(true)} style={{ marginBottom: 15 }}>
              {portfolioAllMatches?.length == 0 ? (
                <>
                  <h4
                    style={{
                      textAlign: "center",
                      color: "grey",
                      marginTop: 70,
                    }}
                  >
                    No Records for closed..
                  </h4>
                </>
              ) : (
                !click && (
                  <div>
                    {Array.isArray(portfolioAllMatches) &&
                      portfolioAllMatches?.map((items, index) => {
                        let data = items?.extra_data;
                        return (
                          <React.Fragment Key={index}>
                            {items.type === "match" ? (
                              <div
                                onClick={() =>
                                  toQuestions(items?.id, items?.type)
                                }
                                style={{
                                  width: "92%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                className="upcoming_match_card"
                              >
                                <div></div>
                                <div className="up-headi">
                                  <div
                                    style={{ textAlign: "start" }}
                                    className="match_head"
                                  >
                                    <p>
                                      {data?.league_name?.split(" ").length > 3
                                        ? data?.league_name?.split(" ")[0] +
                                          " " +
                                          data?.league_name?.split(" ")[1] +
                                          " " +
                                          data?.league_name?.split(" ")[2] +
                                          "..."
                                        : data?.league_name}
                                    </p>
                                  </div>

                                  <img
                                    src={matchlogo2}
                                    alt=""
                                    style={{
                                      width: "135px",
                                      height: "25px",
                                      objectFit: "cover",
                                      backgroundImage:
                                        "linear-gradient(to right, rgb(255 255 255), #E2FEE5)",
                                      borderRadius: "5px",
                                      opacity: ".5",
                                    }}
                                  />
                                </div>

                                <Link>
                                  <div className="match_team_details">
                                    <div className="" style={{ width: "35%" }}>
                                      <div className="">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",

                                            justifyContent: "flex-start",
                                            background:
                                              " linear-gradient(50deg,  rgb(240 240 240) , rgb(255,255,255) )",
                                            paddingLeft: "6px",
                                          }}
                                        >
                                          <div className="team_one_logo">
                                            {data.team_1_flag === null ||
                                            data.team_1_flag == undefined ||
                                            data.team_1_flag == "" ? (
                                              <img
                                                src={fav1}
                                                alt=""
                                                style={{ objectFit: "contain" }}
                                              />
                                            ) : (
                                              <img
                                                src={produc(data?.team_1_flag)}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <p className="team_one_nme">
                                            {data?.team_1_abbr?.length > 5
                                              ? data?.team_1_abbr[0] +
                                                data?.team_1_abbr[1] +
                                                data?.team_1_abbr[2] +
                                                data?.team_1_abbr[3] +
                                                data?.team_1_abbr[4] +
                                                "..."
                                              : data?.team_1_abbr}
                                          </p>
                                        </div>
                                        <p
                                          style={{
                                            textAlign: "start",
                                            color: "grey",
                                            paddingLeft: "10px",
                                          }}
                                          className="tem_nme"
                                        >
                                          {data?.team_1_name?.length > 12
                                            ? data?.team_1_name?.slice(0, 12) +
                                              "..."
                                            : data?.team_1_name}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      style={{ alignSelf: "center" }}
                                      className="fixture"
                                    >
                                      <div
                                        style={{
                                          height: "20px",
                                          // width: "50px",
                                          border: "1px solid black",
                                          borderRadius: "3px",
                                          padding: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "black",
                                          fontSize: "13px",
                                          margin: "auto",
                                        }}
                                      >
                                        Completed{" "}
                                        <GoDotFill size={20} color="black" />
                                      </div>
                                    </div>
                                    {/* <div
                                      className="fixture"
                                      style={{ margin: "auto" }}
                                    >
                                      <p style={{ fontWeight: "500" }}>
                                        <div
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            height: 20,
                                            width: 30,
                                            borderRadius: 10,
                                            paddingTop: 2.5,
                                            margin: "auto",
                                          }}
                                        >
                                          Closed
                                        </div>
                                      </p>
                                    </div> */}
                                    <div
                                      style={{
                                        width: "35%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <div className="">
                                        <div className="">
                                          <div
                                            className=""
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                              gap: "2px",
                                              background:
                                                " linear-gradient(50deg,  rgb(255,255,255)  , rgb(247,221,220) 80%)",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "900",
                                                fontSize: "13px",
                                              }}
                                              className="team_one_nme"
                                            >
                                              {data?.team_2_abbr?.length > 5
                                                ? data?.team_2_abbr[0] +
                                                  data?.team_2_abbr[1] +
                                                  data?.team_2_abbr[2] +
                                                  data?.team_2_abbr[3] +
                                                  data?.team_2_abbr[4] +
                                                  "..."
                                                : data?.team_2_abbr}
                                            </div>
                                            <div className="team_two_logo">
                                              {data.team_2_flag == null ||
                                              data.team_2_flag == undefined ||
                                              data.team_2_flag == "" ? (
                                                <img
                                                  src={fav1}
                                                  alt=""
                                                  style={{
                                                    objectFit: "contain",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={produc(
                                                    data?.team_2_flag
                                                  )}
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <p
                                            className="tem_nme"
                                            style={{
                                              textAlign: "start",
                                              color: "grey",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            {data?.team_2_name?.length > 10
                                              ? data?.team_2_name?.slice(
                                                  0,
                                                  10
                                                ) + "..."
                                              : data?.team_2_name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card_below_content">
                                    <div
                                      className="below_content_match"
                                      style={{
                                        borderBottomLeftRadius: "8px",
                                        borderBottomRightRadius: "8px",
                                      }}
                                    >
                                      <span className="percent-details">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <div className="buttonStyle">B</div>
                                          <div
                                            className=""
                                            style={{
                                              color: "green",
                                              alignItems: "center",
                                              display: "flex",
                                            }}
                                          >
                                            <p style={{ fontSize: "12px" }}>
                                              {data?.venue}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div
                                className="market_card"
                                style={{
                                  width: "97%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                onClick={() =>
                                  toQuestions(items?.id, items?.type)
                                }
                                key={index}
                              >
                                <div
                                  className="market_crd_img"
                                  style={{ backgroundColor: "white" }}
                                >
                                  {data?.market_banner == null ||
                                  data?.market_banner == undefined ||
                                  data?.market_banner == "" ? (
                                    <>
                                      <img
                                        src={banner}
                                        style={{ borderRadius: "5px" }}
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={produc(data?.market_banner)}
                                        alt="banner"
                                      />
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{ borderRadius: "0px" }}
                                  className="content_market_below"
                                >
                                  <div
                                    className="card_below_content"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="below_content">
                                      <span className="percent-details">
                                        {items?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer2 />
        </div>
      </div>
    </div>
  );
}
