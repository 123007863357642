import React, { useEffect, useState } from "react";
import Footer4 from "../Components/Footer4";
import style from "../Auth/style copy.css";
import TopNav from "./TopNav";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faAnglesDown,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { getFaqs } from "../Redux/GetSlice";

export default function Faq() {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState("");

  const { faqs } = useSelector((state) => state.Get);

  useEffect(() => {
    dispatch(getFaqs());
  }, []);

  const Finally = (val) => {
    if (val == expand) {
      setExpand("");
    } else {
      setExpand(val);
    }
  };

  const data = [
    {
      id: 1,
      head: " 1. What is Tradexbet?",
      content:
        " is a cricket betting platform where users can place bets on various cricket matches and events happening around the world. We provide a secure and user-friendly platform for cricket enthusiasts to enjoy betting on their favorite teams and players.",
    },
    {
      id: 2,
      head: "2. How do I get started with Tradexbet?",
      content:
        " To get started with Tradexbet, simply sign up for an account on our platform. Once you have registered, you can deposit funds into your account and start placing bets on upcoming cricket matches.",
    },
    {
      id: 3,
      head: "3. Is Tradexbet legal?",
      content:
        "Yes, Tradexbet operates within the legal framework of the jurisdictions it serves. However, it is your responsibility to ensure that online betting is legal in your country or region before using our platform.",
    },
    {
      id: 4,
      head: "4. How can I deposit funds into my account?",
      content:
        " You can deposit funds into your Tradexbet account using various payment methods such as credit/debit cards, bank transfers, e-wallets, and other secure payment options available on our platform. Simply navigate to the deposit section of your account and follow the instructions provided.",
    },
    {
      id: 5,
      head: "5. How do I place a bet on a cricket match?",
      content:
        " To place a bet on a cricket match, navigate to the cricket betting section of our platform and select the match you're interested in. Choose the type of bet you want to place (e.g., match winner, top batsman, total runs, etc.) and enter the stake amount. Confirm your bet, and it will be placed automatically.",
    },
  ];

  return (
    <div className="mobile__layout main">
      <div className="container auth__container">
        {/* <div style={{position:"sticky",top:"0"}}>
            <TopNav/>
           </div> */}

        <div
          style={{ position: "sticky", top: "0", height: "56px" }}
          className="reg-back"
        >
          <div className="reg__back">
            <Link to="/more">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>
          </div>
          <p>Frequently Asked Questions</p>
        </div>

        <div className="mid_content">
          <div className="faq_main">
            {/* <div style={{backgroundColor:"white",borderRadius:"5px"}}> */}
            {Array.isArray(faqs) &&
              faqs?.map((items, index) => {
                return (
                  <div
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                    key={index}
                  >
                    <Accordion
                      onChange={() => Finally(items?.faq_id)}
                      style={{
                        backgroundColor: "white",
                        cursor: "pointer",
                        borderRadius: "5px",
                      }}
                      allowZeroExpanded
                    >
                      <AccordionItem>
                        <AccordionItemHeading
                          style={{
                            padding: "17px 0 17px 10px",
                            borderRadius: "20px",
                          }}
                        >
                          <AccordionItemButton
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4>{items?.faq_question}</h4>
                            {expand == items?.faq_id ? (
                              <FontAwesomeIcon
                                style={{ marginRight: "15px" }}
                                icon={faAngleUp}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{ marginRight: "15px" }}
                                icon={faAngleDown}
                              />
                            )}
                          </AccordionItemButton>
                        </AccordionItemHeading>

                        <AccordionItemPanel
                          style={{
                            height: "130px",
                            backgroundColor: "#f3f3f3",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "15px 10px 15px 10px",
                              borderTop: "1px solid lightgrey",
                            }}
                          >
                            {items?.faq_answer}
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                    <hr style={{ border: "0.5px solid lightgrey" }} />
                  </div>
                );
              })}
            {/* </div> */}
          </div>
        </div>
      </div>
      <div style={{ position: "sticky", bottom: "0" }}>
        <Footer4 />
      </div>
      <div className="Toastify"></div>
    </div>
  );
}
