import React, { useState } from "react";
import Loader from "./../Components/Loader";
import { Link } from "react-router-dom";
import OTPInput from "react-otp-input";

export default function LoginOtp({ otpCheck, timer }) {
  const [msg, setMsg] = useState("");
  const [rotp, setRotp] = useState("");

  return (
    <div className="otpMain">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "15%",
        }}
      >
        <div className="style_title__small__3ZhHV">{msg}</div>
        <div className="style_form__fields__5NhNp">
          <div className="form__group">
            <div className="form__field__input__wrapper">
              <div className="form__field__input">
                <div className="input-field">
                  <OTPInput
                    value={rotp}
                    onChange={setRotp}
                    numInputs={6}
                    containerStyle={{ alignSelf: "center" }}
                    renderSeparator={<span></span>}
                    inputStyle={{
                      width: "42px",
                      marginRight: "12px",
                      marginLeft: "4px",
                      height: "42px",
                      border: "2px soid lightgrey",
                      background: "#f5f5f5",
                      borderRadius: "5px",
                    }}
                    renderInput={(props) => <input {...props} type="number" />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <p style={{ color: "grey" }}>
          Didn't receive OTP? Resend in{" "}
          <span style={{ fontWeight: "700", color: "black" }}>
            {timer} seconds
          </span>
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%",
          width: "100%",
        }}
      >
        <button
          id="sign-in-button"
          onClick={() => {
            otpCheck(rotp);
          }}
          className="btn btn__primary"
          style={{ transform: "none", width: "90%" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            continue{" "}
          </div>
        </button>
      </div>

      <div
        style={{ fontWeight: "600" }}
        className="style_form__tnc__2ZWri style_form__tnc__login__36I5q"
      >
        By continuing, I accept Tragoxbet's
        <Link to="/tnc">
          <span style={{ fontWeight: "700" }} className="style_link__1wrGI">
            {" "}
            T&C
          </span>
        </Link>
      </div>

      {/* <button style={{ height: "50px", width: "200px", margin: "auto" }} onClick={() => setOpenOTP(false)} >
     close
   </button> */}
    </div>
  );
}
