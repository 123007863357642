import React, { useEffect } from "react";
import Footer4 from "../Components/Footer4";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHowtoplay } from "../Redux/GetSlice";
import { produc } from "../Redux/Helper";
import banner from "../Assets/Images/hero_image.avif";

export default function HowToPlay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getHowtoplay());
  }, []);

  const { pages } = useSelector((state) => state.Get);

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container">
          <div
            style={{ position: "sticky", top: "0", height: "56px" }}
            className="reg-back"
          >
            <div className="reg__back">
              <button onClick={() => navigate(-1)}>
                <i className="fa-solid fa-arrow-left"></i>
              </button>
            </div>
            <p>How to Play</p>
          </div>

          <div className="mid_content">
            <div className="terms_img">
              {pages?.howToPlay?.banner_image == null || undefined || "" ? (
                <img src={banner} alt="" />
              ) : (
                <img src={produc(pages?.howToPlay?.banner_image)} alt="" />
              )}
            </div>

            <div className="terms_content">
              <div className="summary_contents">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pages?.howToPlay?.html_content,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="Toastify"></div>
        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer4 />
        </div>
      </div>
    </div>
  );
}
