import React, { useState, useEffect } from "react";
import {
  MdConnectWithoutContact,
  MdEditDocument,
  MdWbIncandescent,
} from "react-icons/md";
import { IoMdChatboxes, IoMdCode, IoMdLock } from "react-icons/io";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTag } from "react-icons/fa6";
import install from "../Assets/Images/download.png";
import {
  faCircleQuestion,
  faPercent,
  faPersonCircleQuestion,
  faPhone,
  faCode,
  faShieldHalved,
  faUserLock,
  faLightbulb,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import TopNav from "./TopNav";
import Footer from "../Components/Footer";
import Footer4 from "../Components/Footer4";
import SideNav from "./SideNav";
import TopNavWOL from "./TopNavWOL";
import googlePlayImg from "../Assets/Images/googlePlay.png";
import Navbar from "../Components/Navbar";
import caseImg from "../Assets/Images/case.png";

export default function More() {
  const [side, setSide] = useState(false);

  const openSideNav = () => {
    setSide(true);
    //   console.log("Open");
  };
  const closeSideNav = () => {
    setSide(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    return ()=>{
      window.scrollTo({top:0});
      }
  }, []);

  const id = localStorage.getItem("user_id");

  return (
    <div>
      {side ? (
        <>
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>
      ) : (
        <></>
      )}

      <div className="mobile__layout main">
        <div
          style={{ minHeight: "100vh", background: "#f3f3f3" }}
          className="container auth__container"
        >
          <div style={{ position: "sticky", top: "0", height: "56px" }}>
            {id !== "" && id !== undefined && id !== null ? (
              // <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />
              <Navbar side={side} onOpen={openSideNav} onClose={closeSideNav} />
            ) : (
              <TopNavWOL />
            )}
          </div>
          {/* <div>
            <Navbar side={side} onOpen={openSideNav} onClose={closeSideNav} />
          </div> */}
          {/* <div className="reg-back" style={{ boxShadow: "0px 0px 7px black" }}>
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>More</p>
          </div> */}

          <div style={{ background: "#f2f2f2" }} className="mid_content">
            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/livechat" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      {/* <i className="fa-solid fa-comment">
                      </i> */}
                      <IoMdChatboxes
                        size={21}
                        color="black"
                        className="iconStyle"
                      />
                    </div>
                    <p>Live chat</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>

            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/contact" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      <i>
                        {/* <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faPhone}
                        /> */}
                        <MdConnectWithoutContact
                          size={21}
                          color="black"
                          className="iconStyle"
                        />
                      </i>
                    </div>
                    <p>Contact Us</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>
            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/refer" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      {/* <i>
                        <FontAwesomeIcon icon={faCode} />
                      </i> */}
                      <IoMdCode size={21} color="black" className="iconStyle" />
                    </div>
                    <p>Refer and Earn</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>
            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/offer" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      {/* <i>
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faPercent}
                        />
                      </i> */}

                      <FaTag size={18} color="black" className="iconStyle" />
                    </div>
                    <p>Offers</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>

            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/htp" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      {/* <i>
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faCircleQuestion}
                        />
                      </i> */}
                      <img
                        src={caseImg}
                        alt=""
                        srcset=""
                        className="imgIconStyl"
                      />
                    </div>
                    <p>How to Play</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>
            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/aboutus" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      {/* <i>
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faLightbulb}
                        />
                      </i> */}
                      <MdWbIncandescent
                        size={18}
                        color="black"
                        className="iconStyle"
                      />
                      {/* MdWbIncandescent */}
                    </div>
                    <p>About Us</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>
            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/faq" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      <i>
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faCircleQuestion}
                          className="iconStyle"
                        />
                      </i>
                    </div>
                    <p>Frequently Asked Questions</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>

            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/privacy" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      {/* <i>
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faShieldHalved}
                        />
                      </i> */}

                      <IoMdLock size={21} color="black" className="iconStyle" />
                    </div>
                    <p>Privacy Policy</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>

            <div style={{ width: "96%", margin: "auto" }} className="conte_box">
              <Link to="/TnC" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                      {/* <i>
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faUserLock}
                        />
                      </i> */}

                      <MdEditDocument
                        size={21}
                        color="black"
                        className="iconStyle"
                      />
                    </div>
                    <p>Terms & Conditions</p>
                  </div>
                  <div className="right-co">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div>

            {/* <div style={{width:"96%",margin:"auto",}} className="conte_box">
              <Link to="" className="content_box_shadow">
                <div className="content_box_inner ">
                  <div className="left-co">
                    <div className="icon_s">
                     <img src={install} style={{height:"20px",width:"20px"}} alt="" />
                    </div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "800",
                        color: "rgb(87 87 87)",
                      }}
                    >
                      Install mobile app
                    </p>
                  </div>

                  <div
                    className="right-co"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={googlePlayImg}
                      alt=""
                      style={{ height: "35px", width: "100px" }}
                    />
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
          <div
            style={{
              background: "#f2f2f2",
              marginTop: "-12px",
              height: "100px",
            }}
          >
            <p
              style={{
                fontSize: "10px",
                textAlign: "center",
                color: "grey",
                paddingBottom: "10px",
              }}
            >
              App version : 3.33
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                color: "#489b49",
                fontWeight: "800",
                paddingBottom: "30px",
              }}
            >
              UPDATE APP
            </p>
          </div>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer4 />
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
