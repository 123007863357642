import React from "react";
import { produc } from "../Redux/Helper";
import jersey from "../Assets/Images/jerseypng.png";
import { TradeTypography } from "./TradeTypography";

export default function EventDetstatusandborder({
  items,
  betInfos,
  exitPop,
  status,
}) {
  return (
    <div style={{ marginTop: 0 }}>
      <div onClick={() => betInfos()} style={{ marginBottom: "10px" }}>
        <div
          style={{
            width: "97%",
            borderRadius: "20px",
            margin: "auto",
          }}
          className="rslt__crdSimple"
        >
          <div style={{ marginBottom: "15px" }}>
            <div
              style={{
                fontSize: "13px",
                width: "90px",
                height: "20px",
                textAlign: "center",
                borderRadius: "50px",
                color: "#00793c",
                paddingTop: "1px",
                backgroundColor: "#c5ffdd",
              }}
            >
              <TradeTypography>Matched</TradeTypography>
            </div>
          </div>
          <div className="top__dt">
            <div>
              {items?.matched?.question?.question_image == null ||
              items?.matched?.question?.question_image == undefined ||
              items?.matched?.question?.question_image == "" ? (
                <img
                  src={jersey}
                  alt="img"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                  src={produc(items?.matched?.question?.question_image)}
                  alt=""
                />
              )}
            </div>

            <TradeTypography>
              {items?.matched?.question?.question_text}{" "}
            </TradeTypography>
          </div>
          <>
            {items?.matched?.bets?.length == 0 ? (
              <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                No Records
              </h5>
            ) : (
              <div className="fl-det">
                <div className="lower_dtls">
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Choose</TradeTypography>
                    </div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Invest</TradeTypography>
                    </div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Buy Price</TradeTypography>
                    </div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Quantity</TradeTypography>
                    </div>
                  </div>
                  {/* <div className="box">
                    <div className="hd_blo">Result</div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">Returns</div>
                  </div> */}
                </div>

                {items?.matched?.bets?.map((items, index) => {
                  return (
                    <div key={index} className="lower_dtls">
                      <div className="rslt_blo">{items?.choose} </div>
                      <div className="rslt_blo" style={{ marginRight: 12 }}>
                        ₹{items?.invest}
                      </div>
                      <div className="rslt_blo" style={{ marginRight: 14 }}>
                        ₹{items?.buy_price}{" "}
                      </div>
                      <div style={{ marginTop: "5px" }} className="rslt_blo">
                        {items?.quantity}
                      </div>
                      {/* <div className="rslt_blo rd">Option 2</div>
                      <div className="rslt_blo">₹999</div> */}
                    </div>
                  );
                })}

                <div className="lw_cnc">
                  <div className="unmatched_qty">
                    <div className="icon_">
                      <i className="fa-solid fa-money-check"></i>
                    </div>
                    <div className="context_">
                      <TradeTypography>
                        Invested ₹{items?.matched?.total_invested}
                      </TradeTypography>
                    </div>
                  </div>

                  <div>
                    <div
                      onClick={() => exitPop(items)}
                      style={{
                        width: "70px",
                        padding: 4,
                        backgroundColor: "grey",
                        cursor: "pointer",
                        color: "white",
                        borderRadius: "4px",
                        textAlign: "center",
                      }}
                    >
                      <TradeTypography>Exit</TradeTypography>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
}
