import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Typography } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { faAngleDown, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import moment from "moment";

const EventDetails = ({ items, closeddd, link, sot }) => {
  return (
    <div
      style={{
        maxWidth: "420px",
        margin: "auto",
        zIndex: 999,
        // maxHeight: "400px",
        maxHeight: "60vh",
        boxShadow: "1px 1px 100px 10px",
      }}
      className="style_container__IcEZT"
    >
      <div style={{ display: "flex" }}>
        <div style={{ fontSize: "20px", fontWeight: "800", width: "50%" }}>
          About the event
        </div>
        <FontAwesomeIcon
          color="grey"
          onClick={closeddd}
          style={{
            marginLeft: "210px",
            height: "25px",
            cursor: "pointer",
          }}
          icon={faXmarkCircle}
        />
      </div>
      <table className="table">
        <tbody>
          <tr>
            <td style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <p>ID</p>
              <h4>{items?.question_id}</h4>
            </td>
            <td>
              <div
                style={{
                  border: "0px solid black",
                  justifyContent: "flex-end",
                }}
                className="copy-text info"
              >
                <Typography
                  style={{
                    color: "white",
                    paddingRight: "14px",
                  }}
                >
                  Share Content
                </Typography>
                <CopyToClipboard
                  text={link(items?.question_id)}
                  onCopy={() => toast.success("Copied to Clipboard")}
                >
                  <button
                    style={{
                      border: "1px solid #007bff",
                      backgroundColor: "#007bff",
                      height: "28px",
                      width: "28px",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <i style={{ color: "white" }} className="fa fa-clone"></i>
                  </button>
                </CopyToClipboard>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <p>Started at</p>
              <h4>{moment(items?.created_at).format("Do MMM YY, hh:mm a")}</h4>
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ height: "78%", overflowY: "scroll" }}>
        {[
          {
            id: "overview",
            title: "Overview",
            description: "Information about event",
            content:
              items?.question_long_desc || "Event overview will update soon.",
          },
          {
            id: "Source_of_truth",
            title: "Source of truth",
            description: "Information about source of truth",
            content: (
              <>
                To know more,{" "}
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => sot(items?.source_of_truth)}
                >
                  click here
                </span>
              </>
            ),
          },
          {
            id: "rules",
            title: "Rules",
            description: "Terms and conditions",
            content: items?.rules || "No rules defined yet.",
          },
        ].map(({ id, title, description, content }, index) => (
          <div
            key={id}
            style={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <Accordion allowZeroExpanded preExpanded={["rules"]}>
              <AccordionItem uuid={id}>
                <AccordionItemHeading
                  style={{
                    padding: "17px 0 0 10px",
                  }}
                >
                  <AccordionItemButton
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4>
                      {title}
                      <p style={{ fontWeight: "300", paddingTop: "5px" }}>
                        {description}
                      </p>
                    </h4>
                    <FontAwesomeIcon
                      style={{ marginRight: "15px" }}
                      icon={faAngleDown}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  style={{
                    backgroundColor: "#f3f3f3",
                    padding: "10px",
                    borderTop: "1px solid lightgrey",
                  }}
                >
                  <p style={{ fontSize: "14px" }}>{content}</p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <hr style={{ border: "0.5px solid lightgrey" }} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default EventDetails;
