import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PortfCompos from "../Components/PortfCompos";
import TopNav from "./TopNav";
import jersey from "../Assets/Images/jersey.png";
import Footer from "../Components/Footer";
import { cancelExitBet, portfolio } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import exitStyle from "../Components/exitpop.css";
import Footer2 from "../Components/Footer2";
import SideNav from "./SideNav";
import { cancelBet } from "../Redux/PostSlice";
import toast from "react-hot-toast";
import TopNavWOL from "./TopNavWOL";
import { getClosedEvents } from "../Redux/GetSlice";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import { userBalance } from "../Redux/GetSlice";
import { exitBet } from "../Redux/PostSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../Assets/Images/hero_image.avif";
import exitpng from "../Assets/Images/exitpng2.png";
import {
  faArrowLeft,
  faEdit,
  faL,
  faMinus,
  faPlus,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo4 from "../Assets/Images/fav1.png";
import { io } from "socket.io-client";
import { getPortDetails } from "../Redux/PostSlice";
import PLiveMatches from "./PLiveMatches";
import { getPortfolioMatches } from "../Redux/GetSlice";
import "./../Auth/style.css";
import { HiArrowLeft } from "react-icons/hi2";
import { FaCircleChevronRight } from "react-icons/fa6";
import EventDetstatusandborder from "./../Components/EventDetstatusandborder";
import EventDetWithwatermarkstatusInvest from "../Components/EventDetWithwatermarkstatusInvest";
import EventExitcancelsimpleclosed from "../Components/EventExitcancelsimpleclosed";
import { Typography } from "@mui/material";
import { TradeTypography } from "../Components/TradeTypography";

const socket = io.connect(RT_BASE_URL);

export default function PLive() {
  const { portFolios } = useSelector((state) => state.Post);
  const { portfolioDetails } = useSelector((state) => state.Post);
  const { portfolioMatches } = useSelector((state) => state.Get);

  const [portfolioAllMatches, setportfolioAllMatches] = useState([]);
  const [click, setClick] = useState(false);
  const [quests, setQuests] = useState([{}]);
  const [range, setRange] = useState(5);
  const [exit_quantity, setExit_quantity] = useState("");
  const [toQuest, setToQuest] = useState(false);
  const [mID, setMID] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [exitInput, setExitInput] = useState(4.5);

  const params = useParams();

  useEffect(() => {
    if (portfolioMatches?.length > 0) {
      setportfolioAllMatches(
        portfolioMatches?.map((item) => {
          return {
            ...item,
            extra_data: item?.extra_data ? JSON.parse(item?.extra_data) : {},
          };
        })
      );
    } else {
      setportfolioAllMatches([]);
    }
  }, [JSON.stringify(portfolioMatches)]);

  useEffect(() => {
    setQuests(portFolios);
  }, []);

  useEffect(() => {
    socket.on("questionUpdated", (data) => {
      let socketData = JSON.parse(data);
      const formData = new FormData();
      formData.append("user_id", id);
      dispatch(portfolio(formData));
    });
  }, []);

  let arrTest = [1, 2];

  const dispatch = useDispatch();

  const id = localStorage.getItem("user_id");

  const formData = new FormData();
  formData.append("user_id", id);

  useEffect(() => {
    dispatch(portfolio(formData));
  }, []);
  const handleGetPortfolioMatches = () => {
    let data = {
      id: id,
      status: "Live",
    };
    dispatch(getPortfolioMatches(data));
  };
  useEffect(() => {
    handleGetPortfolioMatches();
  }, []);

  const toQuestions = (mId, type) => {
    setClick(true);
    setMID(mId);
    setSelectedType(type);
  };

  const userID = localStorage.getItem("user_id");

  const [side, setSide] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalReturns, setTotalReturns] = useState(0);
  const [quesCardId, setQuesCardId] = useState(1);

  const openSideNav = () => {
    setSide(true);
    // console.log("Open");
  };
  const closeSideNav = () => {
    setSide(false);
  };

  const { cancelResponse } = useSelector((state) => state.Post);

  const [canceled, setCanceled] = useState(false);
  const [canceleExit, setCancelExit] = useState(false);
  const [exit, setExit] = useState(false);
  const [exitInfo, setexitInfo] = useState({});
  const [cancelInfo, setcancelInfo] = useState({});
  const [cancelUcheck, setCancelUcheck] = useState(null);
  const [orderID, setOrderID] = useState("");

  const [cancelMcheck, setCancelMcheck] = useState(null);
  const [exitMcheck, setExitMcheck] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (cancelResponse) {
      setCanceled(false);
      setCancelExit(false);
      setExit(false);
    }
  }, [cancelResponse]);

  const handleConfirm = () => {
    alert("Confirmed!");
    // Add your logic for confirmation here
  };

  // const chngevalminus = () => {
  //   if (range !== 0.5) {
  //     setRange(parseFloat(range) - 0.5);
  //   }
  // };
  // const chngevalplus = () => {
  //   if (range !== 9.5) {
  //     setRange(parseFloat(range) + 0.5);
  //   }
  // };

  const cancelPop = () => {
    setCanceled(true);
  };
  const cancelExitingPop = () => {
    setCancelExit(true);
  };

  const rangeChange = (event) => {
    setRange(parseFloat(event.target.value));
  };

  const exitPop = (items) => {
    setExit(true);
    setexitInfo(items);
  };

  const chngevalminus = () => {
    if (parseFloat(exitInput) !== 0.5) {
      setExitInput(parseFloat(exitInput) - 0.5);
    }
  };
  const chngevalplus = () => {
    if (parseFloat(exitInput) !== 9.5) {
      setExitInput(parseFloat(exitInput) + 0.5);
    }
  };

  const betInfos = (id) => {
    setToQuest(true);
    setClick(true);
    setQuesCardId(id);
    const formData = new FormData();
    formData.append("question_id", id);
    formData.append("user_id", userID);
    dispatch(getPortDetails(formData));
  };

  const runCancel = () => {
    const formData = new FormData();
    formData.append("order_id", orderID);
    dispatch(cancelBet(formData));

    dispatch(exitBet(formData)).then(() => {
      handleGetPortfolioMatches();
      dispatch(getPortDetails(formData));
    });
  };
  const runCancelExit = () => {
    console.log(".....exit order_id....", orderID);
    if (orderID === "") {
      return;
    }
    const formData = new FormData();
    formData.append("order_id", orderID);
    dispatch(cancelExitBet(formData));
  };

  const runExit = () => {
    if (orderID === "") {
      return;
    }
    const formData = new FormData();
    formData.append("order_id", orderID);
    formData.append("qty_exit_requested", exit_quantity);
    formData.append("amount", exitInput);
    console.log("calls.... exit");
    dispatch(exitBet(formData)).then(() => {
      handleGetPortfolioMatches();
      dispatch(portfolio(formData));
    });
    // window.location.reload();
  };
  useEffect(() => {
    exit && (document.body.style.overflow = "hidden");
    !exit && (document.body.style.overflow = "unset");
  }, [exit]);
  useEffect(() => {
    canceleExit && (document.body.style.overflow = "hidden");
    !canceleExit && (document.body.style.overflow = "unset");
  }, [canceleExit]);
  useEffect(() => {
    canceled && (document.body.style.overflow = "hidden");
    !canceled && (document.body.style.overflow = "unset");
  }, [canceled]);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [toQuest, click]);
  const CancelPopup = ({ items, close }) => {
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close(false); // Close the popup if clicked outside
      }
    };
    const closeddd = () => {
      close(false);
    };
    useEffect(() => {
      // Attach the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Clean up the event listener on unmount
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div style={{}}>
        <div
          ref={popupRef}
          style={{
            maxWidth: "420px",
            margin: "auto",
            boxShadow: "1px 1px 100px 10px",
            zIndex: "999999",
            position: "fixed",
            position: "relative",
            color: "black",
            borderRadius: "35px 35px 0px 0px",
            padding: "14px",
            background: "white",
          }}
          // className="style_container__IcEZT"
        >
          <div
            onClick={() => setCanceled(false)}
            style={{
              height: "10px",
              width: "10%",
              background: "white",
              borderRadius: "50px",
              position: "absolute",
              top: "-18px",
              left: "45%",
            }}
          ></div>
          <div
            style={{ height: "90px", borderBottom: "2px solid white" }}
            className="top__dt"
          >
            <div style={{ height: "55px" }} className="plcehlder">
              {items?.matched?.question?.question_image == null ||
              items?.matched?.question?.question_image == undefined ||
              items?.matched?.question?.question_image == "" ? (
                <img style={{ objectFit: "cover" }} src={jersey} alt="img" />
              ) : (
                <img
                  src={produc(items?.matched?.question?.question_image)}
                  alt=""
                />
              )}
            </div>
            <p style={{ color: "black", fontSize: "15px" }}>
              {items?.matched?.question?.question_text}{" "}
            </p>
          </div>
          <hr style={{ border: "0.5px solid #ededed" }} />

          <div
            style={{
              margin: "auto",
              marginTop: "20px",
              background: "black",
              color: "white",
              padding: "2px",
              width: "50%",
            }}
          >
            Select orders to Cancel
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: 600,
                fontSize: "14px",
                marginBottom: "20px",
                marginTop: "25px",
              }}
            >
              <div>Choose</div>
              <div>Invest</div>
              <div>Buy price</div>
              <div>Quantity</div>
              <div>Select</div>
            </div>

            <div
              style={{
                marginBottom: "20px",
                maxHeight: 100,
                overflow: "scroll",
              }}
            >
              {items?.unmatched?.bets?.map((elems, ind) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                      margin: "auto",
                      columnGap: 25,
                      fontWeight: 400,
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ color: "skyblue" }}>{elems?.choose} </div>
                    <div style={{ marginRight: 12 }}>₹{elems?.invest} </div>
                    <div style={{ marginRight: 30 }}>₹{elems?.buy_price} </div>
                    <div>{elems?.quantity}</div>
                    <input
                      checked={ind == cancelUcheck ? true : false}
                      onChange={() => {
                        setCancelUcheck(ind);
                        setOrderID(elems?.order_id);
                        console.log(orderID, "orderID-----------=======inside");
                      }}
                      style={{ border: "1px solid black" }}
                      type="checkbox"
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div
            onClick={runCancel}
            style={{
              textAlign: "center",
              backgroundColor: "black",
              borderRadius: "10px",
              textDecoration: "none",
              height: "50px",
              width: "95%",
              margin: "auto",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                color: "white",
                padding: "10px",
                lineHeight: "30px",
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    );
  };

  const CancelExitPopup = ({ items, close }) => {
    const closeddd = () => {
      close(false);
    };
    return (
      <div
        style={{
          maxWidth: "420px",
          margin: "auto",
          boxShadow: "1px 1px 100px 10px",
          zIndex: "999999",
          position: "relative",
          backgroundColor: "white",
          borderRadius: "35px 35px 0px 0px ",
          padding: "14px",
        }}
        // className="style_container__IcEZT"
      >
        <div
          onClick={() => setCancelExit(false)}
          style={{
            height: "10px",
            width: "10%",
            background: "white",
            borderRadius: "50px",
            position: "absolute",
            top: "-18px",
            left: "45%",
          }}
        ></div>
        <div
          style={{ height: "90px", borderBottom: "2px solid white" }}
          className="top__dt"
        >
          <div style={{ height: "55px" }} className="plcehlder">
            {items?.matched?.question?.question_image == null ||
            items?.matched?.question?.question_image == undefined ||
            items?.matched?.question?.question_image == "" ? (
              <img style={{ objectFit: "cover" }} src={jersey} alt="img" />
            ) : (
              <img
                src={produc(items?.matched?.question?.question_image)}
                alt=""
              />
            )}
          </div>
          <p style={{ color: "black", fontSize: "15px" }}>
            {items?.matched?.question?.question_text}{" "}
          </p>
        </div>
        <hr style={{ border: "0.5px solid #ededed" }} />

        <div
          style={{
            margin: "auto",
            marginTop: "20px",
            background: "black",
            color: "white",
            padding: "2px",
            width: "60%",
          }}
        >
          Select orders to Cancel
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 600,
              fontSize: "14px",
              marginBottom: "20px",
              marginTop: "25px",
            }}
          >
            <div>Choose</div>
            <div>Invest</div>
            <div>Buy price</div>
            <div>Quantity</div>
            <div>Select</div>
          </div>

          <div
            style={{
              marginBottom: "20px",
              overflow:
                portfolioDetails?.unmatched?.bets?.length >= 5
                  ? "scroll"
                  : "hidden",
            }}
          >
            {portfolioDetails?.exiting?.bets?.map((elems, ind) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "95%",
                    margin: "auto",
                    columnGap: 25,
                    fontWeight: 400,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ color: "skyblue" }}>{elems?.choose} </div>
                  <div>₹{elems?.invest} </div>
                  <div>₹{elems?.buy_price} </div>
                  <div>{elems?.quantity} </div>
                  <input
                    checked={ind == cancelUcheck ? true : false}
                    onChange={() => {
                      setCancelUcheck(ind);
                      setOrderID(elems?.order_id);
                      setExit_quantity(elems?.quantity);
                      console.log(orderID, "orderID-----------=======inside");
                    }}
                    style={{ border: "1px solid black" }}
                    type="checkbox"
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div
          onClick={runCancelExit}
          style={{
            textAlign: "center",
            backgroundColor: "black",
            borderRadius: "10px",
            textDecoration: "none",
            height: "50px",
            width: "95%",
            margin: "auto",
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              color: "white",
              padding: "10px",
              lineHeight: "30px",
            }}
          >
            Cancel
          </p>
        </div>
      </div>
    );
  };

  const Exitpop = ({ items }) => {
    return (
      <div
        style={{
          maxWidth: "420px",
          margin: "auto",
          boxShadow: "1px 1px 100px 10px",
          zIndex: "999999",
          position: "relative",
        }}
        className="style_container__IcEZT"
      >
        <div
          onClick={() => setExit(false)}
          style={{
            height: "10px",
            width: "10%",
            background: "white",
            borderRadius: "50px",
            position: "absolute",
            top: "-18px",
            left: "45%",
            cursor: "pointer",
          }}
        ></div>
        <div
          style={{ height: "90px", borderBottom: "2px solid white" }}
          className="top__dt"
        >
          <div style={{ height: "55px" }} className="plcehlder">
            {items?.matched?.question?.question_image == null ||
            items?.matched?.question?.question_image == undefined ||
            items?.matched?.question?.question_image == "" ? (
              <img style={{ objectFit: "cover" }} src={jersey} alt="img" />
            ) : (
              <img
                src={produc(items?.matched?.question?.question_image)}
                alt=""
              />
            )}
          </div>
          <p style={{ color: "black", fontSize: "15px" }}>
            {items?.matched?.question?.question_text}{" "}
          </p>
        </div>
        <hr style={{ border: "0.5px solid #ededed" }} />

        <div
          style={{
            width: "50%",
            height: "15px",
            margin: "auto",
            marginTop: "20px",
          }}
        >
          <img src={exitpng} style={{ width: "100%" }} alt="" />
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: 600,
              fontSize: "14px",
              marginBottom: "20px",
              marginTop: "25px",
            }}
          >
            <div>Choose</div>
            <div>Invest</div>
            <div>Buy price</div>
            <div>Quantity</div>
            <div>Select</div>
          </div>

          <div
            style={{
              height: 50,
              overflow: "scroll",
            }}
          >
            {portfolioDetails?.matched?.bets?.map((elems, ind) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: 400,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ color: "skyblue" }}>{elems?.choose} </div>
                  <div>₹{elems?.invest} </div>
                  <div style={{ paddingRight: 8 }}>₹{elems?.buy_price} </div>
                  <div style={{ marginRight: 14 }}>{elems?.quantity} </div>
                  <input
                    checked={ind == cancelUcheck ? true : false}
                    onChange={() => {
                      setCancelUcheck(ind);
                      setOrderID(elems?.order_id);
                      setExit_quantity(elems?.quantity);
                    }}
                    style={{ border: "1px solid black", marginRight: 14 }}
                    type="checkbox"
                  />
                </div>
              );
            })}
          </div>
        </div>

        <hr style={{ border: "0.5px solid #ededed" }} />

        <div
          style={{
            background: "#f7f8fa",
            height: "auto",
            marginTop: "10px",
            padding: "10px",
          }}
        >
          <div className="custom_set">
            <div className="set_price">Set price</div>
          </div>

          <div
            className="inputFeilds"
            style={{
              width: "95%",
              margin: "auto",
              background: "white",
              border: "2px solid #ededed",
              padding: "10px",
              marginTop: "15px",
              borderRadius: "15px",
            }}
          >
            <div className="slider_price">
              <div className="upper_actions_info">
                <label for="customRange1" className="form-label">
                  Price
                </label>
                <div className="right_infor">
                  <div style={{ fontSize: "16px" }} className="price_info">
                    ₹{exitInput}{" "}
                  </div>
                </div>
              </div>
              <div className="slider_actions">
                <div
                  style={{ padding: "1px 5px", borderRadius: "2px" }}
                  className="minus_icon"
                >
                  <i>
                    <FontAwesomeIcon
                      onClick={chngevalminus}
                      color="black"
                      icon={faMinus}
                    />
                  </i>
                </div>

                <div className="slider-container">
                  <input
                    type="range"
                    min={0.5}
                    max={9.5}
                    value={exitInput}
                    step={0.5}
                    onChange={(event) => {
                      if (
                        parseFloat(event.target.value) >= 0.5 &&
                        parseFloat(event.target.value) <= 9.5
                      )
                        setExitInput(parseFloat(event.target.value));
                    }}
                    className="slider"
                    style={{
                      background: `linear-gradient(to right, black ${
                        exitInput * 10
                      }%, #ccc ${exitInput * 10}%)`,
                    }}
                  />
                </div>

                <div
                  style={{ padding: "1px 5px", borderRadius: "2px" }}
                  className="plus_icon"
                >
                  <i>
                    <FontAwesomeIcon
                      onClick={chngevalplus}
                      color="black"
                      icon={faPlus}
                    />
                  </i>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "10px",
                borderTop: "2px dotted lightgrey",
                margin: "auto",
                marginTop: "15px",
              }}
            ></div>

            <div
              style={{ margin: "auto", textAlign: "center", marginTop: "15px" }}
            >
              <h3>₹{exitInput}</h3>
              <div
                style={{
                  background: "#f6c60f",
                  color: "black",
                  width: "47%",
                  margin: "auto",
                  fontSize: "12px",
                  padding: "2px 5px",
                  borderRadius: "4px",
                  fontWeight: "600",
                  marginTop: "15px",
                }}
              >
                Total Exiting Amount:
              </div>
            </div>
          </div>

          <div
            onClick={runExit}
            style={{
              textAlign: "center",
              backgroundColor: "black",
              borderRadius: "10px",
              textDecoration: "none",
              height: "50px",
              width: "95%",
              margin: "auto",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                color: "white",
                padding: "10px",
                lineHeight: "30px",
              }}
            >
              Exit now
            </p>
          </div>
        </div>
      </div>
    );
  };

  const PortFolioLiveCards = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={betInfos} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "20px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#00793c",
                  paddingTop: "1px",
                  backgroundColor: "#c5ffdd",
                }}
              >
                Matched
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.matched?.question?.question_image == null ||
                items?.matched?.question?.question_image == undefined ||
                items?.matched?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.matched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.matched?.question?.question_text} </p>
            </div>
            <>
              {items?.matched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    {/* <div className="box">
                        <div className="hd_blo">Result</div>
                      </div>
                      <div className="box">
                        <div className="hd_blo">Returns</div>
                      </div> */}
                  </div>

                  {items?.matched?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest}</div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">Option 2</div>
                          <div className="rslt_blo">₹999</div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.matched?.total_invested}
                      </div>
                    </div>

                    <div>
                      <div
                        onClick={() => exitPop(items)}
                        style={{
                          height: "27px",
                          width: "70px",
                          backgroundColor: "grey",
                          cursor: "pointer",
                          color: "white",
                          borderRadius: "4px",
                          textAlign: "center",
                          paddingTop: "3px",
                        }}
                      >
                        <p style={{ fontSize: 14 }}>Exit</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  const PortFolioLiveCards2 = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={betInfos} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "20px",
              margin: "auto",
            }}
            className="rslt__crdSimple"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  // height: "20px",
                  padding: 4,
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  // paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                <TradeTypography>Unmatched</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.unmatched?.question?.question_image == null ||
                items?.unmatched?.question?.question_image == undefined ||
                items?.unmatched?.question?.question_image == "" ? (
                  <img
                    src={jersey}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.unmatched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <TradeTypography>
                {items?.unmatched?.question?.question_text}{" "}
              </TradeTypography>
            </div>
            <>
              {items?.unmatched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Buy Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                  </div>

                  {items?.unmatched?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo" style={{ marginRight: 12 }}>
                          ₹{elems?.invest}{" "}
                        </div>
                        <div className="rslt_blo" style={{ marginRight: 14 }}>
                          ₹{elems?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        <TradeTypography>
                          Invested ₹{items?.unmatched?.total_invested}
                        </TradeTypography>
                      </div>
                    </div>

                    <div>
                      <div
                        onClick={() => cancelPop()}
                        style={{
                          padding: 4,
                          width: "70px",
                          backgroundColor: "grey",
                          cursor: "pointer",
                          color: "white",
                          borderRadius: "4px",
                          textAlign: "center",
                        }}
                      >
                        <TradeTypography>Cancel</TradeTypography>
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        Unmatched qty : {items?.total_unmatched}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexiting = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div
          // onClick={betInfos}
          style={{ marginBottom: "10px" }}
        >
          <div
            style={{
              width: "97%",
              borderRadius: "20x",
              margin: "auto",
            }}
            className="rslt__crdSimple"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#6a257d",
                  paddingTop: "1px",
                  backgroundColor: "#f3e6f8",
                }}
              >
                <TradeTypography>Existing</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.exiting?.question?.question_image == null ||
                items?.exiting?.question?.question_image == undefined ||
                items?.exiting?.question?.question_image == "" ? (
                  <img
                    src={jersey}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.exiting?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <TradeTypography>
                {items?.exiting?.question?.question_text}{" "}
              </TradeTypography>
            </div>
            <>
              {items?.exiting?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Selling Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                    {/* <div className="box">
                        <div className="hd_blo">Result</div>
                      </div>
                      <div className="box">
                        <div className="hd_blo">Returns</div>
                      </div> */}
                  </div>

                  {items?.exiting?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo" style={{ marginRight: 12 }}>
                          ₹{elems?.invest}{" "}
                        </div>
                        <div className="rslt_blo" style={{ marginRight: 14 }}>
                          ₹{elems?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">{items?.publish_result} </div>
                          <div className="rslt_blo">₹{items?.returns} </div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.exiting?.total_invested}
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() => cancelExitingPop()}
                        style={{
                          height: "27px",
                          width: "70px",
                          backgroundColor: "grey",
                          cursor: "pointer",
                          color: "white",
                          borderRadius: "4px",
                          textAlign: "center",
                          paddingTop: "3px",
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexited = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={betInfos} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crdSimple"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  paddingTop: "1px",
                  backgroundColor: "#ededed",
                }}
              >
                <TradeTypography>Exited</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.exited?.question?.question_image == null ||
                items?.exited?.question?.question_image == undefined ||
                items?.exited?.question?.question_image == "" ? (
                  <img
                    src={jersey}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.exited?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.exited?.question?.question_text} </p>
            </div>
            <>
              {items?.exited?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Selling Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                  </div>

                  {items?.exited?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo" style={{ marginRight: 12 }}>
                          ₹{elems?.invest}{" "}
                        </div>
                        <div className="rslt_blo" style={{ marginRight: 14 }}>
                          ₹{elems?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">{items?.publish_result} </div>
                          <div className="rslt_blo">₹{items?.returns} </div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        <TradeTypography>
                          Invested ₹{items?.exited?.total_invested}
                        </TradeTypography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsCancel = ({ items }) => {
    return (
      <div style={{ marginTop: 0, borderRadius: "20px" }}>
        <div onClick={betInfos} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "20px",
              margin: "auto",
            }}
            className="rslt__crdSimple"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  paddingTop: "1px",
                  backgroundColor: "#ededed",
                }}
              >
                <TradeTypography>Cancelled</TradeTypography>
              </div>
            </div>
            <div className="top__dt">
              <div>
                {items?.canceled?.question?.question_image == null ||
                items?.canceled?.question?.question_image == undefined ||
                items?.canceled?.question?.question_image == "" ? (
                  <img
                    src={jersey}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    src={produc(items?.canceled?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <TradeTypography>
                {items?.canceled?.question?.question_text}{" "}
              </TradeTypography>
            </div>
            <>
              {items?.canceled?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Choose</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Invest</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Buy Price</TradeTypography>
                      </div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">
                        <TradeTypography>Quantity</TradeTypography>
                      </div>
                    </div>
                  </div>

                  {items?.canceled?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo" style={{ marginRight: 12 }}>
                          ₹{elems?.invest}{" "}
                        </div>
                        <div className="rslt_blo" style={{ marginRight: 14 }}>
                          ₹{elems?.buy_price}{" "}
                        </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        <TradeTypography>
                          Invested ₹{items?.canceled?.total_invested}
                        </TradeTypography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {side ? (
        <>
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>
      ) : (
        <></>
      )}

      <div
        style={{ minHeight: "100vh", background: "white" }}
        className="mobile__layout main"
      >
        {/* {exit?   <div onClick={()=> setExit(false)} style={{position:"absolute",background:"grey",height:"100vh",width:"420px",zIndex:"9"}}>

</div>:null} */}
        <div
          className="container auth__container"
          style={{ background: "white" }}
        >
          {/* <div style={{ position: "sticky", top: "0", zIndex: "10" }}>
            {id !== "" && id !== undefined && id !== null ? (
              <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />
            ) : (
              <TopNavWOL />
            )}
            <div
              style={{
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)'
              }}
            >
              {canceled && (
                <CancelPopup items={portfolioDetails} close={setCanceled} />
              )}


            </div>
            <div
              style={{
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)'
              }}
            >

            </div>
            <div
              style={{
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)'
              }}
            >

            </div> 
          </div> */}

          <div
            style={{ justifyContent: "space-between", height: "56px" }}
            className="reg-back fixed_up"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ background: "#00000000" }} className="reg__back">
                <Link to="/">
                  <HiArrowLeft color="white" size={25} />
                </Link>
              </div>
              <p
                style={{ fontSize: "18px", color: "white", paddingTop: "4px" }}
              >
                My Portfolio
              </p>
            </div>
          </div>

          {toQuest ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                background: "#cfcfcf",
                marginBottom: 10,
                height: "50px",

                position: "sticky",
                top: 56,
                zIndex: 4,
              }}

              // className="upper_tabs"
            >
              <div
                onClick={() => {
                  setToQuest(false);
                  setClick(false);
                }}
                style={{
                  marginLeft: 10,
                  backgroundColor: "grey",
                  width: "7%",
                  height: 30,
                  borderRadius: 100,
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 10,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginTop: 7, color: "white" }}
                  icon={faArrowLeft}
                />{" "}
              </div>
              <Typography>Event Details</Typography>
            </div>
          ) : (
            <div
              style={{
                height: "50px",
                zIndex: "0",
                position: "sticky",
                top: "5%",
                borderBottom: "1px solid lightgrey",
              }}
              className="upper_tabs"
            >
              <Link>
                <div className="live_eve">Live Events</div>
              </Link>
              <Link to="/portfolioCancel">
                <div className="close_eve">Closed Events</div>
              </Link>
            </div>
          )}

          {/* {click === true ? (
            <div style={{ backgroundColor: "lightgray", marginBottom: "10px" }}>
              <PortfCompos
                returns={totalBalanceResponse?.user?.balance_winning}
                invest={totalBalanceResponse?.investment}
              />
            </div>
          ) : (
            <></>
          )} */}

          {click && !toQuest ? (
            <>
              <div
                onClick={() => setClick(false)}
                style={{
                  marginLeft: 10,
                  backgroundColor: "grey",
                  width: "7%",
                  height: 30,
                  borderRadius: 100,
                  marginTop: 5,
                  marginBottom: 5,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginTop: 7, color: "white" }}
                  icon={faArrowLeft}
                />
              </div>{" "}
            </>
          ) : (
            <></>
          )}

          <div>
            <div style={{}}>
              {toQuest ? (
                <>
                  {portfolioDetails?.matched?.bets?.length > 0 ? (
                    <div>
                      <EventDetstatusandborder
                        exitPop={exitPop}
                        betInfos={betInfos}
                        items={portfolioDetails}
                      />
                      {/* <EventDetWithwatermarkstatusInvest exitPop={exitPop} betInfos={betInfos} items={portfolioDetails} />
<EventExitcancelsimpleclosed exitPop={exitPop} betInfos={betInfos} items={portfolioDetails} /> */}
                    </div>
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.unmatched?.bets?.length > 0 ? (
                    <PortFolioLiveCards2 items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.exiting?.bets?.length > 0 ? (
                    <PortFolioLiveCardsexiting items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.exited?.bets?.length > 0 ? (
                    <PortFolioLiveCardsexited items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                  {portfolioDetails?.canceled?.bets?.length > 0 ? (
                    <PortFolioLiveCardsCancel items={portfolioDetails} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <div style={{ marginTop: -10 }}>
                  {Array.isArray(portFolios) &&
                    portFolios?.map((items, ind) => {
                      if (
                        items?.match_id == mID &&
                        items?.type == selectedType
                      ) {
                        return (
                          // <div
                          //   onClick={() => betInfos(items?.question_id)}
                          //   key={ind}
                          //   style={{ padding: 10, marginBottom: -20 }}
                          // >
                          //   <Link to="/portfolio">
                          //     <div className="question_box_">
                          //       <div className="trade_rule">
                          //         <div className="trade_icon">
                          //           <i className="fa-brands fa-sellsy"></i>
                          //         </div>
                          //         <hr />
                          //         <p>{items?.total_trade} Trades</p>
                          //       </div>
                          //       <div className="imgque">
                          //         <div className="ques">
                          //           <div className="quest_tex">
                          //             {items?.question_text}
                          //           </div>
                          //           <div
                          //             style={{
                          //               display: "flex",
                          //               alignItems: "center",
                          //               columnGap: 5,
                          //               marginTop: 10,
                          //             }}
                          //             className="descpt"
                          //           >
                          //             <img
                          //               style={{ height: "25px" }}
                          //               src={logo4}
                          //               alt=""
                          //             />
                          //             <p className="shrt_desc">
                          //               {items?.question_short_desc}
                          //             </p>
                          //           </div>
                          //         </div>

                          //         <div className="quest_img">
                          //           {items?.question_image == null ||
                          //             items?.question_image == undefined ||
                          //             items?.question_image == "" ? (
                          //             <img
                          //               style={{
                          //                 borderRadius: 10,
                          //                 backgroundColor: "grey",
                          //               }}
                          //               src={jersey}
                          //               alt=""
                          //             />
                          //           ) : (
                          //             <img
                          //               style={{
                          //                 borderRadius: 10,
                          //                 backgroundColor: "lightgrey",
                          //               }}
                          //               src={produc(items?.question_image)}
                          //               alt=""
                          //             />
                          //           )}
                          //         </div>
                          //       </div>
                          //     </div>
                          //   </Link>c
                          // </div>

                          <div
                            key={ind}
                            onClick={() => betInfos(items?.question_id)}
                            style={{
                              width: "95%",
                              margin: "auto",
                              padding: "10px",
                              background: "white",
                              borderRadius: "15px",
                              border: "1px solid black",
                              marginTop: "20px",
                            }}
                          >
                            <div style={{ width: "95%", margin: "auto" }}>
                              <div
                                style={{
                                  padding: "2px 4px",
                                  background: "#5c95e1",
                                  width: "13%",
                                  fontSize: "13px",
                                  marginTop: "6px",
                                  fontWeight: "600",
                                }}
                              >
                                Event
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "95%",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{ marginTop: "15px" }}
                                  className="quest_img"
                                >
                                  {items?.question_image == null ||
                                  items?.question_image == undefined ||
                                  items?.question_image == "" ? (
                                    <img
                                      style={{
                                        borderRadius: 10,
                                        backgroundColor: "grey",
                                      }}
                                      src={jersey}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        borderRadius: 10,
                                        backgroundColor: "lightgrey",
                                      }}
                                      src={produc(items?.question_image)}
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "80%",
                                    color: "grey",
                                    fontSize: "13.5px",
                                  }}
                                >
                                  {items?.question_text}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "95%",
                                margin: "auto",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  background: "red",
                                  padding: "3px 7px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  color: "white",
                                  borderRadius: "50px",
                                  fontSize: "12px",
                                }}
                              >
                                <FaCircleChevronRight size={18} color="white" />
                                JOIN NOW
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={ind}
                            onClick={() => betInfos(items?.question_id)}
                            style={{
                              width: "95%",
                              margin: "auto",
                              padding: "10px",
                              background: "white",
                              borderRadius: "15px",
                              border: "1px solid #dddddd",
                              // border: "1.5px solid black",
                              marginTop: "20px",
                              boxShadow:
                                "0.5px 0.5px 2px 0px rgb(167, 167, 167)",
                            }}
                          >
                            <div style={{}}>
                              <div style={{ width: "95%", margin: "auto" }}>
                                {/* <div
                                  style={{
                                    padding: "2px 4px",
                                    background: "#5c95e1",
                                    width: "13%",
                                    fontSize: "13px",
                                    marginTop: "6px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Event
                                </div> */}

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "95%",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{ marginTop: "15px" }}
                                    className="quest_img"
                                  >
                                    {items?.question_image == null ||
                                    items?.question_image == undefined ||
                                    items?.question_image == "" ? (
                                      <img
                                        style={{
                                          borderRadius: 10,
                                          backgroundColor: "grey",
                                        }}
                                        src={jersey}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          borderRadius: 10,
                                          backgroundColor: "lightgrey",
                                        }}
                                        src={produc(items?.question_image)}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      width: "80%",
                                      color: "grey",
                                      fontSize: "13.5px",
                                    }}
                                  >
                                    {items?.question_text}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  margin: "auto",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  opacity: 0.9,
                                }}
                              >
                                <div
                                  style={{
                                    background: "red",
                                    padding: "3px 7px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "4px",
                                    color: "white",
                                    borderRadius: "50px",
                                    fontSize: "12px",
                                  }}
                                >
                                  <FaCircleChevronRight
                                    size={18}
                                    color="white"
                                  />
                                  <Typography
                                    style={{ fontSize: 12, fontWeight: "bold" }}
                                  >
                                    View Event
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
        {(canceled || exit || canceleExit) && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              height: "40vh",
              width: "100%",
              // height: "calc(100vh-200px)",
              // backgroundColor: "#0000007d",
              backgroundColor: "transparent", // Black transparent background
              zIndex: 9998, // Ensures it stays below the popup
              display: "flex",
              // minHeight: "100vh",
              justifyContent: "center",
              alignItems: "center",
              // position: "absolute",
              // top: 0,
              // left: "34.5%",
              // zIndex: 9999,
              // overflowY: "hidden",
              // width: "100%",
              // maxWidth: "420px",
              // // height: "100%",
              // minHeight: "100vh",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              e.preventDefault(); // Block interaction with background
              setCanceled(false); // Close the modal
              setExit(false);
              setCancelExit(false);
            }}
          />
        )}
        <div style={{ position: "sticky", bottom: "0" }}>
          {exit && <Exitpop items={portfolioDetails} close={setExit} />}
          {canceleExit && (
            <CancelExitPopup items={portfolioDetails} close={setCancelExit} />
          )}
          {canceled ? (
            <CancelPopup close={setCanceled} items={portfolioDetails} />
          ) : null}
          {exit || canceleExit || canceled ? null : <Footer2 />}
        </div>
      </div>
    </div>
  );
}
