import React from "react";
import jersey from "../Assets/Images/jersey.png";
import { TradeTypography } from "./TradeTypography";
import { Typography } from "@mui/material";

export default function PortfCompos({ invest, returns }) {
  return (
    <div>
      <div
        style={{
          width: "97%",
          borderRadius: "5px",
          margin: "auto",
          marginTop: "10px",
        }}
        className="top_trade_port"
      >
        <div className="trde">
          <div className="trde_icon__">
            <i className="fa-solid fa-building-columns"></i>
          </div>
          <p>Trade Portfolio</p>
        </div>
        <div className="full__context" style={{ alignItems: "center" }}>
          <div
            style={{
              borderWidth: 1,
              borderColor: "black",
              alignItems: "center",
            }}
            className="left_context"
          >
            <Typography
              style={{ fontWeight: "bold", color: "black", fontSize: 16 }}
            >
              ₹ {invest == null || invest == "" ? " 0.00" : Number(invest)}
            </Typography>
            <p style={{ textAlign: "center" }}>Investment</p>
          </div>
          <div
            style={{ borderWidth: 1, borderColor: "black" }}
            className="right_context"
          >
            <Typography
              style={{ fontWeight: "bold", color: "black", fontSize: 16 }}
            >
              ₹ {returns == null || returns == "" ? " 0.00" : Number(returns)}
            </Typography>
            <p style={{ textAlign: "center" }}>Returns</p>
          </div>
        </div>
      </div>
    </div>
  );
}
