import React, { useEffect } from "react";
import wallet from "../Assets/Images/wallet.png";
import { Link } from "react-router-dom";
import { withDrawHistory } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Footer3 from "../Components/Footer3";

export default function WithDrawHistry() {
  const dispatch = useDispatch();
  const uid = localStorage.getItem("user_id");

  const formData = new FormData();
  formData.append("user_id", uid);

  useEffect(() => {
    dispatch(withDrawHistory(formData));
  }, []);

  const { withHistry } = useSelector((state) => state.Post);

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container fixed">
          <div className="reg-back" style={{ height: "56px" }}>
            <div className="reg__back">
              <Link to="/wallet">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Withdraw history</p>
          </div>

          <div className="mid_content Transaction_history add_money">
            <div className="main_transi Withdraw">
              <div className="catego">
                <div>
                  <h4 style={{ color: "grey", textAlign: "center" }}>
                    {withHistry?.length == 0 ? "No withdraws yet" : ""}
                  </h4>

                  {Array.isArray(withHistry) &&
                    withHistry?.map((items) => {
                      return (
                        <>
                          <div
                            div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              minHeight: "60px",
                            }}
                          >
                            <div className="cat_icon">
                              <div className="boxs">
                                <img src={wallet} alt="" />
                              </div>
                              <div className="content">
                                <div className="contnt_text_">
                                  <h4>{items?.action} </h4>
                                  <p>
                                    {moment(items?.created_at).format(
                                      "MMM Do YY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="amt">
                              <p>
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                <span>{items?.requested_amount} </span>
                              </p>
                              <h6
                                style={{
                                  color:
                                    items?.status == "Completed"
                                      ? "green"
                                      : items?.status == "Pending"
                                      ? "orange"
                                      : "red",
                                  textAlign: "right",
                                }}
                              >
                                {items?.status}{" "}
                              </h6>
                            </div>
                          </div>
                          <p
                            style={{
                              marginLeft: "33px",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {items?.reject_reason}
                          </p>
                          <hr style={{ border: "1px solid lightgrey" }} />
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Toastify"></div>
        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer3 />
        </div>
      </div>
    </div>
  );
}
