import React, { useEffect } from "react";
import tel from "../Assets/Images/telegram.png";
import twit from "../Assets/Images/twitter.png";
import insta from "../Assets/Images/instagram.png";
import fb from "../Assets/Images/facebook.png";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { userBalance } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../Redux/GetSlice";
import { FiChevronRight } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { HiArrowLeft } from "react-icons/hi2";
import {
  RiCustomerService2Line,
  RiMessage2Line,
  RiWallet3Line,
} from "react-icons/ri";
import { LuSettings, LuUserPlus2 } from "react-icons/lu";
import { GrGamepad } from "react-icons/gr";
import { IoInformationCircleOutline } from "react-icons/io5";
import { produc } from "../Redux/Helper";
import user from "../Assets/Images/userpng.jpg";

export default function SideNav({ closesidenavbar }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    toast.success("Logged out successfully");
    localStorage.clear();
    navigate("/register/0");
    window.location.reload();
  };

  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  useEffect(() => {
    dispatch(getContact());
  }, []);

  const { Balance } = useSelector((state) => state.Get);

  const { contactUs } = useSelector((state) => state?.Get);
  const { totalBalanceResponse } = useSelector((state) => state.Get);
  const balanceAmount =
    parseFloat(totalBalanceResponse?.user?.balance_deposit) +
    parseFloat(totalBalanceResponse?.user?.balance_winning);
  const redirectLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <div
        onClick={closesidenavbar}
        style={{ zIndex: "2" }}
        className="po_nave"
      >
        <div
          style={{ background: "#f5f5f5" }}
          className="container auth__container profile_popup"
        >
          <div
            style={{
              justifyContent: "space-between",
              padding: "10px",
              background: "black",
            }}
            className="reg-back fixed_up"
          >
            <div
              onClick={() => navigate("/settings")}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{ background: "#00000000", padding: "5px" }}
                className="reg__back"
              >
                <Link to="/">
                  {totalBalanceResponse?.user?.photo == undefined ||
                  totalBalanceResponse?.user?.photo == null ||
                  totalBalanceResponse?.user?.photo == "" ? (
                    <img
                      src={user}
                      style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "50px",
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "50px",
                      }}
                      src={produc(totalBalanceResponse?.user?.photo)}
                      alt=""
                    />
                  )}
                </Link>
              </div>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    color: "white",
                    paddingTop: "4px",
                  }}
                >
                  {totalBalanceResponse?.user?.firstname}{" "}
                  {totalBalanceResponse?.user?.lastname}
                </p>
                <p
                  style={{
                    fontSize: "11px",
                    color: "grey",
                    textTransform: "none",
                    paddingTop: "5px",
                  }}
                >
                  Skill Score: 606
                </p>
              </div>
            </div>

            <FiChevronRight />
          </div>

          <section style={{ marginBottom: "1px" }} className="blnce_sec">
            <div
              style={{ background: "red", color: "white", height: "70px" }}
              className="blnce_"
            >
              <div className="total_bln">
                <div
                  style={{ background: "white", border: "1px solid lightgrey" }}
                  className="icon_wallet"
                >
                  <RiWallet3Line size={20} color="black" />
                </div>
                <div className="blnce_amnt">
                  <p>Total Balance</p>
                  <p className="amnt_b">₹ {balanceAmount} </p>
                </div>
              </div>
              <Link to="/addmoney">
                <div
                  style={{
                    fontSize: "12px",
                    color: "black",
                    background: "white",
                  }}
                  className="add_csh_btn"
                >
                  Add Cash
                </div>
              </Link>
            </div>
          </section>

          <section className="categ">
            <div style={{ background: "#e9e9e9", padding: "10px" }}>
              <div
                style={{ borderRadius: "5px 5px 0px 0px" }}
                className="content_box_cat lw"
              >
                <Link to="/refer">
                  <div
                    style={{
                      display: "flex",
                      height: "60px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      columnGap: "7px",
                    }}
                    className="catego"
                  >
                    {/* <LuUserPlus2 style={{ marginLeft: "10px" }} size={20} /> */}

                    <div className="content">
                      <div className="contnt_text_">Refer &amp; Earn</div>
                    </div>
                  </div>
                </Link>

                <hr
                  style={{
                    width: "95%",
                    margin: "auto",
                    border: "1px solid lightgrey",
                  }}
                />
                <Link to="/settings">
                  <div
                    style={{
                      display: "flex",
                      height: "60px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                    className="catego"
                  >
                    <LuSettings style={{ marginLeft: "10px" }} size={20} />
                    <div className="content">
                      <div className="contnt_text_">My Info & Settings</div>
                    </div>
                  </div>
                </Link>
                <hr
                  style={{
                    width: "95%",
                    margin: "auto",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div
                style={{ borderRadius: "0px 0px 5px 5px" }}
                className="content_box_cat lw"
              >
                <Link to="/htp">
                  <div
                    style={{
                      display: "flex",
                      height: "60px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                    className="catego"
                  >
                    <GrGamepad style={{ marginLeft: "10px" }} size={20} />
                    <div className="content">
                      <div className="contnt_text_">How To Play</div>
                    </div>
                  </div>
                </Link>

                <hr
                  style={{
                    width: "95%",
                    margin: "auto",
                    border: "1px solid lightgrey",
                  }}
                />
                <Link to="/verifyAcc">
                  <div
                    style={{
                      display: "flex",
                      height: "60px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                    className="catego"
                  >
                    <IoInformationCircleOutline
                      style={{ marginLeft: "10px" }}
                      size={20}
                    />
                    <div className="content">
                      <div className="contnt_text_">Verify Account</div>
                    </div>
                  </div>
                </Link>

                <hr
                  style={{
                    width: "95%",
                    margin: "auto",
                    border: "1px solid lightgrey",
                  }}
                />
                <Link to="/helpNsupport">
                  <div
                    style={{
                      display: "flex",
                      height: "60px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                    className="catego"
                  >
                    <RiCustomerService2Line
                      style={{ marginLeft: "10px" }}
                      size={20}
                    />
                    <div className="content">
                      <div className="contnt_text_">24x7 Help & Support</div>
                    </div>
                  </div>
                </Link>

                <hr
                  style={{
                    width: "95%",
                    margin: "auto",
                    border: "1px solid lightgrey",
                  }}
                />
                <Link to="/aboutus">
                  <div
                    style={{
                      display: "flex",
                      height: "60px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                    className="catego"
                  >
                    <RiMessage2Line style={{ marginLeft: "10px" }} size={20} />
                    <div className="content">
                      <div className="contnt_text_">About Us</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            {/* <div className="content_box_cat lw">
                                <div style={{display:"flex",height:"50px",justifyContent:"flex-start",alignItems:"center",columnGap:"10px"}} className="catego">
                                        <div className="cat_icon"><i className="fa-solid fa-turn-up"></i></div>
                                        <div className="content">
                                            <div className="contnt_text_">
                                                Level Up Now
                                            </div>
            
                                        </div>
                                </div>
                            </div> */}

            <div
              onClick={logOut}
              style={{ cursor: "pointer", marginTop: "10px" }}
              className="content_box_cat log_out_"
            >
              <div
                style={{
                  display: "flex",
                  height: "50px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  columnGap: "10px",
                }}
                className="catego"
              >
                <div className="cat_icon">
                  <i className="fa-solid fa-right-from-bracket"></i>
                </div>
                <div className="content">
                  <div className="contnt_text_">Log out</div>
                </div>
              </div>
            </div>
          </section>

          <section style={{ marginTop: "50px" }} className="logo__btm">
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                redirectLink(
                  contactUs?.settings?.instagram_url ||
                    "https://www.instagram.com/opinion365_app?igsh=OXZ6cnBjcm5oOTYx"
                )
              }
              className="logo_img_"
            >
              <img src={insta} alt="" />
            </div>
            <div
              style={{ cursor: "pointer", borderRadius: "50px" }}
              onClick={() =>
                redirectLink(
                  contactUs?.settings?.facebook_url ||
                    "https://www.facebook.com/profile.php?id=100094106919428&mibextid=ZbWKwL"
                )
              }
              className="logo_img_"
            >
              <img src={fb} alt="" />
            </div>

            <div
              style={{ cursor: "pointer", borderRadius: "50px" }}
              onClick={() =>
                redirectLink(
                  contactUs?.settings?.telegram_url ||
                    "https://t.me/proboapp_prediction"
                )
              }
              className="logo_img_"
            >
              <img src={tel} alt="" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
