import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { liveMatch, market, upcoming } from "../../Redux/GetSlice";
import { useCallback } from "react";

const CountdownTimer = ({ targetDate }) => {
  const dispatch = useDispatch();

  // refs to hold the timer, timeLeft, and the flag for API calls
  const timerRef = useRef(null);
  const timeLeftRef = useRef({});
  const apiCalledRef = useRef(false);
  const [timerComponents, setTimerComponents] = useState([]);

  // Function to calculate the time left
  // const calculateTimeLeft = () => {
  //   const difference = new Date(targetDate) - new Date();

  //   if (difference <= 0 && !apiCalledRef.current) {
  //     // Make the API calls once when the time reaches zero
  //     apiCalledRef.current = true;
  //     dispatch(upcoming());
  //     dispatch(liveMatch());
  //     dispatch(market());
  //   }

  //   if (difference > 0) {
  //     timeLeftRef.current = {
  //       d: Math.floor(difference / (1000 * 60 * 60 * 24)),
  //       hr: Math.floor((difference / (1000 * 60 * 60)) % 24),
  //       mins: Math.floor((difference / 1000 / 60) % 60),
  //       secs: Math.floor((difference / 1000) % 60),
  //     };
  //   } else {
  //     timeLeftRef.current = {};
  //   }

  //   updateTimerComponents(timeLeftRef.current);
  // };
  const updateTimerComponents = useCallback(
    (timeLeft) => {
      let timeArray = [];

      if (timeLeft.d > 0) {
        timeArray.push(
          <span key="d">
            {`${timeLeft.d}${timeLeft.d === 1 ? "d :" : "d :"}`}{" "}
          </span>
        );
      }
      if (timeLeft.hr > 0 || timeLeft.d > 0) {
        timeArray.push(
          <span key="hr">
            {`${timeLeft.hr}${timeLeft.hr === 1 ? "h :" : "h :"}`}{" "}
          </span>
        );
      }
      if (timeLeft.mins > 0 || timeLeft.hr > 0 || timeLeft.d > 0) {
        timeArray.push(
          <span key="mins">
            {`${timeLeft.mins}${timeLeft.mins === 1 ? "m " : "m "}`}{" "}
          </span>
        );
      }
      setTimerComponents(timeArray);
    },
    [setTimerComponents]
  );
  const calculateTimeLeft = useCallback(() => {
    const difference = new Date(targetDate) - new Date();

    if (difference <= 0 && !apiCalledRef.current) {
      // Make the API calls once when the time reaches zero
      apiCalledRef.current = true;
      dispatch(upcoming());
      dispatch(liveMatch());
      dispatch(market());
    }

    if (difference > 0) {
      timeLeftRef.current = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hr: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
        secs: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeftRef.current = {};
    }

    updateTimerComponents(timeLeftRef.current);
  }, [targetDate, apiCalledRef, dispatch, updateTimerComponents]);

  // Function to update the timer components (UI)

  useEffect(() => {
    // Start the timer once when the component mounts
    timerRef.current = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    // Clean up the interval on unmount
    return () => {
      clearInterval(timerRef.current);
      timeLeftRef.current = null;
    };
  }, [calculateTimeLeft]);

  return (
    <div>
      {timerComponents.length > 0 ? timerComponents : <span>Loading...</span>}
    </div>
  );
};

export default CountdownTimer;
