import React, { useEffect, useReducer, useState } from "react";
import caution from "../Assets/Images/caution.png";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import discountImg from "../Assets/Images/discount5.png";
import { LuBookOpen } from "react-icons/lu";
import bookicon from "../Assets/Images/bookopen.png";
import "rc-slider/assets/index.css";
import "react-range-slider-input/dist/style.css";
import { Slider, RangeSlider } from "rsuite";
import slidelogo from "../Assets/Images/sliderlogo.png";
import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faBookBookmark,
  faCheck,
  faCross,
  faEdit,
  faMinus,
  faPencil,
  faPlus,
  faShare,
  faSquareShareNodes,
  faWallet,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import jersey from "../Assets/Images/jersey.png";
import walletIcon from "../Assets/Images/wallets.png";
import {
  betRes,
  getBetSlots,
  setloadingPost,
  setLowBalance,
} from "../Redux/PostSlice";

import { postBet } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import { getContact, matchQuest } from "../Redux/GetSlice";
import { produc } from "../Redux/Helper";
import PricesPop from "./PricesPop";
import PriceEnter from "./PriceEnter";
import { getSlots } from "../Redux/GetSlice";
import { getyesno } from "../Redux/GetSlice";
import { userBalance } from "../Redux/GetSlice";
import { maxMinBet } from "../Redux/GetSlice";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import toast from "react-hot-toast";
import SwipeButton from "../Components/SwipeButton";
import { HiArrowLeft } from "react-icons/hi2";
import { CiWallet } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaCircleQuestion, FaSquareCheck } from "react-icons/fa6";
import { BsThreeDots } from "react-icons/bs";
import { upcoming } from "../Redux/GetSlice";
import moment from "moment";
import CountdownTimer from "./Countdown/Countdown";

export default function BetPopup() {
  const [pop, setPop] = useState(false);
  const [enter, setEnter] = useState(false);
  const [acc, setAcc] = useState(false);
  const [recharge, setRecharge] = useState(false);
  const [longDesc, setLongDesc] = useState(false);
  const [sliderValue, setSliderValue] = useState(50);
  const [sliderValue2, setSliderValue2] = useState(50);

  const { betSlotsquantity } = useSelector((state) => state.Post);
  const { lowBalance } = useSelector((state) => state.Post);
  const { matchQuestions } = useSelector((state) => state.Get);
  const { contactUs } = useSelector((state) => state.Get);
  const { postBets } = useSelector((state) => state.Post);
  const { betSlots } = useSelector((state) => state.Get);
  const { yesNoCounts } = useSelector((state) => state.Get);
  const { maxMinBets } = useSelector((state) => state.Get);
  const { betPlaceRes } = useSelector((state) => state.Post);
  const { upcomingMatch } = useSelector((state) => state.Get);
  const { yesNoopt } = useSelector((state) => state.Get);
  const { totalBalanceResponse } = useSelector((state) => state.Get);

  const uid = localStorage.getItem("user_id");

  const totalBalance =
    parseFloat(totalBalanceResponse?.user?.balance_deposit) +
    parseFloat(totalBalanceResponse?.user?.balance_bonus) +
    parseFloat(totalBalanceResponse?.user?.balance_winning);

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  const accExpand = (option1_text, option2_text) => {
    setOption1(option1_text);
    setOption2(option2_text);
    if (acc) {
      setAcc(false);
    } else {
      setAcc(true);
    }
  };

  const nonenter = () => {
    setEnter(false);
  };
  const pricepop = () => {
    if (parseInt(inp2) !== 1) {
      setInp2(parseInt(inp2) - 1);
    }
  };

  const pricepop2 = () => {
    if (parseInt(inp2) < contactUs?.settings?.quantity_max_limit) {
      setInp2(parseInt(inp2) + 1);
    } else {
      toast.error("Maximum quantity exceeded", { duration: 5000 });
    }
  };
  const priceunpop = () => {
    setPop(false);
  };

  let maxOpt1;

  let load = 0;

  let timer = setTimeout(() => {
    load = load + 1;
  }, 1000);

  if (load > 1) {
    clearTimeout(timer);
  }

  const [inp1, setInp1] = useState(5);
  const [inp2, setInp2] = useState(250);
  const [count, setCount] = useState(0);
  const [optionCost, setOptionCost] = useState(yesNoopt);

  useEffect(() => {
    if (maxOpt1 == null) {
      setInp1(5);
    } else {
      if (optionCost == 1) {
        setInp1(parseFloat(maxOpt1));
      } else {
        setInp1(10 - parseFloat(maxOpt1));
      }
    }
  }, [matchQuestions, maxOpt1]);

  useEffect(() => {
    dispatch(getContact());
  }, []);

  useEffect(() => {
    dispatch(upcoming());
  }, []);

  useEffect(() => {
    dispatch(matchQuest(params.id));
  }, []);

  const AccItems = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            columnGap: "10px",
            height: "50px",
            justifyContent: "center",
            width: "100%",
            margin: "auto",
            background: "white",
            borderLeft: "2px solid #ededed",
            borderRight: "2px solid #ededed",
            borderBottomLeftRadius: yesNoCounts.length == 0 ? "13px" : "0px",
            borderBottomRightRadius: yesNoCounts.length == 0 ? "13px" : "0px",
            borderBottom:
              yesNoCounts.length == 0 ? "2px solid #ededed" : "0px solid white",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "45px",
                width: "150px",
              }}
            >
              <div style={{ fontSize: "12px", fontWeight: "800" }}> Price</div>
              <div style={{ fontSize: "12px" }}>
                Qty at <span style={{ color: "blue" }}>{option1}</span>{" "}
              </div>
            </div>
            <hr style={{ marginTop: "5px" }} />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "45px",
                width: "150px",
              }}
            >
              <div style={{ fontSize: "12px", fontWeight: "800" }}> Price</div>
              <div style={{ fontSize: "12px" }}>
                Qty at <span style={{ color: "red" }}>{option2}</span>{" "}
              </div>
            </div>
            <hr style={{ marginTop: "5px" }} />
          </div>
        </div>
      </>
    );
  };

  const InsuffBalance = () => {
    return (
      <>
        <div className="left_bl_">
          <div className="caution_icon">
            <img src={caution} alt="" />
          </div>
          <div className="suffc_content">
            <p className="insu">Insufficient balance</p>
            <p className="lern">Learn more</p>
          </div>
        </div>
        <div></div>
        <Link
          to="/addmoney"
          style={{
            backgroundColor: "#353535",
            color: "white",
            height: "30px",
            width: "120px",
            borderRadius: "5px",
            padding: "4px 0 0 20px",
          }}
        >
          <div className="right_bl_">Recharge</div>
        </Link>
      </>
    );
  };

  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    const formData = new FormData();
    formData.append("selected_option", optionCost);
    formData.append("question_id", params.qid);
    formData.append("selected_amount", inp1);
    dispatch(getBetSlots(formData));
    dispatch(setLowBalance(false));
  }, [inp1, optionCost]);

  useEffect(() => {
    dispatch(maxMinBet(params.qid));
  }, []);

  useEffect(() => {
    if (betPlaceRes === true) {
      setTimeout(() => {
        dispatch(betRes(false));
        navigate(`/matchdetquestion/${params.id}`);
      }, 1000);
    }
  }, [betPlaceRes]);

  // console.log(yesNoopt, "yesno++++++++++++++++++++++++++++++++++++");

  // console.log("YesnoCounts", yesNoCounts);

  const navigate = useNavigate();
  const [counts, setCounts] = useState(false);
  const [opt, setOpt] = useState(yesNoopt);
  const [option1, setOption1] = useState(null);
  const [option2, setOption2] = useState(null);

  let timed = setTimeout(() => {
    setCounts(counts + 1);
  }, 500);

  if (counts > 2) {
    clearTimeout(timed);
  }

  const tstArr = [
    {
      bet_amount: 12,
      option1: 1,
      option2: 3.0,
    },
    {
      bet_amount: 12,
      option1: 1,
      option2: 3.0,
    },
    {
      bet_amount: 12,
      option1: 1,
      option2: 3.0,
    },
  ];

  const { Balance } = useSelector((state) => state.Get);
  const balanceAmount =
    parseFloat(totalBalanceResponse?.user?.balance_deposit) +
    parseFloat(totalBalanceResponse?.user?.balance_winning);
  useEffect(() => {
    if (yesNoopt == 1) {
      setToggle(true);
    } else {
      setToggle(false);
    }
    setOpt(yesNoopt);
  }, []);

  let time = setTimeout(() => {
    setCount(count + 1);
  }, 1000);

  if (count > 10) {
    clearTimeout(time);
  }

  const placeBet = async (isSwiped) => {
    if (isSwiped) {
      if (inp2 === null || inp2 === "") {
        setInp2(250);
      }
      const formData = new FormData();
      formData.append("user_id", uid);
      formData.append("price", inp1);
      formData.append("question_id", params.qid);
      formData.append("quantity", inp2 === "" ? 250 : inp2);
      formData.append("selected_option", opt);

      await dispatch(postBet(formData));

      dispatch(userBalance(uid));
    }
  };

  const [side, setSide] = useState(false);
  const openCloseSideNav = () => {
    if (side === true) {
      setSide(false);
    } else {
      setSide(true);
      // console.log("Open");
    }
  };

  const closeSideNav = () => {
    setSide(false);
  };

  useEffect(() => {
    dispatch(getyesno(params.qid));
  }, [params.qid]);

  useEffect(() => {
    dispatch(matchQuest(params.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(userBalance(uid));
  }, [Balance]);

  const chngevalminus = () => {
    if (parseFloat(inp1) !== 0.5) {
      setInp1(parseFloat(inp1) - 0.5);
    }
  };
  const chngevalplus = () => {
    if (parseFloat(inp1) !== 9.5) {
      setInp1(parseFloat(inp1) + 0.5);
    }
  };

  const [toggle, setToggle] = useState(yesNoopt == 1 ? true : false);

  // const dragResponse = () => {
  //   // console.log("dragging");
  // };

  const yesT = () => {
    setToggle(true);
    setOpt(1);
    setOptionCost(2);
  };
  const noT = () => {
    setToggle(false);
    setOpt(2);
    setOptionCost(1);
  };

  const setQty = (data) => {
    if (data <= contactUs?.settings?.quantity_max_limit) {
      setInp2(data);
    } else {
      toast.error("Maximum quantity exceeded", { duration: 5000 });
    }
  };

  return (
    <div>
      {side ? (
        <div
          style={{
            zIndex: "9",
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            display: "block",
            overflow: "hidden",
          }}
        >
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </div>
      ) : (
        <></>
      )}
      <div
        style={{ minHeight: "100vh", background: "white" }}
        className="mobile__layout main"
      >
        <div
          style={{ backgroundColor: "white" }}
          className="container auth__container"
        >
          <div
            style={{ justifyContent: "space-between", height: "56px" }}
            className="reg-back fixed_up"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ background: "#00000000" }} className="reg__back">
                <Link to="/">
                  <HiArrowLeft color="white" size={25} />
                </Link>
              </div>
              <div className="reg__back matches_select">
                <div className="dropdown">
                  {Array.isArray(upcomingMatch) &&
                    upcomingMatch?.map((items) => {
                      if (items?.match_id === params?.id) {
                        return (
                          <button
                            style={{
                              height: "30px",
                              // width: "85px",
                              fontSize: "13px",
                            }}
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {items?.team_1_abbr} v {items?.team_2_abbr}
                            <br />
                            <p
                              style={{
                                fontSize: "13px",
                                textTransform: "lowercase",
                                color: "#bbbbbb",
                                paddingTop: "4px",
                              }}
                            >
                              <CountdownTimer targetDate={items?.match_date} />
                            </p>
                          </button>
                        );
                      }
                    })}
                </div>
              </div>
            </div>

            <div
              onClick={() => navigate("/addmoney")}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "6px",
                gap: "8px",
                borderRadius: "10px",
                background: "#202b3d",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              <CiWallet size={19} color="white" />₹{balanceAmount}
              <div
                style={{
                  background: "green",
                  padding: "1px",
                  height: "20px",
                  borderRadius: "50px",
                }}
              >
                <IoMdAdd style={{ margin: "auto" }} size={18} color="#cdcdcd" />
              </div>
            </div>
          </div>

          <div
            style={{
              boxShadow: "0px -6px 10px -5px",
              borderRadius: "0px",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderBottom: "2px solid #f5f5f5",
              zIndex: "0",
            }}
            className="bottom_sheet bottom_to_top"
          >
            {/* <Link
              to={`/matchdetquestion/${params.id}`}
              style={{ marginLeft: "80px", marginTop: "20px" }}
            >
              <div
                style={{
                  marginLeft: "17px",
                  width: "30px",
                  height: "30px",
                  backgroundColor: "lightgrey",
                  paddingLeft: "9px",
                  paddingTop: "6px",
                  borderRadius: "50px",
                }}
              >
                <FontAwesomeIcon color="" icon={faArrowLeft} />
              </div>
            </Link> */}

            <div style={{ height: "1vh", background: "white" }}></div>

            {/* <div className="slider_contents"> */}
            <div
              style={{
                padding: "15px",
                width: "95%",
                borderRadius: "5px",
                background: "#f7f8fa",
                margin: "auto",
                marginTop: "10px",
              }}
              className="top_dtl_content"
            >
              {matchQuestions?.map((items, index) => {
                if (items?.question_id == params?.qid) {
                  return (
                    <div style={{ width: "80%", alignSelf: "baseline" }}>
                      <h5
                        style={{ fontWeight: "600", fontSize: "13px" }}
                        key={index}
                      >
                        {items?.question_text}{" "}
                      </h5>
                    </div>
                  );
                }
              })}
              <div className="detail_placeholder_img">
                {matchQuestions?.map((items, index) => {
                  if (items?.question_id == params?.qid) {
                    return (
                      <div
                        style={{ width: "60px", height: "60px" }}
                        key={index}
                      >
                        {items?.question_image == null ||
                        items?.question_image == undefined ||
                        items?.question_image == "" ? (
                          <img src={jersey} alt="" />
                        ) : (
                          <img src={produc(items?.question_image)} alt="" />
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>

            <div
              style={{
                background: "#f7f8fa",
                width: "100%",
                height: "60px",
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  height: "42px",
                  cursor: "pointer",
                  width: "93%",
                  margin: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  borderRadius: "50px",
                  border: "2px solid #ededed",
                  justifyContent: "space-between",
                  background: "white",
                }}
              >
                <div style={{ width: "50%", height: "100%" }} onClick={yesT}>
                  {toggle === true ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50px",
                        height: "100%",
                        backgroundColor: "#197bff",

                        color: "white",
                      }}
                    >
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;
                            return (
                              <div style={{ fontWeight: "700" }} key={index}>
                                {items?.option1_text} ₹
                                {optionCost == 1 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        // width: "165px",
                        height: "40px",
                      }}
                    >
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;
                            return (
                              <div style={{ fontWeight: "700" }} key={index}>
                                {items?.option1_text} ₹
                                {optionCost == 1 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}
                    </div>
                  )}
                </div>

                <div style={{ width: "50%", height: "100%" }} onClick={noT}>
                  {toggle === true ? (
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        padding: "12px 0 0 0",
                      }}
                    >
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;
                            return (
                              <div style={{ fontWeight: "700" }} key={index}>
                                {items?.option2_text} ₹
                                {optionCost == 2 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "40px",
                        marginRight: "1px",
                        borderRadius: "50px",
                        backgroundColor: "#ff5953",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;
                            return (
                              <div style={{ fontWeight: "700" }} key={index}>
                                {items?.option2_text} ₹
                                {optionCost == 2 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              style={{
                background: "#f7f8fa",
                marginTop: "10px",
                height: "10px",
              }}
            ></div>

            <div style={{ background: "#f7f8fa", height: "auto" }}>
              <div
                onClick={() => {
                  setPop(false);
                  setEnter(false);
                }}
                className="custom_set"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div className="set_price">Set price</div>
                  <div
                    style={{
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      gap: "0px",
                      width: "70%",
                      marginLeft: 8,
                      justifyContent: "flex-end",
                      background: "white",
                    }}
                  >
                    <img
                      style={{ width: "38%", marginTop: "18px" }}
                      src={discountImg}
                      alt=""
                    />

                    <div
                      style={{
                        height: "70%",
                        width: "65%",
                        borderRadius: "8px",
                        background: "#f3ce3f",
                        border: "2px solid #e6ca66",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontWeight: "bolder",
                        marginRight: "5px",
                      }}
                    >
                      <RiDiscountPercentFill size={23} color="red" />
                      <p
                        style={{
                          fontSize: "7px",
                          padding: "4px",
                          fontWeight: "700",
                        }}
                      >
                        Maximum usable cash bonus per trade = 5% of entry
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="inputFeilds"
                style={{
                  width: "95%",
                  margin: "auto",
                  background: "white",
                  border: "2px solid #ededed",
                  padding: "10px",
                  marginTop: "15px",
                  borderRadius: "15px",
                }}
              >
                <div
                  onClick={() => {
                    setPop(false);
                    setEnter(false);
                  }}
                  className="slider_price"
                >
                  <div className="upper_actions_info">
                    <label for="customRange1" className="form-label">
                      Price
                    </label>
                    <div className="right_infor">
                      <div style={{ fontSize: "18px" }} className="price_info">
                        ₹{inp1}{" "}
                      </div>
                      <div className="quant_info">
                        Available Contests: {betSlotsquantity}
                      </div>
                    </div>
                  </div>
                  <div className="slider_actions">
                    <div onClick={chngevalminus} className="minus_icon">
                      <i>
                        <FontAwesomeIcon color="#2d80eb" icon={faMinus} />
                      </i>
                    </div>

                    {/* <div className="chrome">
               
                      <input
                        className="myinput"
                        min={0.5}
                        max={9.5}
                        step={0.5}
                        value={inp1}
                        onChange={(event) => {
                          if (
                            parseInt(event.target.value) >= 0.5 &&
                            parseInt(event.target.value) <= 9.5
                          )
                            setInp1(parseInt(event.target.value));
                        }}
                        type="range"
                      />
                    </div> */}

                    <div className="slider-container">
                      <input
                        type="range"
                        min={0.5}
                        max={9.5}
                        value={inp1}
                        step={0.5}
                        onChange={(event) => {
                          if (
                            parseInt(event.target.value) >= 0.5 &&
                            parseInt(event.target.value) <= 9.5
                          )
                            setInp1(parseInt(event.target.value));
                        }}
                        className="slider"
                        style={{
                          background: `linear-gradient(to right, black ${
                            inp1 * 10
                          }%, #ccc ${inp1 * 10}%)`,
                        }}
                      />
                    </div>

                    <div onClick={chngevalplus} className="plus_icon">
                      <i>
                        <FontAwesomeIcon color="#2d80eb" icon={faPlus} />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="slider_price">
                  <div
                    style={{ marginTop: "40px" }}
                    className="upper_actions_info"
                  >
                    <label
                      style={{ display: "flex", alignItems: "center" }}
                      for="customRange1"
                      className="form-label"
                    >
                      Quantity
                    </label>

                    <div
                      style={{
                        border: "1px solid #9b9b9b",
                        borderBottom: "1px solid #d6d6d6",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          columnGap: "10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: "#f7f8fa",
                          padding: "5px 9px",
                          gap: "25px",
                          borderRadius: "4px",
                          border: "1px solid #eaebec",
                        }}
                        className="right_infor"
                      >
                        <div
                          style={{ marginLeft: "10px" }}
                          className="price_info"
                        >
                          {enter ? (
                            <input
                              style={{
                                border: "1px solid white",
                                width: "60%",
                              }}
                              value={inp2}
                              onChange={(event) => {
                                if (
                                  (parseInt(event.target.value) >= 1 &&
                                    parseInt(event.target.value) <=
                                      contactUs?.settings
                                        ?.quantity_max_limit) ||
                                  event.target.value === ""
                                )
                                  setInp2(event.target.value);
                              }}
                            />
                          ) : (
                            inp2
                          )}{" "}
                        </div>
                        {enter ? (
                          <FaSquareCheck
                            onClick={() => setEnter(false)}
                            size={25}
                          />
                        ) : (
                          <FontAwesomeIcon
                            style={{ height: "22px" }}
                            onClick={() => setEnter(true)}
                            icon={faEdit}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="slider_actions">
                    <div onClick={pricepop} className="minus_icon">
                      <i>
                        {/* <FontAwesomeIcon color="#2d80eb" icon={faMinus} /> */}
                        <BsThreeDots
                          style={{ paddingTop: "2px" }}
                          color="#2d80eb"
                        />
                      </i>
                    </div>
                    <div className="slider-container">
                      <input
                        type="range"
                        min={1}
                        max={contactUs?.settings?.quantity_max_limit}
                        step={1}
                        value={inp2}
                        onChange={(event) => {
                          if (
                            parseInt(event.target.value) >= 1 &&
                            parseInt(event.target.value) <=
                              contactUs?.settings?.quantity_max_limit
                          )
                            setInp2(parseInt(event.target.value));
                        }}
                        className="slider"
                        style={{
                          background: `linear-gradient(to right, black ${
                            inp2 /
                            (contactUs?.settings?.quantity_max_limit / 100)
                          }%, #ccc ${
                            inp2 /
                            (contactUs?.settings?.quantity_max_limit / 100)
                          }%)`,
                        }}
                      />
                    </div>
                    {/* <div className="chrome">
                      <input
                        className="myinput"
                        min={1}
                        max={contactUs?.settings?.quantity_max_limit}
                        step={1}
                        aria-labelledby="range-slider"
                        type="range"
                        onChange={(event) => {
                          if (
                            parseInt(event.target.value) >= 1 &&
                            parseInt(event.target.value) <=
                            contactUs?.settings?.quantity_max_limit
                          )
                            setInp2(parseInt(event.target.value));
                        }}
                        value={inp2}
                      />
                    </div> */}
                    <div onClick={pricepop2} className="plus_icon">
                      <i>
                        <FontAwesomeIcon color="#2d80eb" icon={faPlus} />
                      </i>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "95%",
                    height: "10px",
                    borderTop: "2px dotted lightgrey",
                    margin: "auto",
                    marginTop: "50px",
                  }}
                ></div>

                <div
                  onClick={() => {
                    setPop(false);
                    setEnter(false);
                  }}
                  className="btm_cont"
                >
                  <div className="you_put">
                    <p className="put_am">₹{inp1 * inp2} </p>
                    <p className="yu-put">You put</p>
                  </div>
                  <div className="you_get">
                    <p className="get_am">₹{inp2 * 10} </p>
                    <p className="yu-get">You get</p>
                  </div>
                </div>
              </div>

              <div style={{ marginLeft: "25px" }}>
                {pop && <PricesPop setInp={setInp2} close={priceunpop} />}
                {/* {enter && (
                  <PriceEnter
                    close={nonenter}
                    setInpp={(data) => setQty(data)}
                    limit={contactUs?.settings?.quantity_max_limit}
                  />
                )} */}
              </div>

              <div
                style={{
                  width: "95%",
                  margin: "auto",
                  borderRadius: "15px",
                  marginTop: "15px",
                }}
              >
                {Array.isArray(matchQuestions) &&
                  matchQuestions?.map((items, index) => {
                    if (items?.question_id == params?.qid) {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            accExpand(items?.option1_text, items?.option2_text)
                          }
                          style={{
                            height: "55px",
                            alignItems: "center",
                            columnGap: "200px",
                            justifyContent: "space-between",
                            display: "flex",
                            width: "100%",
                            backgroundColor: "white",
                            margin: "auto",
                            borderRadius: "13px",
                            borderBottomLeftRadius: acc ? "0px" : "13px",
                            borderBottomRightRadius: acc ? "0px" : "13px",
                            border: "2px solid #ededed",

                            borderBottom:
                              acc == true
                                ? "2px solid white"
                                : "2px solid #ededed",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "12px",
                              marginLeft: "20px",
                            }}
                          >
                            {/* <LuBookOpen color="#9dc3ee" size={20} /> */}
                            <img
                              src={bookicon}
                              style={{ height: "18%", width: "17%" }}
                              alt=""
                            />
                            <h3 style={{ fontSize: "14px" }}>Order Book</h3>
                          </div>

                          <FontAwesomeIcon
                            style={{ marginRight: "20px", height: "20px" }}
                            icon={acc === true ? faAngleUp : faAngleDown}
                          />
                        </div>
                      );
                    }
                  })}
                <div style={{ margin: "auto", marginLeft: "0px" }}>
                  {acc && <AccItems />}
                </div>

                {acc && (
                  <div>
                    {yesNoCounts?.map((items, index) => {
                      return (
                        <div key={index}>
                          <div
                            style={{
                              height: "30px",
                              marginTop: "0px",
                              display: "flex",
                              justifyContent: "center",
                              columnGap: "70px",
                              background: "white",
                              borderLeft: "2px solid #ededed",
                              borderRight: "2px solid #ededed",
                              borderBottomLeftRadius:
                                tstArr.length - 1 == index ? "13px" : "0px",
                              borderBottomRightRadius:
                                tstArr.length - 1 == index ? "13px" : "0px",
                              borderBottom:
                                tstArr.length - 1 == index
                                  ? "2px solid #ededed"
                                  : "0px solid white",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                height: "30px",
                                width: "120px",
                                justifyContent: "center",
                                columnGap: "75px",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ marginLeft: "10px" }}>
                                {items?.bet_amount}{" "}
                              </p>
                              <p>{items?.option1} </p>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                height: "30px",
                                width: "120px",
                                justifyContent: "flex-start",
                                columnGap: "65px",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ marginLeft: "-15px" }}>
                                {items?.bet_amount}{" "}
                              </p>
                              <p>{items?.option2} </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{ height: "20px", background: "#f5f5f5" }}></div>

          <div style={{ background: "#e1e1e1", padding: "10px" }}>
            <div
              onClick={() => setLongDesc(!longDesc)}
              style={{
                height: "50px",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
                backgroundColor: "white",
                margin: "auto",
                borderRadius: "5px",
                borderBottomLeftRadius: longDesc ? "0px" : "5px",
                borderBottomRightRadius: longDesc ? "0px" : "5px",

                // border: "2px solid #ededed",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "5px",
                  marginLeft: "10px",
                }}
              >
                <FaCircleQuestion size={18} />
                <h3 style={{ fontSize: "13px", fontWeight: "800" }}>
                  Long description
                </h3>
              </div>

              <FontAwesomeIcon
                style={{ marginRight: "10px", height: "15px" }}
                icon={longDesc ? faAngleUp : faAngleRight}
              />
            </div>
            {longDesc ? (
              <div
                style={{
                  background: "white",
                  width: "100%",
                  padding: "5px",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
              >
                {matchQuestions?.map((items, index) => {
                  if (items?.question_id == params?.qid) {
                    return (
                      <div
                        style={{
                          padding: "16px",
                          borderRadius: "8px",
                          backgroundColor: "white",

                          marginBottom: "20px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: "8px",
                            color: "#333",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          Overview:{" "}
                          <p style={{ fontWeight: "normal", paddingTop: 8 }}>
                            {items?.question_long_desc}
                          </p>
                        </p>

                        <div
                          style={{
                            fontSize: "14px",
                            color: "#666",
                            lineHeight: "1.6",
                          }}
                        >
                          <p
                            style={{
                              margin: "0 0 8px",
                              fontWeight: "bold",
                              color: "#333",
                            }}
                          >
                            About the event:
                          </p>
                          <p style={{ margin: "0 0 4px" }}>
                            <strong>ID:</strong> {items?.question_id}
                          </p>
                          <p style={{ margin: "0 0 4px" }}>
                            <strong>Started At:</strong>{" "}
                            {moment(items?.createdAt).format(
                              "Do MMM YY, hh:mm a"
                            )}
                          </p>
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#666",
                            lineHeight: "1.6",
                          }}
                        >
                          <p
                            style={{
                              margin: "0 0 8px",
                              fontWeight: "bold",
                              color: "#333",
                            }}
                          >
                            Rules:{" "}
                          </p>
                          <p style={{ margin: "0 0 4px" }}>{items?.rules}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : null}
          </div>

          <div style={{ height: "15px", background: "#f5f5f5" }}></div>
          <div
            style={{
              backgroundColor: "white",
              position: "sticky",
              bottom: "0",
            }}
            className="btm_lw"
          >
            <div className="swipe_actions">
              {lowBalance ? (
                <div className="insuffc">
                  <InsuffBalance />
                </div>
              ) : null}
            </div>
            <div style={{}}>
              {/* <Footer /> */}
              <main>
                {betPlaceRes === true ? (
                  <div
                    style={{
                      width: "90%",
                      height: "40px",
                      margin: "auto",
                      borderRadius: "50px",
                      textAlign: "center",
                      fontSize: "20px",
                      paddingTop: "6px",
                      backgroundColor: "green",
                      color: "white",
                    }}
                  >
                    Bet Placed <FontAwesomeIcon icon={faCheck} />
                  </div>
                ) : (
                  <>
                    {lowBalance ? null : (
                      <SwipeButton
                        onSwipeEnd={placeBet}
                        refresh={lowBalance}
                        backgroundColor={toggle ? "#197bff" : "#ff5953"}
                      />
                    )}
                  </>
                  // <div
                  //   onClick={placeBet}
                  //   style={{
                  //     width: "90%",
                  //     height: "40px",
                  //     margin: "auto",
                  //     borderRadius: "50px",
                  //     textAlign: "center",
                  //     fontSize: "20px",
                  //     paddingTop: "6px",
                  //     backgroundColor: "black",
                  //     color: "white",
                  //     cursor: "pointer",
                  //   }}
                  // >
                  //   Place Bet
                  // </div>
                )}
              </main>
              <div
                className="available_bl"
                style={{ backgroundColor: "white" }}
              >
                {`Available Balance ₹${balanceAmount?.toFixed(2)}`}
                {/* 15% commission will be charged on net winnings. */}
                {/* Available Balance: ₹
              {parseInt(totalBalanceResponse?.user?.balance_deposit) +
                parseInt(totalBalanceResponse?.user?.balance_winning)}{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
