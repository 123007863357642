import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import React, { useState } from 'react'

export default function PriceEnter({close,setInpp,limit}) {



const [value,setValue] = useState("");
const [error,setError] = useState("");

const valid = (num)=>{
    if(parseInt(num) > parseInt(limit)){
setError(`Please enter value under ${limit}`)
    }
    else{
        setError("")
    }
}

const Change =(e)=>{
    const val = parseInt(e.target.value);
    if(parseInt(val) > 0){
      setValue(parseInt(val) )
      valid(val);
    }
    else{
      setValue("")
      valid("");
    }
}





  return (
    <div>

      <div className='modal-container' style={{height:"150px",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"10px",margin:"auto",borderRadius:"15px",width:"350px",backgroundColor:"white",columnGap:"20px"}}>
      
       <div style={{height:"40px",width:"100%",marginTop:"-5px",margin:"auto",marginLeft:"0px",display:"flex"}}>
        <div style={{width:"85%",marginTop:"10px"}}>
        <TextField fullWidth value={value} onChange={Change} minLength={3} placeholder='Enter your quantity' style={{height:"40px",border:"0px solid lightgrey",borderRadius:"7px",fontSize:"16px",paddingLeft:"15px"}} type="text" />
        </div>
        
        <div onClick={close} style={{height:"30px",cursor:"pointer",width:"30px",alignSelf:"center",borderRadius:"50px",backgroundColor:"lightgrey",marginTop:"20px",marginLeft:"10px"}}>
        <FontAwesomeIcon style={{marginLeft:"8.5px",marginTop:"7px"}} icon={faMinus}/>
          
        </div>
       </div>
       <div style={{fontSize:"12px",color:"red",marginLeft:"20px"}}> 
        {error}
       </div>
     <div onClick={()=> {close(); setInpp(value)}} style={{cursor:"pointer",height:"35px",width:"94%",borderRadius:"5px",margin:"auto",marginTop:"0px",backgroundColor:"#303030",color:"white",textAlign:"center",paddingTop:"5px"}}>
      Select
     </div>
        </div>
    </div>
  )
}