import React, { useEffect, useState } from "react";
import { matchQuest, no, userBalance, yes } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import jersey from "../Assets/Images/jerseypng.png";
import logo4 from "../Assets/Images/fav1.png";
import { betRes, postBet } from "../Redux/PostSlice";
import Footer from "../Components/Footer";
import { upcoming } from "../Redux/GetSlice";
import moment from "moment";
import { getyesno } from "../Redux/GetSlice";
import discountImg from "../Assets/Images/discount5.png";
import id from "../Assets/Images/id4.png";
import stock from "../Assets/Images/stockup.png";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { io } from "socket.io-client";

import {
  faAngleDown,
  faMinus,
  faX,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { getQuestByMarket } from "../Redux/GetSlice";
import { BASE_APP_URL } from "../config";
import { HiArrowLeft } from "react-icons/hi2";
import { CiWallet } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaInfo } from "react-icons/fa6";
import InfoPopup from "../Components/InfoPopup";
const socket = io.connect(RT_BASE_URL);

export default function Markets() {
  const { matchQuestions } = useSelector((state) => state.Get);
  const { marketQuestions } = useSelector((state) => state.Get);
  const { Balance, totalBalanceResponse } = useSelector((state) => state.Get);
  const balanceAmount =
    parseFloat(totalBalanceResponse?.user?.balance_deposit) +
    parseFloat(totalBalanceResponse?.user?.balance_winning);
  const params = useParams();
  // console.log("idddddddddddddd", params.id);

  const [queId, setQuiId] = useState("");
  const [info, setInfo] = useState(false);
  const [popinfo, setPopinfo] = useState({});
  const [copy, setCopy] = useState(false);
  const [realQuest, setRealQuest] = useState([{}]);

  if (copy) {
    toast.success("Copied to clipboard");
    setCopy(false);
  }

  const uid = localStorage.getItem("user_id");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const infoPop = (items, ind) => {
    setInfo(true);
    setPopinfo(items);
  };

  const setYes = (mId, qId, opt) => {
    dispatch(yes());
    navigate(`/placebetmarkets/${mId}/${qId}`);
  };

  const setNo = (mId, qId, opt) => {
    dispatch(no());

    navigate(`/placebetmarkets/${mId}/${qId}`);
  };

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  useEffect(() => {
    setRealQuest(marketQuestions);
  }, [marketQuestions]);

  useEffect(() => {
    socket.on("newQuestionPublished", (data) => {
      // console.log(data, "question data ----------");
      let socketData = JSON.parse(data);
      if (
        socketData?.match?.match_type == "Market" &&
        socketData?.match?.market_id == params.id
      ) {
        realQuest.push(socketData);
        dispatch(getQuestByMarket(params.id));
      }
    });
  }, []);

  useEffect(() => {
    socket.on("questionDeleted", (data) => {
      // console.log(data, "question data ----------");
      let socketData = JSON.parse(data);
      if (
        socketData?.match?.match_type == "Market" &&
        socketData?.match?.market_id == params.id
      ) {
        dispatch(getQuestByMarket(params.id));
      }
    });
  }, []);

  // console.log(realQuest,"realinfo---------------");

  // let link = "https://luxury-brigadeiros-5b57ce.netlify.app/";
  const link = (qid) => {
    let newLink = BASE_APP_URL + `placebetmarkets/${params.id}` + `/${qid}`;
    return newLink;
  };

  useEffect(() => {
    dispatch(getQuestByMarket(params?.id));
  }, []);

  useEffect(() => {
    dispatch(matchQuest(params.id));
  }, []);

  let time = setTimeout(() => {
    setQuiId(queId + 1);
  }, 1000);

  if (queId > 2) {
    clearTimeout(time);
  }

  useEffect(() => {
    dispatch(betRes(false));
  }, [dispatch]);
  useEffect(() => {
    info && (document.body.style.overflow = "hidden");
    !info && (document.body.style.overflow = "unset");
  }, [info]);

  const sot = (url) => {
    window.open(url, "_blank");
  };

  // const InfoPopup = ({ items, close }) => {
  //   const closeddd = () => {
  //     close(false);
  //   };
  //   return (
  //     <>
  //       <div
  //         style={{
  //           maxWidth: "420px",
  //           margin: "auto",
  //           boxShadow: "1px 1px 100px 10px",
  //         }}
  //         className="style_container__IcEZT"
  //       >
  //         <div style={{ display: "flex" }}>
  //           <h3>About the event</h3>
  //           <FontAwesomeIcon
  //             color="grey"
  //             onClick={closeddd}
  //             style={{
  //               marginLeft: "210px",
  //               height: "25px",
  //               cursor: "pointer",
  //               marginTop: "0px",
  //             }}
  //             icon={faXmarkCircle}
  //           />
  //         </div>
  //         <table className="table">
  //           <tbody>
  //             <tr>
  //               <td>
  //                 <p>Id</p>
  //                 <h4>{items?.question_id} </h4>
  //               </td>
  //               <td>
  //                 <p style={{ textAlign: "end" }}>Share</p>
  //                 <div
  //                   style={{
  //                     border: "0px solid black",
  //                     justifyContent: "flex-end",
  //                   }}
  //                   className="copy-text info"
  //                 >
  //                   <CopyToClipboard
  //                     text={link(items?.question_id)}
  //                     onCopy={() => setCopy(true)}
  //                   >
  //                     <button
  //                       style={{
  //                         border: "1px solid #007bff",
  //                         backgroundColor: "#007bff",
  //                         height: "28px",
  //                         width: "28px",
  //                         borderRadius: "6px",
  //                         cursor: "pointer",
  //                       }}
  //                     >
  //                       <i
  //                         style={{ color: "white" }}
  //                         className="fa fa-clone"
  //                       ></i>
  //                     </button>
  //                   </CopyToClipboard>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr>
  //               <td>
  //                 <p>Started at</p>
  //                 <h4>
  //                   {moment(items?.created_at).format("Do MMM YY, hh : mm a")}
  //                 </h4>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>

  //         <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
  //           <Accordion
  //             preExpanded={["a", "b"]}
  //             style={{ backgroundColor: "white", cursor: "pointer" }}
  //             allowZeroExpanded
  //           >
  //             <AccordionItem uuid="a">
  //               <AccordionItemHeading
  //                 style={{ height: "60px", padding: "17px 0 0 10px" }}
  //               >
  //                 <AccordionItemButton
  //                   style={{ display: "flex", justifyContent: "space-between" }}
  //                 >
  //                   <h4>
  //                     Overview
  //                     <p style={{ fontWeight: "300", paddingTop: "5px" }}>
  //                       Information about event
  //                     </p>
  //                   </h4>

  //                   <FontAwesomeIcon
  //                     style={{ marginRight: "15px" }}
  //                     icon={faAngleDown}
  //                   />
  //                 </AccordionItemButton>
  //               </AccordionItemHeading>

  //               <AccordionItemPanel
  //                 style={{ height: "130px", backgroundColor: "#f3f3f3" }}
  //               >
  //                 <p
  //                   style={{
  //                     fontSize: "14px",
  //                     padding: "10px 10px 0 10px",
  //                     borderTop: "1px solid lightgrey",
  //                   }}
  //                 >
  //                   {items?.question_long_desc}
  //                 </p>
  //               </AccordionItemPanel>
  //             </AccordionItem>
  //           </Accordion>
  //           <hr style={{ border: "0.5px solid lightgrey" }} />
  //         </div>
  //         <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
  //           <Accordion
  //             style={{ backgroundColor: "white", cursor: "pointer" }}
  //             allowZeroExpanded
  //           >
  //             <AccordionItem>
  //               <AccordionItemHeading
  //                 style={{ height: "60px", padding: "17px 0 0 10px" }}
  //               >
  //                 <AccordionItemButton
  //                   style={{ display: "flex", justifyContent: "space-between" }}
  //                 >
  //                   <h4>
  //                     Source of truth
  //                     <p style={{ fontWeight: "300", paddingTop: "5px" }}>
  //                       Information about source of truth
  //                     </p>
  //                   </h4>

  //                   <FontAwesomeIcon
  //                     style={{ marginRight: "15px" }}
  //                     icon={faAngleDown}
  //                   />
  //                 </AccordionItemButton>
  //               </AccordionItemHeading>

  //               <AccordionItemPanel
  //                 style={{ height: "130px", backgroundColor: "#f3f3f3" }}
  //               >
  //                 <p
  //                   style={{
  //                     fontSize: "14px",
  //                     padding: "10px 10px 0 10px",
  //                     borderTop: "1px solid lightgrey",
  //                   }}
  //                 >
  //                   To know more,{" "}
  //                   <span
  //                     style={{ color: "blue" }}
  //                     onClick={() => sot(items?.source_of_truth)}
  //                   >
  //                     click here
  //                   </span>
  //                 </p>
  //               </AccordionItemPanel>
  //             </AccordionItem>
  //           </Accordion>
  //           <hr style={{ border: "0.5px solid lightgrey" }} />
  //         </div>
  //         <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
  //           <Accordion
  //             style={{ backgroundColor: "white", cursor: "pointer" }}
  //             allowZeroExpanded
  //           >
  //             <AccordionItem>
  //               <AccordionItemHeading
  //                 style={{ height: "60px", padding: "17px 0 0 10px" }}
  //               >
  //                 <AccordionItemButton
  //                   style={{ display: "flex", justifyContent: "space-between" }}
  //                 >
  //                   <h4>
  //                     Rules
  //                     <p style={{ fontWeight: "300", paddingTop: "5px" }}>
  //                       Terms and conditions
  //                     </p>
  //                   </h4>

  //                   <FontAwesomeIcon
  //                     style={{ marginRight: "15px" }}
  //                     icon={faAngleDown}
  //                   />
  //                 </AccordionItemButton>
  //               </AccordionItemHeading>

  //               <AccordionItemPanel
  //                 style={{ height: "130px", backgroundColor: "#f3f3f3" }}
  //               >
  //                 <p
  //                   style={{
  //                     fontSize: "14px",
  //                     padding: "10px 10px 0 10px",
  //                     borderTop: "1px solid lightgrey",
  //                   }}
  //                 >
  //                   {items?.rules}
  //                 </p>
  //               </AccordionItemPanel>
  //             </AccordionItem>
  //           </Accordion>
  //           <hr style={{ border: "0.5px solid lightgrey" }} />
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  return (
    <div>
      <div>
        <div
          style={{ minHeight: "100vh", background: "white" }}
          className="mobile__layout main"
        >
          <div
            style={{ background: "white" }}
            className="container auth__container"
          >
            <div
              style={{
                justifyContent: "space-between",
                position: "sticky",
                top: "0",
                height: "56px",
              }}
              className="reg-back fixed_up"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ background: "#00000000" }} className="reg__back">
                  <Link to="/">
                    <HiArrowLeft color="white" size={25} />
                  </Link>
                </div>
                {/* <div className="reg__back matches_select">
                <div className="dropdown">
                  {Array.isArray(liveMatches) &&
                    liveMatches?.map((items) => {
                      if (items?.match_id === params?.id) {
                        return (
                          <button
                            style={{ height: "30px", width: "85px", fontSize: "13px" }}
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {items?.team_1_abbr} v {items?.team_2_abbr}
                            <br />
                            <p style={{ fontSize: "13px", textTransform: "lowercase", color: "#bbbbbb", paddingTop: "4px" }}>
                              51m 34s left
                            </p>

                          </button>
                        );
                      }
                    })}
                </div>

              </div> */}
                Markets
              </div>

              <div
                onClick={() => navigate("/addmoney")}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  padding: "6px",
                  gap: "8px",
                  borderRadius: "10px",
                  background: "#202b3d",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                <CiWallet size={19} color="white" />₹{balanceAmount}
                <div
                  style={{
                    background: "green",
                    padding: "1px",
                    height: "20px",
                    borderRadius: "50px",
                  }}
                >
                  <IoMdAdd
                    style={{ margin: "auto" }}
                    size={18}
                    color="#cdcdcd"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                width: "98%",
                margin: "auto",
                height: "30px",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                marginBottom: "8px",
                gap: "1px",
              }}
            >
              <img
                style={{ width: "30%", marginTop: "20px" }}
                src={discountImg}
                alt=""
              />

              <div
                style={{
                  height: "82%",
                  width: "50%",
                  borderRadius: "10px",
                  background: "#f3ce3f",
                  border: "2px solid #e6ca66",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontWeight: "bolder",
                }}
              >
                <RiDiscountPercentFill size={23} color="red" />
                <p
                  style={{ fontSize: "7px", padding: "4px", fontWeight: "700" }}
                >
                  Maximum usable cash bonus per trade = 5% of entry
                </p>
              </div>
            </div>

            <section style={{ width: "100%" }} className="ques">
              {realQuest?.length == 0 ? (
                <>
                  <h3 style={{ textAlign: "center", color: "grey" }}>
                    No questions for this match!
                  </h3>
                </>
              ) : (
                <></>
              )}
              {Array.isArray(realQuest) &&
                realQuest?.map((items, ind) => {
                  // console.log("items running",items);
                  return (
                    <div
                      style={{ borderWidth: 1, borderColor: "black" }}
                      className="question_box_"
                    >
                      <div
                        onClick={() =>
                          navigate(
                            `/placebetmarkets/${items?.match_id}/${items?.question_id}`
                          )
                        }
                        className="trade_rule"
                      >
                        <div className="trade_icon">
                          <img
                            style={{ height: "12px", width: "12px" }}
                            src={stock}
                            alt=""
                          />
                        </div>

                        <p style={{ fontSize: "11px" }}>
                          {items?.answer_count} traders {">"}{" "}
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          navigate(
                            `/placebetmarkets/${items?.match_id}/${items?.question_id}`
                          )
                        }
                        className="imgque"
                      >
                        <div className="ques" style={{ width: "100%" }}>
                          <h5 style={{ fontWeight: "600", width: "100%" }}>
                            {items?.question_text}
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 5,
                              marginTop: 10,
                            }}
                            className="descpt"
                          >
                            <img
                              style={{
                                height: "20px",
                                width: "20px",
                                borderRadius: "50px",
                              }}
                              src={logo4}
                              alt=""
                            />
                            <p
                              style={{ fontSize: "11px" }}
                              className="shrt_desc"
                            >
                              {items?.question_short_desc}{" "}
                            </p>
                          </div>
                        </div>

                        <div
                          style={{ marginRight: "5px" }}
                          className="quest_img"
                        >
                          <img
                            style={{ borderRadius: "10px" }}
                            src={
                              items?.question_image == "" || null || undefined
                                ? logo4
                                : produc(items?.question_image)
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="yes_no_action">
                        <div
                          style={
                            {
                              // border: "1px solid #a6eaff",
                              // padding: "1px",
                            }
                          }
                        >
                          <button
                            onClick={() =>
                              setYes(
                                items?.match_id,
                                items?.question_id,
                                items?.option1_text
                              )
                            }
                            value={items?.option1_text}
                            style={{
                              fontWeight: "800",
                              width: "155px",
                              height: "35px",
                              fontSize: "12px",
                              letterSpacing: "0px",
                            }}
                            className="yes_btn"
                          >
                            {items.option1_text?.split(" ")?.length > 1
                              ? items?.option1_text?.split(" ")[0] + "..."
                              : items?.option1_text}{" "}
                            {items?.min_option1_amount == null
                              ? "₹ 5"
                              : "₹ " +
                                parseFloat(items?.min_option1_amount)}{" "}
                          </button>
                        </div>

                        <div
                          style={
                            {
                              // border: "1px solid #ffd0d0",
                              // padding: "1px",
                            }
                          }
                        >
                          <button
                            onClick={() =>
                              setNo(
                                items?.match_id,
                                items?.question_id,
                                items?.option2_text
                              )
                            }
                            value={items?.option2_text}
                            style={{
                              fontWeight: "800",
                              width: "155px",
                              height: "35px",
                              fontSize: "12px",
                              letterSpacing: "0px",
                            }}
                            className="no_btn"
                          >
                            {items.option2_text?.split(" ")?.length > 1
                              ? items?.option2_text?.split(" ")[0] + "..."
                              : items?.option2_text}{" "}
                            {items?.min_option1_amount == null
                              ? "₹ 5"
                              : "₹ " +
                                (10 - parseFloat(items?.min_option1_amount))}
                          </button>
                        </div>

                        <button
                          type="button"
                          className="btn btn-secondary tool"
                          data-toggle="tooltip"
                          data-placement="top"
                          style={{ borderRadius: 4, borderWidth: 1 }}
                        >
                          <div
                            style={{
                              // width: "50px",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => infoPop(items, ind)}
                            className="qu_ic"
                          >
                            <FaInfo size={22} />
                          </div>
                        </button>
                      </div>
                    </div>
                  );
                })}
            </section>

            <section className="tbl">
              {/* <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <div className="lft">
                                        <div className="icn"><i className="fa-solid fa-book-bookmark"></i></div>
                                        <div className="ord_text">Order Book</div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="tw_tble">
                                        <table className="table w-50 mb-0">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Price</th>
                                                    <td className="qunt">Qty at <span className="tbl_y">Yes</span></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0.5</td>
                                                    <td className="rft">33</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td className="rft">44</td>
                                                </tr>
                                                <tr>
                                                    <td>1.5</td>
                                                    <td className="rft">65</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td className="rft">78</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <table className="table w-50 mb-0">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Price</th>
                                                    <td className="qunt" scope="col">Qty at <span className="tbl_n">No</span>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0.5</td>
                                                    <td className="rft">45</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td className="rft">77</td>
                                                </tr>
                                                <tr>
                                                    <td>1.5</td>
                                                    <td className="rft">89</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td className="rft">556</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </section>

            {/* <div className="btm_lw">
                    <div className="swipe_actions">

                        <div className="insuffc">
                            <div className="left_bl_">
                                <div className="caution_icon">
                                    <img src={caution} alt=""/>
                                </div>
                                <div className="suffc_content">
                                    <p className="insu">Insufficient balance</p>
                                    <p className="lern">Learn more</p>
                                </div>
                            </div>
                            <div className="right_bl_">
                                <a className="rch_btn" href="">Recharge</a>
                            </div>
                        </div>
                        <main>
                            <div className="slide-button__container">
                                <span className="slide-button__text">Swipe for Yes</span>
                                <div className="slide-button__completed-overlay">
                                    <span className="slide-button__text">Proceeding...</span>
                                </div>
                                <div className="slide-button__control" draggable="true" ondrag="myFunction()">
                                    <i className="fa-solid fa-angles-right"></i>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className="available_bl">
                        Available Balance: ₹0.99
                    </div>
                </div> */}
          </div>
        </div>
        {info && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "40vh",
              backgroundColor: "transparent", // Semi-transparent background
              zIndex: 999, // Ensure it's on top
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              e.preventDefault(); // Block interaction with background
              setInfo((prev) => !prev); // Close the modal
            }}
          />
        )}
        <div style={{ position: "sticky", bottom: "0" }}>
          <div>
            {info && (
              <InfoPopup items={popinfo} close={setInfo} params={params} />
            )}
          </div>
          {info == true ? <></> : <Footer />}
        </div>

        <div className="Toastify"></div>
      </div>
    </div>
  );
}
