import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import tel from "../Assets/Images/telegram.png";
import twit from "../Assets/Images/twitter.png";
import insta from "../Assets/Images/instagram.png";
import fb from "../Assets/Images/facebook.png";
import { useNavigate } from "react-router-dom";
import TopNav from "./TopNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faGear,
  faHandshakeAngle,
  faQuestion,
  faRightFromBracket,
  faTurnUp,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Components/Footer";
import Footer3 from "../Components/Footer3";
import SideNav from "./SideNav";

export default function Profile() {
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("user_id");
    navigate("/register");
  };

  const [side, setSide] = useState(false);
  const openSideNav = () => {
    setSide(true);
    //   console.log("Open");
  };
  const closeSideNav = () => {
    setSide(false);
  };
    useEffect(() => {
      window.scrollTo({ top: 0 });
      return ()=>{
        window.scrollTo({top:0});
        }
    }, []);

  return (
    <div>
      {side ? (
        <>
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>
      ) : (
        <></>
      )}

      <div className="mobile__layout main">
        <div className="container auth__container">
          <div style={{ position: "sticky", top: "0", height: "56px" }}>
            <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />
          </div>

          {/* <!-- <div className="profile_main_content">
                <div className="up_profile">
                    <div className="prfl_logo">
                        <img src="images/fav1.png" alt="">
                    </div>
                    <div className="mid_content_profile">
                        <p>Eddy Guy 01</p>
                        <div className="level_">
                            <div className="lvl_dot"></div>
                            <div className="levl_text">Level 76</div>
                        </div>
                    </div>
                    <div className="right__jersey">
                        <img src="images/jerseypng.png" alt="">
                    </div>
                </div>
            </div> --> */}

          <div className="reg-back">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>My Profile</p>
          </div>

          <section className="blnce_sec">
            <div className="blnce_">
              <div className="total_bln">
                <div className="icon_wallet">
                  <i className="fa-solid fa-wallet"></i>
                </div>
                <div className="blnce_amnt">
                  <p>Total Balance</p>
                  <p className="amnt_b">₹ 1300 </p>
                </div>
              </div>
              <Link to="/addmoney">
                <div className="add_csh_btn">Add Cash</div>
              </Link>
            </div>
          </section>

          <section className="categ">
            <div className="content_box_cat">
              <Link to="/refer">
                <div
                  style={{
                    display: "flex",
                    columnGap: "30px",
                    marginBottom: "10px",
                    marginBottom: "10px",
                  }}
                  className="catego"
                >
                  <div style={{ height: "28px" }} className="cat_icon">
                    <i className="fa-solid fa-hand-holding-dollar"></i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">Refer & Earn</div>
                    <div className="new_indic">New</div>
                  </div>
                </div>
              </Link>

              <hr />
              <div
                style={{
                  display: "flex",
                  columnGap: "30px",
                  marginTop: "10px",
                }}
                className="catego"
              >
                <div style={{ height: "28px" }} className="cat_icon">
                  <i>
                    <FontAwesomeIcon icon={faCertificate} />
                  </i>
                </div>
                <div className="content">
                  <div className="contnt_text_">Verify Account</div>
                </div>
              </div>
            </div>
            <div className="content_box_cat">
              <div
                style={{
                  display: "flex",
                  columnGap: "30px",
                  marginBottom: "3px",
                  marginTop: "3px",
                }}
                className="catego"
              >
                <div style={{ height: "28px" }} className="cat_icon">
                  <i>
                    <FontAwesomeIcon icon={faTurnUp} />
                  </i>
                </div>
                <div className="content">
                  <div className="contnt_text_">Level Up Now</div>
                </div>
              </div>
            </div>

            <div className="content_box_cat">
              <div
                style={{
                  height: "20px",
                  display: "flex",
                  columnGap: "30px",
                  marginBottom: "15px",
                  marginTop: "5px",
                  alignItems: "center",
                }}
                className="catego"
              >
                <div style={{ height: "28px" }} className="cat_icon">
                  <i>
                    <FontAwesomeIcon icon={faHandshakeAngle} />
                  </i>
                </div>
                <div className="content">
                  <div className="contnt_text_">Help & Support</div>
                </div>
              </div>
              <hr />
              <div
                style={{
                  height: "20px",
                  display: "flex",
                  columnGap: "30px",
                  marginBottom: "15px",
                  marginTop: "15px",
                  alignItems: "center",
                }}
                className="catego"
              >
                <div style={{ height: "28px" }} className="cat_icon">
                  <i>
                    <FontAwesomeIcon icon={faQuestion} />
                  </i>
                </div>
                <div className="content">
                  <div className="contnt_text_">How to Play</div>
                </div>
              </div>
              <hr />

              <Link to="/settings">
                <div
                  style={{
                    height: "17px",
                    display: "flex",
                    columnGap: "30px",
                    marginBottom: "10px",
                    marginTop: "15px",
                    alignItems: "center",
                  }}
                  className="catego"
                >
                  <div style={{ height: "28px" }} className="cat_icon">
                    <i>
                      <FontAwesomeIcon icon={faGear} />
                    </i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">Settings</div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              style={{ marginBottom: "-12px" }}
              className="content_box_cat log_out_"
            >
              <div
                onClick={logOut}
                style={{
                  display: "flex",
                  columnGap: "30px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                className="catego"
              >
                <div style={{ height: "28px" }} className="cat_icon">
                  <i>
                    <FontAwesomeIcon icon={faRightFromBracket} />
                  </i>
                </div>
                <button
                  style={{ background: "#ffb6b6", border: "1px solid #ffb6b6" }}
                >
                  <div className="content">
                    <div className="contnt_text_">Log out</div>
                  </div>
                </button>
              </div>
            </div>
          </section>
          <br />

          <section
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              columnGap: "20px",
              marginBottom: "15px",
            }}
            className="logo__btm"
          >
            <div className="logo_img_">
              <img src={fb} style={{ height: "35px", width: "35px" }} alt="" />
            </div>
            <div className="logo_img_">
              <img
                style={{ height: "35px", width: "35px" }}
                src={twit}
                alt=""
              />
            </div>
            <div className="logo_img_">
              <img
                style={{ height: "35px", width: "35px" }}
                src={insta}
                alt=""
              />
            </div>
            <div className="logo_img_">
              <img style={{ height: "35px", width: "35px" }} src={tel} alt="" />
            </div>
          </section>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer3 />
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
