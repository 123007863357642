import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import tnc from "../Assets/Images/termsconditions.jpg";
import { getAboutUs } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { produc } from "../Redux/Helper";
import logo2 from "../Assets/Images/logo-top-black.png";
import banner from "../Assets/Images/hero_image.avif";
import ipl from "../Assets/Images/ipl.jfif";

export default function AboutUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAboutUs());
  }, [dispatch]);

  const { pages } = useSelector((state) => state.Get);

  return (
    <div>
      <div>
        <div className="mobile__layout main">
          <div className="container auth__container">
            <div
              style={{ position: "sticky", top: "0", height: "56px" }}
              className="reg-back fixed"
            >
              <div className="reg__back">
                <button onClick={() => navigate(-1)}>
                  <i className="fa-solid fa-arrow-left"></i>
                </button>
              </div>
              <p>About Us</p>
            </div>

            <div className="mid_content">
              {/* <div style={{backgroundColor:"black",height:"150px"}}>
                    <img style={{width:"380px",marginTop:"30px"}} src={logo2} alt=""/>
                </div> */}

              <div className="terms_img">
                {pages?.aboutUs?.banner_image == null ||
                pages?.aboutUs?.banner_image == undefined ||
                pages?.aboutUs?.banner_image == "" ? (
                  <img src={ipl} alt="" />
                ) : (
                  <img src={produc(pages?.aboutUs?.banner_image)} alt="" />
                )}
              </div>

              <div className="terms_content">
                <div className="summary_contents">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pages?.aboutUs?.html_content,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "sticky", bottom: "0" }}>
            <Footer />
          </div>
          <div className="Toastify"></div>
        </div>
      </div>
    </div>
  );
}
