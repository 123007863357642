import React, { useState, useEffect } from "react";

import mscard from "../Assets/Images/mastercard_icon.png";
import paypal from "../Assets/Images/paypal_icon.png";
import visa from "../Assets/Images/visa_icon.png";

import { Link, useNavigate, useParams } from "react-router-dom";
import { withDraw } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { getRequestStatus } from "../Redux/GetSlice";
import toast from "react-hot-toast";

export default function WithdrawReq() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestStatus } = useSelector((state) => state.Get);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const amounts = [
    { label: "₹1,000", value: 1000 },
    { label: "₹2,000", value: 2000 },
    { label: "₹3,000", value: 3000 },
    { label: "₹5,000", value: 5000 },
    { label: "₹10,000", value: 10000 },
    { label: "₹20,500", value: 20000 },
    { label: "₹30,000", value: 30000 },
    { label: "₹50,000", value: 50000 },
  ];
  const { logResponse } = useSelector((state) => state.Auth);
  // console.log(logResponse.user_id);
  const uid = localStorage.getItem("user_id");

  const addAmount = (e) => {
    setAmount(e);
  };

  useEffect(() => {
    dispatch(getRequestStatus(uid));
  }, []);

  const withdReq = () => {
    if (
      requestStatus?.kyc?.kyc_status == "Approved" &&
      requestStatus?.bankDetailsRequested == 1
    ) {
      const amnt = amount;
      const formData = new FormData();
      formData.append("user_id", uid);
      formData.append("amount", amnt);

      if (amount !== "" && amount !== undefined) {
        setError("");
        dispatch(withDraw(formData));
      } else {
        setError("Please enter an amount");
      }
    } else {
      toast.error("Please Complete your KYC Or Submit Bank Details", {
        duration: 5000,
      });
      navigate("/verifyAcc");
    }
  };

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container fixed">
          <div className="reg-back" style={{ height: "56px" }}>
            <div className="reg__back">
              <Link to="/wallet">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Withdraw</p>
          </div>

          <div className="mid_content add_money">
            <div className="inn_money">
              <div className="mb-3">
                <label className="form-label">Amount</label>
                <TextField
                  fullWidth
                  placeholder="Enter the amount"
                  onChange={(e) => setAmount(e.target.value)}
                  type="text"
                  className="form-control"
                  value={amount}
                />
                <div
                  style={{
                    height: "50px",
                    fontSize: "15px",
                    color: "red",
                    paddingLeft: "5px",
                  }}
                >
                  {error}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",

                  // justifyContent: "space-between",
                  gap: "8px", // Added gap for better spacing
                  width: "100%",
                  margin: "auto",
                }}
              >
                {amounts.map((item) => (
                  <div
                    key={item.value}
                    onClick={() => addAmount(item.value)}
                    style={{
                      padding: "7px 10px",
                      border: "2px solid #582e85",
                      borderRadius: "8px",
                      color: "#582e85",
                      cursor: "pointer",
                      minWidth: "20%",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
              {/* <ul>
                <li onClick={(e) => addAmount(e)} value={250}>
                  250
                </li>
                <li onClick={(e) => addAmount(e)} value={500}>
                  500
                </li>
                <li onClick={(e) => addAmount(e)} value={750}>
                  750
                </li>
              </ul> */}
              {/* <label className="form-label">Add Bank</label>
                    <div className="card_main">
                        <ul className="card_list">
                            <li><img style={{height:"15px",width:"30px"}} src={mscard} alt=""/></li>
                            <li><img style={{height:"15px",width:"50px"}}  src={paypal} alt=""/></li>
                            <li><img  style={{height:"15px",width:"40px"}} src={visa} alt=""/></li>
                            <li><span>+5</span></li>
                        </ul>
                    </div> */}
              <button
                onClick={withdReq}
                className="btn submit_btn"
                style={{ marginTop: 14 }}
              >
                Withdraw Instantly
              </button>
            </div>
          </div>
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
