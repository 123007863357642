import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

import { Typography } from "@mui/material";
import { BASE_APP_URL } from "../config";
import EventDetails from "./popup/EventDetails";

const InfoPopup = ({ items, close, params }) => {
  const closeddd = () => {
    close(false);
  };
  const sot = (url) => {
    window.open(url, "_blank");
  };
  const link = (qid) => {
    let newLink = BASE_APP_URL + `placebetmarkets/${params.id}` + `/${qid}`;
    return newLink;
  };
  return (
    <EventDetails closeddd={closeddd} items={items} link={link} sot={sot} />
  );
};

export default InfoPopup;
