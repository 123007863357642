import React, { useEffect } from "react";
import Footer3 from "../Components/Footer3";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getHelpNsupport } from "../Redux/GetSlice";
import { produc } from "../Redux/Helper";
import tnc from "../Assets/Images/termsconditions.jpg";

export default function HelpNsupport() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHelpNsupport());
  }, []);

  const { pages } = useSelector((state) => state.Get);

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container">
          <div
            style={{ position: "sticky", top: "0", height: "56px" }}
            className="reg-back fixed"
          >
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Help & Support</p>
          </div>

          <div className="mid_content">
            <div className="terms_img">
              {pages?.helpAndSupport?.banner_image == null ||
              undefined ||
              "" ? (
                <img src={tnc} alt="" />
              ) : (
                <img src={produc(pages?.helpAndSupport?.banner_image)} alt="" />
              )}
            </div>

            <div className="terms_content">
              <div className="summary_contents">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pages?.helpAndSupport?.html_content,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer />
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
