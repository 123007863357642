import React, { useEffect, useMemo, useState } from "react";
import Footer3 from "../Components/Footer3";
import Select from "react-select";
import countryList from "react-select-country-list";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import { getCountry } from "../Redux/GetSlice";
import { getState } from "../Redux/GetSlice";
import { settings } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userBalance } from "../Redux/GetSlice";
import { produc } from "../Redux/Helper";
import { TextField } from "@mui/material";
import NewDatePicker from "./Datepicker/Datepicker";
import { getContact } from "../Redux/GetSlice";
import moment from "moment";

export default function Settings() {
  const uid = localStorage.getItem("user_id");

  const navigate = useNavigate();

  const { countries } = useSelector((state) => state.Get);
  const { states } = useSelector((state) => state.Get);
  const { totalBalanceResponse } = useSelector((state) => state.Get);
  const { settingLoad } = useSelector((state) => state.Post);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [value, setValue] = useState("");
  const [fullName, setFullName] = useState(
    totalBalanceResponse?.user?.firstname == "" ||
      totalBalanceResponse?.user?.firstname == null
      ? ""
      : totalBalanceResponse?.user?.firstname +
          " " +
          totalBalanceResponse?.user?.lastname
  );
  const [load, setLoad] = useState(false);
  const [cntry, setCntry] = useState(
    totalBalanceResponse?.user?.country_id == "0"
      ? 1
      : totalBalanceResponse?.user?.country_id
      ? totalBalanceResponse?.user?.country_id
      : 5
  );
  const [id, setId] = useState(1);
  const [stte, setStte] = useState(
    totalBalanceResponse?.user?.state_id == "0"
      ? 1
      : totalBalanceResponse?.user?.state_id
      ? totalBalanceResponse?.user?.state_id
      : 28
  );
  const [img, setImg] = useState("");
  const [dobTime, setDobTime] = useState(
    totalBalanceResponse?.user?.dob == null ||
      totalBalanceResponse?.user?.dob == "0000-00-00"
      ? new Date()
      : `${moment(new Date(totalBalanceResponse?.user?.dob)).format(
          "YYYY-MM-DD"
        )}`
  );

  // console.log(dobTime,"dob++++++_______");

  const handleSetupDateChange = (selectedDate) => {
    setDobTime(selectedDate?.format("YYYY-MM-DD"));
    // console.log(selectedDate?.format("YYYY-MM-DD"),"selectedadte+++++++++++++");
  };

  useEffect(() => {
    if (settingLoad == 1) {
      setLoad(true);
    } else if (settingLoad == 0) {
      setLoad(false);
    }
  }, [settingLoad]);

  const options = useMemo(() => countryList().getData(), []);

  // console.log(options);

  const changeHandler = (value) => {
    setValue(value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getState(cntry));
    }, 1000);
  }, [cntry]);

  const setCountry = (e) => {
    setCntry(e.target.value);
  };

  const setStates = (e) => {
    setStte(e.target.value);
  };

  const saveSettings = (data) => {
    const { name } = data;
    const formData = new FormData();
    formData.append("user_id", uid);
    formData.append("name", fullName);
    formData.append("country_id", cntry);
    formData.append("state_id", stte);
    formData.append("dob", `${dobTime}`);
    formData.append("photo", img);
    dispatch(settings(formData));
  };

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container">
          <div
            style={{ position: "sticky", top: "0", zIndex: 10, height: "56px" }}
            className="reg-back"
          >
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Settings</p>
          </div>

          <div className="mid_content kyc">
            <div className="inn_kyc">
              <form onSubmit={handleSubmit(saveSettings)}>
                <div style={{ color: "black" }} className="mb-3">
                  <label className="form-label">Name</label>
                  <br />
                  <TextField
                    required="true"
                    fullWidth
                    style={{
                      color: "black",
                      marginTop: "5px",
                    }}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                  />
                </div>
                <div style={{ color: "black" }} className="mb-3">
                  <label className="form-label">Mobile</label>
                  <br />
                  <TextField
                    fullWidth
                    style={{ color: "black", marginTop: "5px" }}
                    disabled="true"
                    type="text"
                    placeholder={totalBalanceResponse?.user?.phone}
                    className="form-control"
                  />
                </div>

                <div style={{ marginTop: "10px" }} className="mb-3">
                  <label style={{ marginBottom: "5px" }} className="form-label">
                    Upload photo
                  </label>
                  <br />
                  <div style={{ marginTop: "5px" }}>
                    {totalBalanceResponse?.user?.photo !== null || undefined ? (
                      <img
                        style={{ height: "95px", width: "100px" }}
                        src={produc(totalBalanceResponse?.user?.photo)}
                        alt=""
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <br />
                  <TextField
                    required="true"
                    onChange={(e) => setImg(e.target.files[0])}
                    style={{ width: "100%", marginTop: "0px" }}
                    type="file"
                    className="form-control"
                    placeholder="Image"
                  />
                </div>

                <div style={{ marginTop: "30px" }} className="mb-3">
                  <label className="form-label">Date of Birth</label>
                  <br />
                  <NewDatePicker
                    required="true"
                    maxDate={new Date()}
                    value={
                      moment(new Date(totalBalanceResponse?.user?.dob)) ||
                      dobTime
                    }
                    name="dobTime"
                    event={"(DOB)"}
                    OnChange={handleSetupDateChange}
                  />
                </div>

                <div style={{ marginTop: "10px" }} className="mb-3">
                  <label
                    style={{ marginBottom: "20px" }}
                    className="form-label"
                  >
                    Select Country
                  </label>
                  <br />

                  {/* <Select options={countries.name} value={value} onChange={changeHandler} /> */}

                  <select
                    required="true"
                    onChange={setCountry}
                    value={cntry}
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      paddingLeft: 10,
                    }}
                    id="country"
                    name="country"
                    className="form-control"
                  >
                    {/* <option value={totalBalanceResponse?.countryState?.country_name == "" ? "": totalBalanceResponse?.countryState?.country_name} > {totalBalanceResponse?.countryState?.country_name == "" ? "Select country": totalBalanceResponse?.countryState?.country_name}</option> */}
                    {Array.isArray(countries) &&
                      countries?.map((items) => {
                        // console.log(cntry,"country+++++++++++++");
                        return (
                          <option
                            onClick={() => setId(items?.id)}
                            value={items?.id}
                          >
                            {items?.name}{" "}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div style={{ marginTop: "10px" }} className="mb-3">
                  <label className="form-label col-sm-3">Select State</label>
                  <br />
                  <select
                    required="true"
                    onChange={setStates}
                    value={stte}
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      paddingLeft: 10,
                    }}
                    name="state"
                    id="state"
                    className="form-control"
                    placeholder="Select State"
                  >
                    <option
                      value={
                        totalBalanceResponse?.countryState?.state_name == ""
                          ? ""
                          : totalBalanceResponse?.countryState?.state_name
                      }
                    >
                      {" "}
                      {totalBalanceResponse?.countryState?.state_name == ""
                        ? "Select state"
                        : totalBalanceResponse?.countryState?.state_name}
                    </option>
                    {Array.isArray(states) &&
                      states?.map((items) => {
                        return (
                          <option value={items?.id}>{items?.name} </option>
                        );
                      })}
                  </select>
                </div>

                <button
                  disabled={load ? true : false}
                  type="submit"
                  onSubmit={handleSubmit(saveSettings)}
                  style={{ marginTop: "15px" }}
                  className="btn submit_btn"
                >
                  {load ? "Saving..." : "Save"}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer />
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
