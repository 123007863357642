import { BASE_URL } from "../config";


const getFormData = (obj) => {
  let formdata = new FormData();
  for (let key in obj) {
    formdata.append(key, obj[key]);
  }
  return formdata;
};

export const checkAccount = async (requestObj) => {
  let url = BASE_URL + "check-account";

  let requestOptions = {
    method: "POST",
    body: getFormData(requestObj),
  };

  let response = await fetch(url, requestOptions);
  return await response.json();
};
export const register = async (requestObj) => {
  let url = BASE_URL + "registration";

  let requestOptions = {
    method: "POST",
    body: getFormData(requestObj),
  };

  let response = await fetch(url, requestOptions);
  return await response.json();
};
export const reg_otp = async (requestObj) => {
  let url = BASE_URL + "registration/send-otp";

  let requestOptions = {
    method: "POST",
    body: getFormData(requestObj),
  };

  let response = await fetch(url, requestOptions);
  return await response.json();
};
export const varify_reg_otp = async (requestObj) => {
  let url = BASE_URL + "registration/validate-otp";

  let requestOptions = {
    method: "POST",
    body: getFormData(requestObj),
  };

  let response = await fetch(url, requestOptions);
  return await response.json();
};
export const login_otp = async (requestObj) => {
  let url = BASE_URL + "login/send-otp";

  let requestOptions = {
    method: "POST",
    body: getFormData(requestObj),
  };

  let response = await fetch(url, requestOptions);
  return await response.json();
};
export const varify_login_otp = async (requestObj) => {
  let url = BASE_URL + "login/validate-otp";

  let requestOptions = {
    method: "POST",
    body: getFormData(requestObj),
  };

  let response = await fetch(url, requestOptions);
  return await response.json();
};
