import React, { useEffect } from "react";
import visa from "../Assets/Images/visa.png";
import rupay from "../Assets/Images/rupay.png";
import master from "../Assets/Images/mastercard.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faHouseFire,
  faFaceSmileWink,
  faMoneyBillTrendUp,
  faMoneyBillTransfer,
  faBell,
  faRefresh,
  faBellConcierge,
  faAngleRight,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getContact, userBalance } from "../Redux/GetSlice";
import Footer3 from "../Components/Footer3";
import { GoBell, GoQuestion } from "react-icons/go";
import {
  IoIosInformationCircleOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import question from "../Assets/Images/question (1).png";
import { VscBell } from "react-icons/vsc";
import { TelegramShareButton } from "react-share";
import { Typography } from "@mui/material";

export default function Wallet() {
  const dispatch = useDispatch();

  const useid = localStorage.getItem("user_id");

  useEffect(() => {
    dispatch(userBalance(useid));
  }, []);

  const { Balance } = useSelector((state) => state.Get);
  const { totalBalanceResponse } = useSelector((state) => state.Get);

  const reloadBalance = () => {
    dispatch(userBalance(useid));
  };

  const navigate = useNavigate();

  const { contactUs } = useSelector((state) => state?.Get);
  const val = "Some text to share";
  const balanceAmount =
    parseFloat(totalBalanceResponse?.user?.balance_deposit) +
    parseFloat(totalBalanceResponse?.user?.balance_winning);
  const redirectLink = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    dispatch(getContact());
  }, []);

  return (
    <div>
      <div className="mobile__layout">
        <div className="container auth__container before-reg">
          <form className="style_auth__form__3wv2I" novalidate="">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "sticky",
                top: "0",
                height: "56px",
              }}
              className="reg-back"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link to="/">
                  <div className="reg__back">
                    <i>
                      <FontAwesomeIcon
                        style={{ color: "white" }}
                        icon={faArrowLeft}
                      />
                    </i>
                  </div>
                </Link>

                <p style={{ fontSize: "18px" }} className="wallt">
                  My Balance
                </p>
              </div>
              <div>
                <VscBell
                  onClick={() => navigate("/notify")}
                  style={{ marginTop: "5px" }}
                  size={25}
                  color="#f5dcde"
                />
              </div>
            </div>

            <div className="mid_content">
              {/* <div className="notific">
                        <div className="notify_icon">
                            <i >
                            <FontAwesomeIcon style={{color:"#efef42"}} icon={faBell}/>

                            </i>
                        </div>
                        <p>Know how your balances work</p>
                    </div> */}

              <div style={{ marginTop: "2px" }} className="wallet_section">
                {/* <div style={{display:"flex"}}>
                        <h5>Balance Amount</h5> 
                        <div onClick={reloadBalance} style={{marginLeft:"10px",cursor:"pointer"}}>
                        <FontAwesomeIcon icon={faRefresh}/>

                        </div>

                        </div>
                        <p className="blnc_amnt"><strong>₹{parseInt(totalBalanceResponse?.user?.balance_deposit)+parseInt(totalBalanceResponse?.user?.balance_winning)} </strong></p> */}
                <div>
                  <p
                    style={{
                      fontWeight: "550",
                      fontSize: "12px",
                      color: "grey",
                      marginBottom: 4,
                    }}
                  >
                    Balance Amount
                  </p>
                  <p style={{ fontWeight: "900" }} className="rupe">
                    ₹{balanceAmount}{" "}
                  </p>
                </div>
                <div className="full_contents">
                  <div className="left_items">
                    {/* <div className="icon">
                                    <i >
                                    <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faHouseFire}/>
                                    </i>
                                </div> */}

                    <div className="cont_infor">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          marginBottom: "5px",
                        }}
                      >
                        <p style={{ fontWeight: "550", fontSize: "12px" }}>
                          Amount Unutilised
                        </p>
                        <IoMdInformationCircleOutline color="grey" size={18} />
                      </div>
                      <p style={{ fontWeight: "900" }} className="rupe">
                        ₹
                        {parseFloat(
                          totalBalanceResponse?.user?.balance_deposit
                        )?.toFixed(2)}{" "}
                      </p>
                    </div>
                  </div>

                  <Link
                    style={{
                      width: "140px",
                      height: "45px",
                      background: "#eaf4ef",
                    }}
                    to="/addmoney"
                  >
                    <div
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        width: "130px",
                        height: "40px",
                        textAlign: "center",
                        borderRadius: "7px",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "700",
                        margin: "auto",
                        letterSpacing: "0.5px",
                      }}
                      className="right_items"
                    >
                      DEPOSIT NOW
                    </div>
                  </Link>
                </div>
                <div className="full_contents">
                  <div className="left_items">
                    {/* <div className="icon">
                                    <i >
                                    <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faFaceSmileWink}/>
                                    </i>
                                </div> */}
                    <div className="cont_infor">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          marginBottom: "5px",
                        }}
                      >
                        <p style={{ fontWeight: "500", fontSize: "12px" }}>
                          Winnings
                        </p>
                        <IoMdInformationCircleOutline color="grey" size={18} />
                      </div>

                      <p style={{ fontWeight: "900" }} className="rupe">
                        ₹{totalBalanceResponse?.user?.balance_winning}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="right_items">
                    <Link
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      to="/withdrawrequest"
                      className="add_money_btn withdraw"
                    >
                      WITHDRAW INSTANTLY
                    </Link>
                  </div>
                </div>
                <div
                  style={{ borderBottom: "1px solid white" }}
                  className="full_contents"
                >
                  <div className="left_items">
                    {/* <div className="icon">
                                    <i >
                                    <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faMoneyBillTrendUp}/>
                                    </i>
                                </div> */}
                    <div className="cont_infor">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          marginBottom: "5px",
                        }}
                      >
                        <p style={{ fontWeight: "550", fontSize: "12px" }}>
                          Discount Bonus
                        </p>
                        <IoMdInformationCircleOutline color="grey" size={18} />
                      </div>
                      <p style={{ fontWeight: "900" }} className="rupe">
                        ₹{totalBalanceResponse?.user?.balance_bonus}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ margin: "auto", marginTop: 10 }}
                className="conte_box"
              >
                <Link
                  to="/transaction"
                  className="content_box_shadow"
                  style={{ boxShadow: "none" }}
                >
                  <div className="content_box_inner ">
                    <div className="left-co">
                      <p>My Transactions</p>
                    </div>
                    <div className="right-co">
                      <div className="icon_s">
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div className="list_items_type">
                <Link to="/transaction">
                  <div className="items_listtype">
                    <p>My Transactions</p>
                    <div className="right-co">
                      <div className="icon_s">
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div> */}
              <div
                style={{ margin: "auto", marginTop: 10 }}
                className="conte_box"
              >
                <Link
                  to="/withdrawhistory"
                  className="content_box_shadow"
                  style={{ boxShadow: "none" }}
                >
                  <div className="content_box_inner ">
                    <div className="left-co">
                      <p>Withdrawal History</p>
                    </div>
                    <div className="right-co">
                      <div className="icon_s">
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div
                style={{ margin: "auto", marginTop: 10 }}
                className="conte_box"
              >
                <Link
                  to="/offer"
                  className="content_box_shadow"
                  style={{ boxShadow: "none" }}
                >
                  <div className="content_box_inner ">
                    <div className="left-co">
                      <p>Offers</p>
                    </div>
                    <div className="right-co">
                      <div className="icon_s">
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div
                style={{ margin: "auto", marginTop: 10 }}
                className="conte_box"
              >
                <Link
                  to="/verifyAcc"
                  className="content_box_shadow"
                  style={{ boxShadow: "none" }}
                >
                  <div className="content_box_inner ">
                    <div className="left-co">
                      <p>KYC</p>
                    </div>
                    <div className="right-co">
                      <div
                        className="icon_s"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "600",
                            color: "#363636",
                          }}
                        >
                          (Verify KYC to withdraw)
                        </p>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <Link to="/refer">
                <div className="list_items_type">
                  <div className="items_listtype">
                    {/* <div className="icon">
                                <i >
                                <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faMoneyBillTransfer}/>
                                    
                                </i>
                            </div> */}
                    <div>
                      <p className="descr">Invite & Collect</p>
                      <p style={{ color: "grey" }}>
                        Bring your friends on Tragoxbet and earn rewards
                      </p>
                    </div>

                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </div>
              </Link>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f9ff",
                  height: "35px",
                  margin: "auto",
                  marginTop: "25px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  paddingLeft: "10px",
                  marginBottom: "5px",
                }}
              >
                {/* <GoQuestion /> */}
                <img
                  src={question}
                  style={{ height: "14px", width: "14px" }}
                  alt=""
                />
                {/* <FontAwesomeIcon  icon={faCommentQuestion}/> */}
                <p style={{ color: "#363636" }}>
                  {" "}
                  Have questions on how it works? {"    "}{" "}
                  <span>
                    {" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        textDecorationStyle: "dashed",
                        textUnderlineOffset: "4px",
                        cursor: "pointer",
                        textTransform: "none",
                      }}
                      // url={val}
                      // url={contactUs?.settings?.telegram_url}
                      // className="share-button linkedin-button"
                      onClick={() =>
                        redirectLink(contactUs?.settings?.telegram_url)
                      }
                    >
                      Chat with Us
                    </span>
                  </span>
                </p>
              </div>

              {/* <div className="bottom_logos">
                        <div className="logo_bot">
                            <img src={master} alt=""/>
                        </div>
                        <div className="logo_bot">
                            <img src={rupay} alt=""/>
                        </div>
                        <div className="logo_bot">
                            <img src={visa} alt=""/>
                        </div>
                    </div> */}
            </div>
          </form>
        </div>
        <div className="Toastify"></div>
        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer3 />
        </div>
      </div>
    </div>
  );
}
