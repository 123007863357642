import React from 'react'
import logo2 from '../Assets/Images/logo-top-black.png';
import { Link, useParams } from 'react-router-dom';
import logo from '../Assets/Images/logomatch.png';



export default function TopNavWOL() {
  return (
    <div>
          <div className="main_header before-registration">
            <div className="top_logo">
              <img style={{height:"35px",width:"145px"}} src={logo} alt="" />
            </div>
            <div style={{ display: "flex" }}>
              <div className="login_btn">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/register/0"
                >
                  Login
                </Link>
              </div>
              <div className="registration__btn">
                <Link to="/register/1">Register</Link>
              </div>
            </div>
          </div>
    </div>
  )
}
