import React from "react";
import { produc } from "../Redux/Helper";
import jersey from "../Assets/Images/jerseypng.png";
import { TradeTypography } from "./TradeTypography";

export default function EventDetWithwatermarkstatusInvest({
  items,
  betInfos,
  exitPop,
}) {
  return (
    <div style={{ marginTop: 0 }}>
      <div
        onClick={() => (betInfos ? betInfos() : null)}
        style={{ marginBottom: "10px" }}
      >
        <div
          style={{
            width: "97%",
            borderRadius: "20px",
            margin: "auto",
          }}
          className="rslt__crd"
        >
          <div style={{ marginBottom: "15px" }}>
            <div
              style={{
                fontSize: "13px",
                width: "90px",
                height: "20px",
                textAlign: "center",
                borderRadius: "50px",
                color: "#00793c",
                paddingTop: "1px",
                backgroundColor: "#c5ffdd",
              }}
            >
              <TradeTypography>Matched</TradeTypography>
            </div>
          </div>
          <div style={{ minWidth: "20%" }} className="top__dt">
            <div
              className="plcehlder"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "5px",
              }}
            >
              {items?.matched?.question?.question_image == null ||
              items?.matched?.question?.question_image == undefined ||
              items?.matched?.question?.question_image == "" ? (
                <img src={jersey} alt="img" />
              ) : (
                <img
                  src={produc(items?.matched?.question?.question_image)}
                  alt=""
                />
              )}
            </div>
            <div style={{ width: "90%" }}>
              <TradeTypography>
                {items?.matched?.question?.question_text}{" "}
              </TradeTypography>
            </div>
          </div>
          <>
            {items?.matched?.bets?.length == 0 ? (
              <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                No Records
              </h5>
            ) : (
              <div className="fl-det">
                <div className="lower_dtls">
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Choose</TradeTypography>
                    </div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Invest</TradeTypography>
                    </div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Buy Price</TradeTypography>
                    </div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Quantity</TradeTypography>
                    </div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">
                      <TradeTypography>Result</TradeTypography>
                    </div>
                  </div>
                  {/* <div className="box">
                    <div className="hd_blo">Result</div>
                  </div>
                  <div className="box">
                    <div className="hd_blo">Returns</div>
                  </div> */}
                </div>

                {items?.matched?.bets?.map((matched, index) => {
                  return (
                    <div key={index} className="lower_dtls">
                      <div className="rslt_blo">{matched?.choose} </div>
                      <div className="rslt_blo">₹{matched?.invest}</div>
                      <div className="rslt_blo" style={{ marginRight: 16 }}>
                        ₹{matched?.buy_price}{" "}
                      </div>
                      <div
                        style={{ marginTop: "5px", marginRight: 14 }}
                        className="rslt_blo"
                      >
                        {matched?.quantity}
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          fontWeight: "bold",
                          marginRight: 4,
                          // alignSelf: "flex-end",
                        }}
                        // className="rslt_blo"
                      >
                        <TradeTypography>{matched?.status}</TradeTypography>
                      </div>
                      {/* <div className="rslt_blo rd">Option 2</div>
                      <div className="rslt_blo">₹999</div> */}
                    </div>
                  );
                })}

                <div className="card_below_content">
                  <div
                    style={{
                      width: "100%",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      background: `linear-gradient(34deg, rgb(247,246,244) 35%, rgb(247,246,244) 100%)`,
                    }}
                    className="below_content"
                  >
                    <span
                      style={{
                        color: "red",
                        background: "white",
                        padding: "1px",
                        fontWeight: "600",
                      }}
                      className="percent-details"
                    >
                      {" "}
                      Investment ₹{items?.matched?.total_invested}{" "}
                      <span
                        style={{
                          color: "green",
                          background: "white",
                          padding: "1px",
                          fontWeight: "600",
                        }}
                        className="percent-details"
                      >
                        YOU WON: ₹{items?.matched?.total_returns}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
}
