import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function Footer() {
  const [active, setActive] = useState(0);

  const clickActive1 = () => {
    if (active === 4 || 2 || 3) {
      setActive(1);
    }
  };
  const clickActive2 = () => {
    if (active === 1 || 3 || 4) {
      setActive(2);
    }
  };
  const clickActive3 = () => {
    if (active === 2 || 4 || 1) {
      setActive(3);
    }
  };
  const clickActive4 = () => {
    if (active === 3 || 1 || 2) {
      setActive(4);
    }
  };

  return (
    <div>
      <div style={{ margin: "auto" }} className="footer">
        <Link to="/">
          <div className="home_content">
            <i className="fa-solid fa-house"></i>
            <p>Home</p>
          </div>
        </Link>

        <Link to="/portfolio">
          <div className="portfolio_content">
            <i className="fa-solid fa-briefcase"></i>
            <p>Portfolio</p>
          </div>
        </Link>

        <Link to="/wallet">
          <div className="portfolio_content">
            <i className="fa-solid fa-user"></i>
            <p>Profile</p>
          </div>
        </Link>

        <Link to="/more">
          <div className="portfolio_content">
            <i className="fa-solid fa-ellipsis"></i>
            <p>More</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
