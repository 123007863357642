import React, { useEffect, useState } from "react";
import { liveMatch, matchQuest, userBalance } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import jersey from "../Assets/Images/jerseypng.png";
import logo4 from "../Assets/Images/fav1.png";
import { postBet } from "../Redux/PostSlice";
import Footer from "../Components/Footer";
import { upcoming } from "../Redux/GetSlice";
import moment from "moment";
import { getyesno } from "../Redux/GetSlice";
import { yes, no } from "../Redux/GetSlice";
import { io } from "socket.io-client";
import discountImg from "../Assets/Images/discount5.png";
import id from "../Assets/Images/id4.png";
import stock from "../Assets/Images/stockup.png";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faMinus,
  faX,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { BASE_APP_URL } from "../config";
import { HiArrowLeft } from "react-icons/hi2";
import { CiWallet } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaInfo } from "react-icons/fa6";
import CountdownTimer from "./Countdown/Countdown";
import EventDetails from "../Components/popup/EventDetails";

const socket = io.connect(RT_BASE_URL);

export default function MatchDetQuest() {
  const { matchQuestions } = useSelector((state) => state.Get);
  const { upcomingMatch } = useSelector((state) => state.Get);
  const { yesNoCounts } = useSelector((state) => state.Get);
  const { liveMatches } = useSelector((state) => state.Get);
  const { Balance, totalBalanceResponse } = useSelector((state) => state.Get);

  const params = useParams();
  // console.log("idddddddddddddd",params.id);

  const [queId, setQuiId] = useState("");
  const [info, setInfo] = useState(false);
  const [popinfo, setPopinfo] = useState({});
  const [copy, setCopy] = useState(false);
  const [realQuest, setRealQuest] = useState([{}]);

  // if (copy) {
  //   toast.success("Copied to clipboard");

  // }

  const uid = localStorage.getItem("user_id");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const infoPop = (items, ind) => {
    console.log(items, "items--------- out");
    setInfo(true);
    setPopinfo(items);
  };

  useEffect(() => {
    setRealQuest(matchQuestions);
  }, [matchQuestions]);

  useEffect(() => {
    socket.on("newQuestionPublished", (data) => {
      //   console.log(data, "question data ----------");
      let socketData = JSON.parse(data);
      if (
        socketData?.match?.match_type == "Live" &&
        socketData?.match?.match_id == params.id
      ) {
        dispatch(matchQuest(params.id));
      }
    });
  }, []);

  useEffect(() => {
    socket.on("questionDeleted", (data) => {
      //   console.log(data, "question data ----------");
      let socketData = JSON.parse(data);
      if (
        socketData?.match?.match_type == "Live" &&
        socketData?.match?.match_id == params.id
      ) {
        dispatch(matchQuest(params.id));
      }
    });
  }, []);
  // console.log(realQuest,"realinfo---------------");

  // onClick={(e)=> placeBet(items.question_id,items.match_id,e.target.value)}

  useEffect(() => {
    dispatch(matchQuest(params.id));
  }, []);

  useEffect(() => {
    dispatch(liveMatch());
  }, []);

  let time = setTimeout(() => {
    setQuiId(queId + 1);
  }, 1000);

  if (queId > 2) {
    clearTimeout(time);
  }

  const setYes = (mId, qId, opt) => {
    dispatch(yes());
    navigate(`/placebetlive/${mId}/${qId}`);
  };

  const setNo = (mId, qId, opt) => {
    dispatch(no());

    navigate(`/placebetlive/${mId}/${qId}`);
  };

  // let link = "https://luxury-brigadeiros-5b57ce.netlify.app/";
  const link = (qid) => {
    let newLink = BASE_APP_URL + `placebetlive/${params.id}` + `/${qid}`;
    return newLink;
  };

  const InfoPopup = ({ items, close }) => {
    const closeddd = () => {
      close(false);
    };
    return (
      <EventDetails items={items} closeddd={closeddd} link={link} sot={sot} />
    );
  };

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  // setQuestions([...matchQuestions,matchQuestions])

  // console.log(matchQuestions);

  useEffect(() => {
    info && (document.body.style.overflow = "hidden");
    !info && (document.body.style.overflow = "unset");
  }, [info]);

  const redirectLink = matchQuestions[0]?.source_of_truth;

  const redirect = (data) => {
    console.log(".....data...", data);
    window.open(data, "_blank");
  };

  const sot = (url) => {
    window.open(url, "_blank");
  };

  const balanceAmount =
    parseFloat(totalBalanceResponse?.user?.balance_deposit) +
    parseFloat(totalBalanceResponse?.user?.balance_winning);

  return (
    <div>
      <div
        style={{
          height: "calc(100vh - 56px)",
        }}
        className="mobile__layout main"
      >
        <div
          style={{ background: "white" }}
          className="container auth__container"
        >
          <div
            style={{
              justifyContent: "space-between",
              position: "sticky",
              top: "0",

              height: "56px",
            }}
            onClick={() => setInfo(false)}
            className="reg-back fixed_up"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ background: "#00000000" }} className="reg__back">
                <Link to="/">
                  <HiArrowLeft color="white" size={25} />
                </Link>
              </div>
              <div className="reg__back matches_select">
                <div className="dropdown">
                  {Array.isArray(liveMatches) &&
                    liveMatches?.map((items) => {
                      if (items?.match_id === params?.id) {
                        return (
                          <button
                            style={{
                              height: "30px",
                              width: "100%",
                              fontSize: "13px",
                            }}
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {items?.team_1_abbr} v {items?.team_2_abbr}
                            <br />
                            <p
                              style={{
                                fontSize: "16px",
                                textTransform: "none",
                                color: "#bbbbbb",
                                paddingTop: "4px",
                              }}
                            >
                              LIVE
                            </p>
                          </button>
                        );
                      }
                    })}
                </div>
              </div>
            </div>

            <div
              onClick={() => navigate("/addmoney")}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "6px",
                gap: "8px",
                borderRadius: "10px",
                background: "#202b3d",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              <CiWallet size={19} color="white" />₹{balanceAmount}
              <div
                style={{
                  background: "green",
                  padding: "1px",
                  height: "20px",
                  borderRadius: "50px",
                }}
              >
                <IoMdAdd style={{ margin: "auto" }} size={18} color="#cdcdcd" />
              </div>
            </div>
          </div>

          <>
            {Array.isArray(liveMatches) &&
              liveMatches.map((items) => {
                if (items?.match_id === params.id) {
                  return (
                    <section
                      style={{
                        marginBottom: "1px",
                        padding: "5px",
                        paddingBottom: "1px",
                        borderBottom: "4px solid black",
                      }}
                      onClick={() => setInfo(false)}
                      className="middle_content"
                    >
                      <Link>
                        <div
                          style={{ border: "0px solid black" }}
                          className="upcoming_match_card_top qu"
                        >
                          {/* <div className="up-headi">
                          <div className="match_head detils_head">
                            <p>{items?.league_name}</p>
                          </div>
                        </div> */}

                          <div
                            style={{ height: "75px" }}
                            className="top_dt_mtch"
                          >
                            <div className="frst_team_img">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  gap: "15px",
                                }}
                              >
                                {items?.team_1_flag == null ||
                                items?.team_1_flag == undefined ||
                                items?.team_1_flag == "" ? (
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      borderRadius: "50px",
                                    }}
                                    src={logo4}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      borderRadius: "50px",
                                    }}
                                    src={produc(items?.team_1_flag)}
                                    alt=""
                                  />
                                )}
                                {/* <img style={{height:"30px",width:"30px",borderRadius:"50px"}} src={logo4} alt="" /> */}
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: "19px",
                                    fontWeight: "750",
                                  }}
                                >
                                  {items?.team_1_abbr}
                                </p>
                              </div>
                              <p
                                style={{
                                  color: "grey",
                                  textAlign: "start",
                                  paddingTop: "10px",
                                }}
                              >
                                {items?.team_1_name?.split(" ").length > 2
                                  ? items?.team_1_name?.split(" ")[0] +
                                    " " +
                                    items?.team_1_name?.split(" ")[1] +
                                    "..."
                                  : items?.team_1_name}{" "}
                              </p>
                            </div>

                            <div className="scnd_team_img">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  gap: "15px",
                                }}
                              >
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: "19px",
                                    fontWeight: "750",
                                  }}
                                >
                                  {items?.team_2_abbr}
                                </p>
                                {items?.team_2_flag == null ||
                                items?.team_2_flag == undefined ||
                                items?.team_2_flag == "" ? (
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      borderRadius: "50px",
                                    }}
                                    src={logo4}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      borderRadius: "50px",
                                    }}
                                    src={produc(items?.team_2_flag)}
                                    alt=""
                                  />
                                )}
                                {/* <img style={{height:"30px",width:"30px",borderRadius:"50px"}} src={logo4} alt="" /> */}
                              </div>
                              <p
                                style={{
                                  color: "grey",
                                  textAlign: "end",
                                  paddingTop: "10px",
                                }}
                              >
                                {" "}
                                {items?.team_2_name?.split(" ").length > 2
                                  ? items?.team_2_name?.split(" ")[0] +
                                    " " +
                                    items?.team_2_name?.split(" ")[1] +
                                    "..."
                                  : items?.team_2_name}
                              </p>
                            </div>
                          </div>

                          <div style={{ color: "lightgrey" }} className="fix_">
                            <p
                              style={{
                                fontSize: "11px",
                                fontWeight: "600",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              <div
                                style={{
                                  height: "5px",
                                  width: "5px",
                                  borderRadius: "50px",
                                  background: "green",
                                }}
                              ></div>{" "}
                              LIVE
                            </p>

                            <p className="time_fixed">
                              Started on{" "}
                              {moment(items?.match_date).format("h:mm A")}
                            </p>
                          </div>

                          <div className="card_below_content">
                            <div className="below_content lst">
                              <p
                                style={{
                                  color: "lightgrey",
                                  fontWeight: "500",
                                }}
                              >
                                For Full Score And Commentary.
                              </p>
                              <div
                                style={{
                                  color: "lightgrey",
                                  fontWeight: "600",
                                  fontSize: "12px",
                                }}
                                onClick={() => redirect(items?.source_of_truth)}
                                className="tm_scnd"
                              >
                                Click Here
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </section>
                  );
                }
              })}
          </>

          <div style={{ height: "100vh", overflowY: "auto" }}>
            <div
              style={{
                width: "98%",
                margin: "auto",
                height: "30px",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                marginBottom: "8px",
                gap: "1px",
              }}
            >
              <img
                style={{ width: "30%", marginTop: "20px" }}
                src={discountImg}
                alt=""
              />

              <div
                style={{
                  height: "82%",
                  width: "50%",
                  borderRadius: "10px",
                  background: "#f3ce3f",
                  border: "2px solid #e6ca66",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontWeight: "bolder",
                }}
              >
                <RiDiscountPercentFill size={23} color="red" />
                <p
                  style={{ fontSize: "7px", padding: "4px", fontWeight: "700" }}
                >
                  Maximum usable cash bonus per trade = 5% of entry
                </p>
              </div>
            </div>

            <section style={{ width: "100%" }} className="ques">
              {realQuest?.length == 0 ? (
                <>
                  <h3 style={{ textAlign: "center", color: "grey" }}>
                    No questions for this match!
                  </h3>
                </>
              ) : (
                <></>
              )}
              {Array.isArray(realQuest) &&
                realQuest?.map((items, ind) => {
                  console.log("items running", items);
                  return (
                    <div
                      style={{ borderWidth: 1, borderColor: "black" }}
                      className="question_box_"
                    >
                      <div
                        onClick={() =>
                          navigate(
                            `/placebetlive/${items?.match_id}/${items?.question_id}`
                          )
                        }
                        className="trade_rule"
                      >
                        <div className="trade_icon">
                          <img
                            style={{ height: "12px", width: "12px" }}
                            src={stock}
                            alt=""
                          />
                        </div>

                        <div style={{ padding: "0px 3px" }}>
                          <p style={{ fontSize: "11px" }}>
                            {items?.answer_count} traders {">"}{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        // onClick={() =>
                        //   navigate(
                        //     `/placebetlive/${items?.match_id}/${items?.question_id}`
                        //   )
                        // }
                        className="imgque"
                      >
                        <div className="ques">
                          <h5
                            style={{
                              fontWeight: "600",
                              width: "100%",
                            }}
                          >
                            {items?.question_text}
                          </h5>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 5,
                              marginTop: 10,
                            }}
                            className="descpt"
                          >
                            <img
                              style={{
                                height: "20px",
                                width: "20px",
                                borderRadius: "50px",
                              }}
                              src={logo4}
                              alt=""
                            />
                            <p
                              style={{ fontSize: "11px" }}
                              className="shrt_desc"
                            >
                              {items?.question_short_desc}{" "}
                            </p>
                          </div>
                        </div>

                        <div
                          style={{ marginRight: "5px" }}
                          className="quest_img"
                        >
                          {/* {items?.question_image == null ||
                      items?.question_image == undefined ||
                      items?.question_image == "" ? (
                        <img src={jersey} alt="" />
                      ) : (
                        <img src={produc(items?.question_image)} alt="" />
                      )} */}
                          <img
                            style={{ borderRadius: "10px" }}
                            src={
                              items?.question_image == "" || null || undefined
                                ? logo4
                                : produc(items?.question_image)
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="yes_no_action">
                        <button
                          onClick={() =>
                            setYes(
                              items?.match_id,
                              items?.question_id,
                              items?.option1_text
                            )
                          }
                          value={items?.option1_text}
                          style={{
                            fontWeight: "800",
                            width: "165px",
                            height: "30px",
                            fontSize: "12px",
                            letterSpacing: "0px",
                          }}
                          className="yes_btn"
                        >
                          {items.option1_text?.split(" ")?.length > 1
                            ? items?.option1_text?.split(" ")[0] + "..."
                            : items?.option1_text}{" "}
                          {items?.min_option1_amount == null
                            ? "₹ 5"
                            : "₹ " + parseFloat(items?.min_option1_amount)}{" "}
                        </button>

                        <button
                          onClick={() =>
                            setNo(
                              items?.match_id,
                              items?.question_id,
                              items?.option2_text
                            )
                          }
                          value={items?.option2_text}
                          style={{
                            fontWeight: "800",
                            width: "165px",
                            height: "30px",
                            fontSize: "12px",
                            letterSpacing: "0px",
                          }}
                          className="no_btn"
                        >
                          {items.option2_text?.split(" ")?.length > 1
                            ? items?.option2_text?.split(" ")[0] + "..."
                            : items?.option2_text}{" "}
                          {items?.min_option1_amount == null
                            ? "₹ 5"
                            : "₹ " +
                              (10 - parseFloat(items?.min_option1_amount))}
                        </button>

                        <button
                          type="button"
                          className="btn btn-secondary tool"
                          data-toggle="tooltip"
                          data-placement="top"
                          style={{ borderRadius: 4, borderWidth: 1 }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => infoPop(items, ind)}
                            className="qu_ic"
                          >
                            <FaInfo size={22} />
                          </div>
                        </button>
                      </div>
                    </div>
                  );
                })}
            </section>
          </div>
          <div style={{ height: 55 }} />
          <section className="tbl"></section>
        </div>
      </div>
      {info && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "40vh",
            backgroundColor: "transparent", // Semi-transparent background
            zIndex: 999, // Ensure it's on top
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            e.preventDefault(); // Block interaction with background
            setInfo((prev) => !prev); // Close the modal
          }}
        />
      )}
      <div style={{ position: "sticky", bottom: "0" }}>
        <div>{info && <InfoPopup items={popinfo} close={setInfo} />}</div>
        {info == true ? <></> : <Footer />}
      </div>

      <div className="Toastify"></div>
    </div>
  );
}
